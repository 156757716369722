import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../../../../contexts/adminPanel.context.jsx";
import SubmitButton from "../../../../bulk-order/submit.btn.component.jsx";
import { useHomePage } from "../../../../../contexts/homePage.context.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "250px",
  },
};

Modal.setAppElement("#root");

function EditBanner({ isOpen, onRequestClose, editUser }) {
  const { UpdateBannerContext } = useHomePage();
  const uniqueId = editUser?._id;
  console.log(
    "🚀 ~ file: editHomePageBanner.jsx:25 ~ EditBanner ~ uniqueId:",
    uniqueId
  );

  const [formData, setFormData] = useState({
    text: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    try {
      const formDataHandleSave = {
        ...formData,
      };

      await UpdateBannerContext(uniqueId, formDataHandleSave);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Home Banner Text"
    >
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-section my-3">
              <label htmlFor="text">Text</label>
              <textarea
                type="text"
                name="text"
                placeholder="text"
                value={formData.text}
                onChange={handleInputChange}
                rows={4} // You can adjust the number of rows to control the height
                style={{
                  width: "100%", // Make the textarea take up the full width
                  display: "block", // Ensure it's displayed as a block element
                }}
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default EditBanner;
