import React from "react";

function DateDisplay({ dateString }) {
  // Define dateString as a prop
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="font-normal">
      <p className="font-normal text-2xl">{formattedDate}</p>
      <p className="font-normal text-2xl">{formattedTime}</p>
    </div>
  );
}

export default DateDisplay;
