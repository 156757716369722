import React from "react";

// Refund Policy Component
const RefundPolicy = () => {
  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      lineHeight: "1.6",
    },
    heading: {
      color: "#333",
      borderBottom: "2px solid #FF5722",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    section: {
      marginBottom: "20px",
    },
    sectionHeading: {
      color: "#FF5722",
      marginBottom: "10px",
    },
    list: {
      marginLeft: "20px",
    },
    listItem: {
      marginBottom: "10px",
    },
    contactLink: {
      color: "#FF5722",
      textDecoration: "none",
    },
  };
  console.log("Refund Policy Page Loaded");

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Refund Policy</h1>
      <p>
        At JRISE Shopping, we strive to ensure your satisfaction with every
        purchase. Please review our refund policy, which outlines the process
        for requesting refunds without the option for order cancellation:
      </p>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>1. No Order Cancellation:</h2>
        <p>
          Please note that once an order is placed and confirmed, it cannot be
          cancelled. Our no cancellation policy ensures that orders are
          processed promptly to expedite delivery.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>2. Eligibility for Refunds:</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Product(s) received are defective, damaged, or not as described.
          </li>
          <li style={styles.listItem}>The wrong item(s) were delivered.</li>
          <li style={styles.listItem}>
            The product(s) received do not match the order details.
          </li>
          <li style={styles.listItem}>
            Non-delivery of the ordered item(s) within the specified timeframe.
          </li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>3. Refund Request Process:</h2>
        <p>
          To request a refund, please contact our customer support team within 3
          days of receiving your order. Provide detailed information regarding
          the issue, including order number, product(s) affected, and a brief
          description of the problem.
        </p>
        <p>
          Our support team will review your request and may require additional
          information or evidence, such as photographs of the damaged or
          incorrect items.
        </p>
        <p>
          Refund requests submitted after the specified timeframe may not be
          eligible for consideration.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>4. Refund Approval:</h2>
        <p>
          Once your refund request is approved, we will initiate the refund
          process. Refunds will be issued to the original payment method used
          for the purchase.
        </p>
        <p>
          Please allow 10 business days for the refunded amount to reflect in
          your account, depending on your payment provider's processing time.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>5. Return of Product(s):</h2>
        <p>
          In some cases, we may request the return of the product(s) for
          inspection or verification purposes before processing the refund. Our
          customer support team will provide instructions on how to return the
          item(s) if necessary.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>6. Refund Exceptions:</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Refunds will not be provided for the following reasons:
          </li>
          <ul style={styles.list}>
            <li style={styles.listItem}>Change of mind or preference.</li>
            <li style={styles.listItem}>
              Incorrect selection of product(s) by the customer.
            </li>
            <li style={styles.listItem}>
              Items damaged due to improper use, mishandling, or neglect.
            </li>
            <li style={styles.listItem}>
              Products that have been used or are not in their original
              condition and packaging.
            </li>
          </ul>
        </ul>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>7. Contact Us:</h2>
        <p>
          If you have any questions or need assistance regarding refunds, please
          don't hesitate to contact our customer support team. We're here to
          help address any concerns you may have.
        </p>
        <p>
          By making a purchase on JRISE Shopping, you agree to abide by our
          refund policy outlined above. We appreciate your understanding and
          cooperation. Thank you for choosing JRISE Shopping for your shopping
          needs.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
