import React from "react";
import Modal from "react-modal";
import { Card, CardContent, Typography } from "@mui/material";

const WorkflowCompletionCard = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          maxWidth: "fit-content",
          maxHeight: "fit-content",
          margin: "auto",
        },
      }}
    >
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Process Completed!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Thank you for completing the workflow.
          </Typography>
          <button className="btn btn-primary" onClick={onClose}>
            Close
          </button>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default WorkflowCompletionCard;
