import axios from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import {
  GenerateInvoiceRoutes,
  GenerateTaxInvoiceRoutes,
  GetSalesOrderRoutes,
  OrderHistoryContextRoutes,
  RefreshFilesFormOrderRoutes,
  updateTaxInvoiceApi,
} from "../api/user.api.js";
import { useAuth } from "./UserProviderMongo.jsx";
import { useNavigate } from "react-router-dom";

const TaxInvoiceMongo = createContext();

const TaxInvoiceMongoContext = ({ children }) => {
  const navigate = useNavigate();

  const { user } = useAuth([]);

  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [getSaleOrderForm, setGetSaleOrderForm] = useState([]);
  const [updatedTaxInvoiceData, setUpdatedTaxInvoice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //ANCHOR - DELIVERY CHALLAN USESTATE
  const [getDeliveryChallan, SetGetDeliveryChallan] = useState([]);

  useEffect(() => {
    // Set userId state if user object is available
    if (user) {
      setUserId(user.userId);
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        await getSalesOrder(userId);
        await orderHistoryContext(userId);
      } else {
        // navigate("/");
      }
    };
    fetchData();
  }, [user, userId]);

  const orderHistoryContext = async (userId) => {
    const userFilesUrl = OrderHistoryContextRoutes.getUserFiles(userId);
    try {
      const response = await axios.get(userFilesUrl);
      if (response.status === 200) {
        setFiles(response.data);
      }
    } catch (error) {
      console.error("Error fetching userId files:", error);
    }
  };

  const getSalesOrder = async (userId) => {
    const apiUrl = GetSalesOrderRoutes.GetSalesOrder(userId);
    try {
      setError(null);
      const response = await axios.get(apiUrl);
      setGetSaleOrderForm(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      );
    }
  };

  const UpdateTaxInvoice = async (formData, invoiceId) => {
    try {
      setError(null);
      const apiUrl = `${updateTaxInvoiceApi.updateTaxInvoiceData(invoiceId)}`;
      const response = await axios.put(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
          // Add any other required headers here
        },
        // Remove 'cors: "no-cors"' option
      });
      setUpdatedTaxInvoice(response?.data);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
        getSalesOrder();
      } else {
        toast.error(response?.data?.message); // Fixed error handling
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "An error occurred.", {
        position: "top-right",
        autoClose: 3000,
      });
      setError(error.response?.data?.message || "An error occurred.");
    }
  };

  const refreshFiles = useCallback(async () => {
    const apiUrl = RefreshFilesFormOrderRoutes.RefreshFilesFormOrder(userId);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const updatedData = await response.json();
      setUpdatedTaxInvoice(updatedData);
    } catch (error) {
      console.error("Error simulating data update:", error);
    }
  }, [userId]);

  const generateTaxInvoice = async (fileId, invoiceNumber) => {
    setIsLoading(true);
    const apiUrl = GenerateTaxInvoiceRoutes?.GenerateTaxInvoice(fileId);
    const invoiceName = invoiceNumber;
    // console.log(
    //   "🚀 ~ file: UserProviderMongo.jsx:287 ~ generateInvoice ~ invoiceName:",
    //   invoiceName
    // );

    try {
      const response = await axios.get(apiUrl, {
        responseType: "blob",
      });
      console.log("🚀 ~ generateTaxInvoice ~ response:", response);

      // Log the entire response headers to check Content-Disposition
      console.log("Response Headers:", response.headers);

      const contentDisposition = response.headers["content-disposition"];
      const contentDispositionX = response.headers["X-Filename"];
      console.log(
        "🚀 ~ generateTaxInvoice ~ contentDispositionX:",
        contentDispositionX
      );
      console.log(
        "🚀 ~ generateTaxInvoice ~ contentDisposition:",
        contentDisposition
      );

      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename=(.*?)(;|$)/);
      const suggestedFilename = filenameMatch
        ? decodeURIComponent(filenameMatch[1])
        : `${invoiceName}.pdf`;

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = suggestedFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ generateInvoice ~ err:", err);
      setError(err);
      setIsLoading(false);
    }
  };

  //ANCHOR - DELIVERY CHALLAN CRUD

  const getDeliveryChallanContext = async (userId) => {
    const apiUrl = GetSalesOrderRoutes.GetSalesOrder(userId);
    try {
      setError(null);
      const response = await axios.get(apiUrl);
      SetGetDeliveryChallan(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      );
    }
  };

  const value = {
    getSaleOrderForm,
    orderHistoryContext,
    files,
    getSalesOrder,
    UpdateTaxInvoice,
    updatedTaxInvoiceData,
    refreshFiles,
    generateTaxInvoice,
    // ANCHOR- delivery Challan
    getDeliveryChallanContext,
    getDeliveryChallan,
    isLoading,
    error,
  };

  return (
    <TaxInvoiceMongo.Provider value={value}>
      {children}
    </TaxInvoiceMongo.Provider>
  );
};

const useTaxInvoiceMongo = () => useContext(TaxInvoiceMongo);

export { TaxInvoiceMongoContext, useTaxInvoiceMongo };
