import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import "./profile.scss"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/Edit";
import EditInvoiceForm from "../invoices/EditInvoiceForm.jsx";
import { Link } from "react-router-dom";

function ProfileSetting() {
  const { user } = useAuth();
  const { getClientData } = useAuth();
  console.log(
    "🚀 ~ file: profile.jsx:13 ~ ProfileSetting ~ getClientData:",
    getClientData
  );

  const [profileData, setProfileData] = useState([]);
  const [getClientDataState, setGetClientData] = useState([]);
  console.log(
    "🚀 ~ file: profile.jsx:16 ~ ProfileSetting ~ getClientDataState:",
    getClientDataState
  );
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleEditClick = (file) => {
    setShowEditModal(true);
    setEditFile(file);
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
  };
  useEffect(() => {
    setProfileData(user);
    setGetClientData(getClientData?.data);
    console.log(
      "🚀 ~ file: profile.jsx:36 ~ useEffect ~ getClientData?.registrationData:",
      getClientData?.data
    );
  }, [user, getClientData]);

  return (
    <div className="profile-container">
      <div className="profile-card">
        <div className="profile-header">
          {/* <img
            src={profileData?.profileImage}
            alt="Profile"
            className="profile-image"
          /> */}
          <div className="profile-icon m-10">
            <FontAwesomeIcon icon={faUserCircle} size="3x" />
          </div>

          <div className="profile-details">
            <div className="profile-name">
              Hello, {profileData?.firstName + " " + profileData?.lastName}
            </div>
            <div className="profile-email">{profileData?.email}</div>
          </div>
        </div>
        {!getClientDataState && (
          <div className="profile-details">
            <Link className="profile-email" to="/registration">
              Seems Like you have not registered details, Click here.
            </Link>
          </div>
        )}
      </div>

      {/* Billing Information */}
      <div className="profile-section">
        <div className="profile-card">
          <div className="section-title">
            Billing Information{" "}
            <button
              onClick={() => handleEditClick()}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <EditIcon color="primary" />
            </button>
          </div>

          <div className="profile-info">
            <div className="info-item">
              <div className="info-label">Name:</div>
              {getClientDataState?.billTo?.name}
            </div>
            <div className="info-item">
              <div className="info-label">Address:</div>
              {getClientDataState?.billTo?.address}
            </div>
            <div className="info-item">
              <div className="info-label">City:</div>
              {getClientDataState?.billTo?.city}
            </div>
            <div className="info-item">
              <div className="info-label">State:</div>
              {getClientDataState?.billTo?.state}
            </div>
            <div className="info-item">
              <div className="info-label">Pin Code:</div>
              {getClientDataState?.billTo?.pinCode}
            </div>
            <div className="info-item">
              <div className="info-label">GST:</div>
              {getClientDataState?.billTo?.gstinOrPan}
            </div>
          </div>
        </div>
      </div>

      {/* Payment Information */}
      <div className="profile-section">
        <div className="profile-card">
          <div className="section-title">Payment Information</div>
          <div className="profile-info">
            <div className="info-item">
              <div className="info-label">Account Name:</div>
              {getClientDataState?.paymentInfo?.accountName}
            </div>
            <div className="info-item">
              <div className="info-label">Account Number:</div>
              {getClientDataState?.paymentInfo?.accountNumber}
            </div>
            <div className="info-item">
              <div className="info-label">Bank Name:</div>
              {getClientDataState?.paymentInfo?.bankName}
            </div>
            <div className="info-item">
              <div className="info-label">IFSC Code:</div>
              {getClientDataState?.paymentInfo?.ifscCode}
            </div>
          </div>
        </div>
      </div>

      {/* Payment Instructions & Terms */}
      <div className="profile-section">
        <div className="profile-card">
          <div className="section-title">Payment Instructions & Terms</div>
          <div className="full-width">
            <div className="info-item">
              <div className="info-label">Payment Instructions:</div>
              {getClientDataState?.paymentInstructions}
            </div>
          </div>
          <div className="full-width">
            <div className="info-item">
              <div className="info-label">Terms And Conditions:</div>
              {getClientDataState?.termsAndConditions}
            </div>
          </div>
        </div>
      </div>
      {showEditModal && (
        <EditInvoiceForm
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
    </div>
  );
}

export default ProfileSetting;
