import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";
import SubmitButton from "../../../bulk-order/submit.btn.component.jsx";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px", // Increase padding for better spacing
    maxWidth: "800px", // Set a maximum width for the modal
    width: "100%", // Make the modal responsive
    maxHeight: "80vh", // Set a maximum height for the modal (80% of the viewport height)
    overflowY: "auto", // Enable vertical scrolling if the content exceeds the height
    margin: "10px",
  },
};

Modal.setAppElement("#root");

function CreateProductAdmin({ isOpen, onRequestClose }) {
  const { createProductWithAdminRole } = useUser();
  const [formData, setFormData] = useState({
    images: [], // Initialize with an empty array
    brand: "",
    category: "",
    modelNo: "",
    productFeature: "",
    warrantyPeriod: "",
    name: "",
    description: "",
    descriptionTwo: "",
    descriptionThree: "",
    productWeight: 0,
    price: 0,
    countInStock: 0,
    deleted: false,
    productDetails: {
      brand: "",
      category: "",
      modelNo: "",
      productFeature: "",
      warrantyPeriod: "",
    },
    productDimensions: {
      length: 0,
      breadth: 0,
      height: 0,
    },
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      if (name === "brand" || name === "category") {
        setFormData({
          ...formData,
          [name]: value,
          productDetails: {
            ...formData.productDetails,
            [name]: value,
          },
        });
      } else if (name.startsWith("productDimensions.")) {
        // Handle changes for productDimensions fields
        const dimensionName = name.split(".")[1];
        setFormData({
          ...formData,
          productDimensions: {
            ...formData.productDimensions,
            [dimensionName]: value,
          },
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const handleLogoInputChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: Array.from(files), // Convert files to an array
      }));
    }
  };

  const handleSave = async () => {
    try {
      // Create form data
      // Create form data
      const formDataWithUpdatedIsAdmin = new FormData();

      Object.entries(formData).forEach(([key, value]) => {
        if (key === "images" && Array.isArray(value)) {
          // Append each images file to FormData
          value.forEach((file, index) => {
            formDataWithUpdatedIsAdmin.append(`images`, file);
          });
        } else if (typeof value === "object" && value !== null) {
          // If the value is an object, handle nested fields
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            formDataWithUpdatedIsAdmin.append(
              `${key}.${nestedKey}`,
              nestedValue
            );
          });
        } else {
          // Otherwise, append as is
          formDataWithUpdatedIsAdmin.append(key, value);
        }
      });

      // Make Axios call
      const response = await createProductWithAdminRole(
        formDataWithUpdatedIsAdmin
      );
      console.log(response.data);
      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
        toast.success("Product created successfully");

        // Clear form fields
        setFormData((prevFormData) => ({
          ...prevFormData,
          images: [], // Clear the images array
          brand: "",
          category: "",
          modelNo: "",
          productFeature: "",
          name: "",
          hsnCode: "",
          description: "",
          descriptionTwo: "",
          descriptionThree: "",
          productWeight: 0,
          price: 0,
          countInStock: 0,
          deleted: false,
          productDetails: {
            brand: "",
            category: "",
            modelNo: "",
            productFeature: "",
            warrantyPeriod: "",
            hsnCode: "",
          },
          productDimensions: {
            length: 0,
            breadth: 0,
            height: 0,
          },
        }));

        onRequestClose();
      } else {
        toast.error("Submission failed.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Create New Product</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="images">
                {`Image`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                multiple
                type="file"
                name="images"
                accept="image/*"
                onChange={handleLogoInputChange}
              />
              {formData?.images && formData?.images?.length > 0 && (
                <div>
                  Selected Files:{" "}
                  {formData?.images.map((file) => file?.name).join(", ")}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="brand">
                {`brand`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="brand"
                placeholder="Brand"
                value={formData.brand}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="category">
                {" "}
                {`Category`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="category"
                placeholder="Category"
                value={formData.category}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="modelNo">
                {" "}
                {`Model No`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="modelNo"
                placeholder="modelNo"
                value={formData.modelNo}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="productFeature">
                {" "}
                {`Product Feature`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="productFeature"
                placeholder="productFeature"
                value={formData.productFeature}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="warrantyPeriod">
                {" "}
                {`warranty Period`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="warrantyPeriod"
                placeholder="warrantyPeriod"
                value={formData.warrantyPeriod}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="name">
                {`Name`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="hsnCode">
                {`HSN code`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="hsnCode"
                placeholder="hsnCode"
                value={formData.hsnCode}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="description">
                {`Description`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="text"
                name="description"
                placeholder="Description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="descriptionTwo">Description Two</label>
              <input
                type="text"
                name="descriptionTwo"
                placeholder="description Two"
                value={formData.descriptionTwo}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="descriptionThree">Description Three</label>
              <input
                type="text"
                name="descriptionThree"
                placeholder="description Three"
                value={formData.descriptionThree}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="productWeight">{`Product Weight (in kgs)`}</label>
              <input
                type="number"
                name="productWeight"
                placeholder="Product Weight"
                value={formData.productWeight}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {/* productDimensions */}
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="productDimensions.length">{`Product Dimensions(length)`}</label>
              <input
                type="number"
                name="productDimensions.length"
                placeholder="Product Length"
                value={formData.productDimensions.length}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="productDimensions.breadth">{`Product Dimensions(breadth)`}</label>
              <input
                type="number"
                name="productDimensions.breadth"
                placeholder="Product breadth"
                value={formData.productDimensions.breadth}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="productDimensions.height">{`Product Dimensions(height)`}</label>
              <input
                type="number"
                name="productDimensions.height"
                placeholder="Product height"
                value={formData.productDimensions.height}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="price">
                {`Price`} <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="number"
                name="price"
                placeholder="Price"
                value={formData.price}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="countInStock">
                {`Count in Stock `}
                <StarIcon style={{ fontSize: "smaller" }} />
              </label>
              <input
                type="number"
                name="countInStock"
                placeholder="Count in Stock"
                value={formData.countInStock}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="deleted">Product Deleted</label>
              <input
                className="form-check-input"
                type="checkbox"
                name="deleted"
                checked={formData.deleted}
                onChange={handleInputChange}
                id="deleted"
                style={{ width: "50px", height: "30px", display: "flex" }}
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default CreateProductAdmin;
