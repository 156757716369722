import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  SignInRoutes,
  SignUpRoutes,
  OrderHistoryContextRoutes,
  OrderDownloadRoutes,
  RefreshFilesRoutes,
  createMfiRoutes,
  getClientBillingDataAllRoutes,
  GetClientBillingDataRoutes,
  GenerateInvoiceRoutes,
  CreateSaleOrderRoutes,
  GetSalesOrderRoutes,
  RefreshFilesFormOrderRoutes,
  updateSaleOrderRoutes,
  softDeletSaleOrderRoutes,
  softDeleteSaleOrderRoutes,
  getStaticDataRoutes,
  getPurchaseOrderInvoiceRoutes,
  GeneratePurchaseOrderInvoiceRoutes,
  getRefreshPurchaseOrderInvoiceRoutes,
  ValidateTokenRoutes,
  GetAllMfiRoutes,
  UpdateMfiRoutes,
  GetRefreshAllMfiRoutes,
  updateTaxInvoiceApi,
} from "../api/user.api.js";

const UserProviderMongo = createContext();

export function useAuth() {
  return useContext(UserProviderMongo);
}

export function UserProviderMongoContext({ children }) {
  const [user, setUser] = useState(null);
  // console.log(
  //   "🚀 ~ file: UserProviderMongo.jsx:45 ~ UserProviderMongoContext ~ user:",
  //   user
  // );
  const [invoice, setInvoice] = useState(null);
  const [createMfi, SetCreateMfi] = useState(null);
  const [updateMfi, SetUpdateMfi] = useState(null);
  const [getAllMfi, SetGetAllMfi] = useState(null);
  // console.log(
  //   "🚀 ~ file: UserProviderMongo.jsx:53 ~ UserProviderMongoContext ~ getAllMfi:",
  //   getAllMfi
  // );
  const [error, setError] = useState(null);
  const [getClientData, setGetClientData] = useState(null);

  const [fileData, setFileData] = useState([]);
  const [saleOrderForm, setSaleOrderForm] = useState([]);
  const [getSaleOrderForm, setGetSaleOrderForm] = useState([]);
  const [getPurchaseOrderForm, setPurchaseOrderForm] = useState([]);
  const [updateSaleOrderForm, setUpdateSaleOrderForm] = useState([]);
  const [deleteOrderForms, setDeleteOrderForms] = useState([]);
  const [files, setFiles] = useState([]);
  const [getStaticDataS, setGetStaticData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [updatedTaxInvoiceData, setUpdatedTaxInvoice] = useState([]);

  const [isAuth, setAuth] = useState(user); // Set initial value based on the token

  const [isLoading, setIsLoading] = useState(false);

  const userId = user?.userId; // user id

  // console.log(
  //   "🚀 ~ file: UserProviderMongo.jsx:78 ~ UserProviderMongoContext ~ userId:",
  //   userId
  // );

  const signup = async (userData) => {
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(SignUpRoutes, userData);
      // Handle a successful response here
      setUser(response.data);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
      } else {
        toast.error("User Registration failed.");
      }
    } catch (error) {
      console.error(error);

      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  const signin = async (userData) => {
    try {
      setError(null);
      const response = await axios.post(SignInRoutes, userData);
      const userResponse = response.data.user;
      console.log("🚀 ~ signin ~ userResponse:", userResponse);
      // console.log(
      //   "🚀 ~ file: UserProviderMongo.jsx:109 ~ signin ~ userResponse:",
      //   userResponse
      // );

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("userToken", userResponse?.sessionToken);

        const {
          isAdmin,
          isInventoryManager,
          isMfiUser,
          isNewOrderManager,
          isWarehouseManager,
        } = userResponse;

        setRoles({
          isAdmin,
          isInventoryManager,
          isMfiUser,
          isNewOrderManager,
          isWarehouseManager,
        });

        setAuth(true);
        setUser(userResponse);

        toast.success(response?.data?.message);
        window.location.href = "/"; // Replace '/' with the URL of your home page
      } else {
        toast.error("User Registration failed.");
      }
    } catch (error) {
      console.log(error);

      toast.error(
        error?.response?.data?.error || "An error occurred during signIn.",
        {
          position: "top-right",
          autoClose: 3000, // 3 seconds
        }
      );
      setError(
        error?.response?.data?.error || "An error occurred during signIn."
      );
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("userToken");

    const validateToken = async () => {
      try {
        if (storedToken) {
          const response = await axios.post(ValidateTokenRoutes, {
            token: storedToken,
          });

          if (response.data.success) {
            // Extract roles from the user object
            const {
              isAdmin,
              isInventoryManager,
              isMfiUser,
              isNewOrderManager,
              isWarehouseManager,
            } = response.data.user;

            // Update your state or do anything with the roles as needed
            // For example, if you have a state variable roles, you can set it like this:
            setRoles({
              isAdmin,
              isInventoryManager,
              isMfiUser,
              isNewOrderManager,
              isWarehouseManager,
            });

            // Set authentication status directly
            setAuth(true);

            // Set the user data
            setUser(response.data.user);
            // Reload the page after successful sign-in
          } else {
            // If the token is not valid, clear it from localStorage and set authentication to false
            localStorage.removeItem("userToken");
            setAuth(false);
          }
        } else {
          // If there is no stored token, set authentication to false
          setAuth(false);
        }
      } catch (error) {
        console.error("Error validating token:", error);
        setAuth(false);
      }
    };

    validateToken();

    // Periodically revalidate the token (adjust the interval as needed)
    const tokenValidationInterval = setInterval(validateToken, 60000); // 1 minute
    return () => clearInterval(tokenValidationInterval); // Cleanup on component unmount
  }, []);

  // Function to set a cookie with a specific expiration time
  const setCookie = (name, value, hours) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000);

    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const signout = () => {
    // Clear the user data when signing out
    setUser(null);
    localStorage.removeItem("userToken");
  };

  const orderHistoryContext = async (userId) => {
    const userFilesUrl = OrderHistoryContextRoutes.getUserFiles(userId);
    try {
      const response = await axios.get(userFilesUrl);
      if (response.status === 200) {
        setFiles(response.data);
      }
    } catch (error) {
      console.error("Error fetching user files:", error);
    }
  };

  const orderDownload = async (fileId) => {
    const OrderDownloadRoute = OrderDownloadRoutes.OrderDownload(fileId);

    try {
      const response = await axios({
        url: OrderDownloadRoute, // Replace with your API endpoint that serves the XLSX file
        method: "GET",
        responseType: "blob", // Important: responseType must be 'blob' for binary data
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.xlsx"; // Specify the file name here
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading XLSX file:", error);
    }
  };

  // Simulate fetching and updating data
  const refreshFiles = useCallback(async () => {
    const apiUrl = RefreshFilesFormOrderRoutes.RefreshFiles(userId);
    try {
      // Simulate fetching data from the API
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const updatedData = await response.json(); // Parse the response JSON
      // Update the context data with the new data
      setFiles(updatedData);
      setGetSaleOrderForm(files);
    } catch (error) {
      console.error("Error simulating data update:", error);
    }
  }, [userId]);

  const generateInvoice = async (fileId, invoiceNumber) => {
    setIsLoading(true);
    const apiUrl = GenerateInvoiceRoutes?.GenerateInvoice(fileId);
    const invoiceName = invoiceNumber;
    // console.log(
    //   "🚀 ~ file: UserProviderMongo.jsx:287 ~ generateInvoice ~ invoiceName:",
    //   invoiceName
    // );

    try {
      const response = await axios.get(apiUrl, {
        responseType: "blob",
      });

      // Log the entire response headers to check Content-Disposition
      // console.log("Response Headers:", response.headers);

      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename=(.*?)(;|$)/);
      const suggestedFilename = filenameMatch
        ? decodeURIComponent(filenameMatch[1])
        : `${invoiceName}.pdf`;

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = suggestedFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ generateInvoice ~ err:", err);
      setError(err);
      setIsLoading(false);
    }
  };

  // const generateInvoice = async (fileId) => {
  //   setIsLoading(true);
  //   const apiUrl = GenerateInvoiceRoutes?.GenerateInvoice(fileId);
  //   try {
  //     const response = await axios.get(apiUrl, {
  //       responseType: "blob", // Important: responseType must be 'blob' for binary data
  //     });
  //     const blob = new Blob([response.data], {
  //       type: "application/pdf",
  //     });

  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = "invoice.pdf"; // Specify the filename
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     // Replace with your server endpoint
  //     setInvoice(response.data);
  //     setIsLoading(false);

  //     // Trigger the download once data is received
  //     downloadInvoice(response.data);
  //   } catch (err) {
  //     setError(err);
  //     setIsLoading(false);
  //   }
  // };

  const downloadInvoice = (data) => {
    try {
      // Create a Blob containing the PDF data
      const blob = new Blob([data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      // Create a temporary <a> element and simulate a click to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf";
      a.click();

      // Clean up the URL object after the download
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during PDF download:", error);
    }
  };

  // Invoice Creation
  const createMfiContext = async (formData) => {
    const apiUrl = createMfiRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(apiUrl, formData);
      SetCreateMfi(response.data);

      // Show a success toast
      toast.success("Invoice created successfully!", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
      });
    } catch (error) {
      console.log(
        "🚀 ~ file: UserProviderMongo.jsx:227 ~ createMfiContext ~ error:",
        error
      );
      // Show an error toast
      toast.error(
        error.response?.data?.message ||
          "An error occurred while creating the invoice.",
        {
          position: "bottom-right",
          autoClose: 3002, // Close the toast after 5 seconds
        }
      );
    }
  };

  const updateMfiContext = async (formData, uniqueId) => {
    const apiUrl = UpdateMfiRoutes.UpdateMfi(uniqueId);
    try {
      setError(null); // Clear any previous errors
      const response = await axios.put(apiUrl, formData);
      SetUpdateMfi(response.data);

      // Show a success toast
      toast.success("Invoice Updated successfully!", {
        position: "top-right",
        autoClose: 3000, // Close the toast after 3 seconds
      });
      await getAllMfiContext();
    } catch (error) {
      console.log(
        "🚀 ~ file: UserProviderMongo.jsx:227 ~ createMfiContext ~ error:",
        error
      );
      // Show an error toast
      toast.error(
        error.response?.data?.message ||
          "An error occurred while creating the invoice.",
        {
          position: "bottom-right",
          autoClose: 3002, // Close the toast after 5 seconds
        }
      );
    }
  };

  // Invoice Creation
  const getAllMfiContext = async (formData) => {
    const apiUrl = GetAllMfiRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl, formData);
      SetGetAllMfi(response.data);
    } catch (error) {
      console.log(
        "🚀 ~ file: UserProviderMongo.jsx:227 ~ createMfiContext ~ error:",
        error
      );
    }
  };

  // Invoice Creation
  const getRefreshAllMfiContext = async (formData) => {
    const apiUrl = GetRefreshAllMfiRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await fetch(apiUrl, formData);
      const updatedData = await response.json(); // Parse the response JSON
      // console.log(
      //   "🚀 ~ file: UserProviderMongo.jsx:381 ~ getRefreshAllMfiContext ~ updatedData:",
      //   updatedData
      // );
      // Update the context data with the new data
      SetGetAllMfi(updatedData);
    } catch (error) {
      console.log(
        "🚀 ~ file: UserProviderMongo.jsx:227 ~ createMfiContext ~ error:",
        error
      );
    }
  };

  // Billing Data get
  const getClientBillingDataAll = async (id) => {
    const ApiUrl =
      getClientBillingDataAllRoutes.getClientBillingDataAll(userId);
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(ApiUrl);

      // Handle a successful response here
      setGetClientData(response.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // Billing Data put
  const getClientBillingData = async (id, updatedData) => {
    const ApiUrl = GetClientBillingDataRoutes.BillingData(id);
    try {
      setError(null); // Clear any previous errors
      const response = await axios.put(ApiUrl, updatedData);
      // Handle a successful response here
      setGetClientData(response.data);
    } catch (error) {
      console.error(error);

      setError(
        error.response?.data?.message || "An error occurred during the update."
      ); // Handle errors here
    }
  };

  // Invoice Creation
  const createSaleOrder = async (formData) => {
    const apiUrl = CreateSaleOrderRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(apiUrl, formData);
      setSaleOrderForm(response?.data);
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message || "Order Filled!");
      } else {
        toast.error(error.response?.data?.message || "Order Failed");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Order Failed", {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // Invoice Creation
  const getSalesOrder = async () => {
    // const apiUrl = GetSalesOrderRoutes;
    const apiUrl = GetSalesOrderRoutes.GetSalesOrder(userId);
    // console.log("🚀 ~ getSalesOrder ~ apiUrl:", apiUrl);

    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl, {
        params: {
          deleted: false, // Exclude soft-deleted forms
        },
      });
      // console.log("🚀 ~ getSalesOrder ~ response:", response);
      setGetSaleOrderForm(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // Simulate fetching and updating data
  const refreshFilesFormOrder = async () => {
    const apiUrl = RefreshFilesFormOrderRoutes.RefreshFilesFormOrder(userId);

    try {
      // Simulate fetching data from the API
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const updatedData = await response.json(); // Parse the response JSON
      // Update the context data with the new data
      setGetSaleOrderForm(updatedData);
    } catch (error) {
      console.error("Error simulating data update:", error);
    }
  };

  // Update Upload Order
  const updateSaleOrder = async (formData, uid) => {
    try {
      setError(null); // Clear any previous errors

      // Convert the UID to a string if it's not already
      const id = typeof uid === "string" ? uid : uid.toString();
      const apiUrl = `${updateSaleOrderRoutes.UpdateSaleOrder(id)}`;

      const response = await axios.put(apiUrl, formData);

      setUpdateSaleOrderForm(response?.data);

      // After a successful update, call getSalesOrder to update getSaleOrderForm
      await getSalesOrder();

      // setGetSaleOrderForm(updateSaleOrderForm);
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message, {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  const handleSoftDelete = async (uid) => {
    // Make an API call to soft delete the order form
    try {
      // Convert the UID to a string if it's not already
      // const id = typeof uid === "string" ? uid : uid.toString();
      const id = uid;

      const apiUrl = `${softDeleteSaleOrderRoutes.SoftDeleteSaleOrder(id)}`;
      const formData = { userId };
      const response = await axios.delete(apiUrl, formData);

      if (response.ok) {
        // Update the local state to mark the order form as soft-deleted
        setDeleteOrderForms((prevOrderForms) =>
          prevOrderForms.map((form) =>
            form._id === id ? { ...form, deleted: true } : form
          )
        );
        await getSalesOrder();
      } else {
        console.error("Failed to soft delete the order form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Invoice Creation
  const getStaticData = async () => {
    // const apiUrl = GetSalesOrderRoutes;
    const apiUrl = getStaticDataRoutes;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      setGetStaticData(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR - PurchaseOrder
  // getPurchaseOrder
  const getPurchaseOrder = async () => {
    const apiUrl =
      getPurchaseOrderInvoiceRoutes.PurchaseOrderInvoiceRoutes(userId);

    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      // console.log(
      //   "🚀 ~ file: UserProviderMongo.jsx:437 ~ getPurchaseOrder ~ response:",
      //   response
      // );
      setPurchaseOrderForm(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // getPurchaseOrder
  const getRefreshPurchaseOrder = async () => {
    const apiUrl =
      getRefreshPurchaseOrderInvoiceRoutes.PurchaseOrderInvoiceRoutes(userId);

    try {
      setError(null); // Clear any previous errors
      const response = await axios.fetch(apiUrl);
      // console.log(
      //   "🚀 ~ file: UserProviderMongo.jsx:437 ~ getPurchaseOrder ~ response:",
      //   response
      // );
      setPurchaseOrderForm(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  const generatePurchaseOrder = async (fileId) => {
    setIsLoading(true);
    const apiUrl =
      GeneratePurchaseOrderInvoiceRoutes.PurchaseOrderInvoiceRoutes(fileId);
    try {
      const response = await axios.get(apiUrl, {
        responseType: "blob", // Important: responseType must be 'blob' for binary data
      });
      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf"; // Specify the filename
      a.click();
      window.URL.revokeObjectURL(url);
      // Replace with your server endpoint
      setInvoice(response.data);
      setIsLoading(false);

      // Trigger the download once data is received
      downloadInvoice(response.data);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // orderHistoryContext();
    // getSalesOrder();
    getClientBillingDataAll();
    // getSalesOrder();
    getPurchaseOrder();
  }, [userId]);

  useEffect(() => {
    getStaticData();
    getAllMfiContext();
  }, []);

  const value = {
    user,
    files,
    orderHistoryContext,
    refreshFiles,
    fileData,
    orderDownload,
    generateInvoice,
    invoice,
    isLoading,
    error,
    signup,
    signin,
    signout,
    createMfi,
    updateMfi,
    getAllMfi,
    createMfiContext,
    getClientData,
    getClientBillingData,
    saleOrderForm,
    createSaleOrder,
    getSaleOrderForm,
    updateSaleOrderForm,
    getSalesOrder,
    refreshFilesFormOrder,
    updateSaleOrder,
    deleteOrderForms,
    handleSoftDelete,
    getStaticData,
    getStaticDataS,
    getPurchaseOrderForm,
    getPurchaseOrder,
    generatePurchaseOrder,
    updateMfiContext,
    getRefreshAllMfiContext,
  };

  return (
    <UserProviderMongo.Provider value={value}>
      {children}
    </UserProviderMongo.Provider>
  );
}
