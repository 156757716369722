// HomePage.js

import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  CreateBannerHomePageRoutes,
  CreateBrandsHomePageRoutes,
  DeleteBannerHomePageRoutes,
  DeleteBrandsHomePageRoutes,
  GetBannerHomePageRoutes,
  GetBrandsHomePageRoutes,
  UpdateBannerHomePageRoutes,
  UpdateBrandsHomePageRoutes,
} from "../api/user.api.js";

// Create a context
const HomePage = createContext();

// Create a provider component
export const HomePageContext = ({ children }) => {
  // Define your state variables and functions here
  const [data, setData] = useState(null);
  const [getBannerContent, setGetBannerContent] = useState(null);
  const [getBrandsContent, SetGetBrandsContent] = useState(null);
  const [createBrandsContent, SetCreateBrandsContent] = useState(null);
  const [deleteBannerContent, SetDeleteBannerContent] = useState(null);
  const [updateBannerContent, setUpdateBannerContent] = useState(null);
  const [updateBrandsContent, SetBrandsBannerContent] = useState(null);
  const [createBannerContent, SetCreateBannerContent] = useState(null);
  const [deleteBrandsContent, SetDeleteBrandsContent] = useState(null);

  const [error, setError] = useState(null);

  // Example function to update the context data
  const updateData = (newData) => {
    setData(newData);
  };

  // ANCHOR - GET Banner Content
  const getBannerContext = async () => {
    const apiUrl = GetBannerHomePageRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      setGetBannerContent(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - update Banner Content
  const UpdateBannerContext = async (uniqueId, formDataHandleSave) => {
    const id = uniqueId;
    const apiUrl = `${UpdateBannerHomePageRoutes.UpdateBannerHomePage(id)}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.put(apiUrl, formDataHandleSave);
      setUpdateBannerContent(response?.data);
      await getBannerContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - Delete Banner Content
  const DeleteBannerContext = async (uniqueId) => {
    const id = uniqueId;
    const apiUrl = `${DeleteBannerHomePageRoutes.DeleteBannerHomePage(id)}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.delete(apiUrl);
      SetDeleteBannerContent(response?.data);
      await getBannerContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - Create Banner Content
  const CreateBannerContext = async (formDataCreate) => {
    const apiUrl = `${CreateBannerHomePageRoutes}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(
        CreateBannerHomePageRoutes,
        formDataCreate
      );
      SetCreateBannerContent(response?.data);
      await getBannerContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - GET Brands Content
  const getBrandsContext = async () => {
    const apiUrl = GetBrandsHomePageRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      SetGetBrandsContent(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - Create Banner Content
  const createBrandsContext = async (formDataCreate) => {
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(
        CreateBrandsHomePageRoutes,
        formDataCreate
      );
      SetCreateBrandsContent(response?.data);
      await getBrandsContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - update Brands Content
  const UpdateBrandsContext = async (uniqueId, formDataHandleSave) => {
    const id = uniqueId;
    const apiUrl = `${UpdateBrandsHomePageRoutes.UpdateBrandsHomePage(id)}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.put(apiUrl, formDataHandleSave);
      SetBrandsBannerContent(response?.data);
      await getBrandsContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  // ANCHOR - Delete Banner Content
  const DeleteBrandsContext = async (uniqueId) => {
    const id = uniqueId;
    const apiUrl = `${DeleteBrandsHomePageRoutes.DeleteBrandsHomePage(id)}`;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.delete(apiUrl);
      SetDeleteBrandsContent(response?.data);
      await getBrandsContext();
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during data fetch."
      ); // Handle errors here
    }
  };

  useEffect(() => {
    getBannerContext();
    getBrandsContext();
  }, []);

  const state = {
    data,
    error,
    updateData,
    updateBannerContent,
    getBannerContent,
    UpdateBannerContext,
    deleteBannerContent,
    DeleteBannerContext,
    CreateBannerContext,
    createBannerContent,
    getBrandsContent,
    getBrandsContext,
    createBrandsContext,
    createBrandsContent,
    UpdateBrandsContext,
    updateBrandsContent,
    DeleteBrandsContext,
    deleteBrandsContent,
  };

  return <HomePage.Provider value={state}>{children}</HomePage.Provider>;
};

// Create a custom hook for accessing the context
export const useHomePage = () => {
  return useContext(HomePage);
};
