import React, { useEffect, useState } from "react";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationModal from "../../../success-modal/deleteModal.js";
import Spinner from "react-bootstrap/esm/Spinner.js";
import PaginationComponent from "../../../pagination/PaginationComponent.jsx";
import "./userAdmin.styles.css";
import EditUserModal from "./userAdminTab/editUserAdmin.component.jsx";
import LoadingOverlay from "../../../user-profile/order-history/LoadingOverlay.jsx";

function UserAdminComponent() {
  const { getAllUsers } = useUser();
  console.log(
    "🚀 ~ file: userAdmin.component.jsx:16 ~ UserAdminComponent ~ getAllUsers:",
    getAllUsers
  );
  const { refreshFilesFormOrder } = useUser([]);

  const pageSize = 10; // Number of rows per page
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  // const handleDeleteClick = async (file) => {
  //   await handleSoftDelete();
  // };

  const handleDeleteClick = (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (file) => {
    // e.preventDefault();
    const uid = file;

    try {
      // console.log(formData);
      //   await handleSoftDelete(uid);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refreshFilesFormOrder();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // handleRefresh();
  }, []);

  if (isLoading) {
    return (
      <div>
        <h1>Users</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!getAllUsers || !getAllUsers.length === 0) {
    console.log(
      "🚀 ~ file: userAdmin.component.jsx:87 ~ UserAdminComponent ~ getAllUsers:",
      getAllUsers
    );
    return (
      <div>
        <h1>Users</h1>
        <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
        <p>No files found for the user.</p>
      </div>
    );
  }

  // For Form Order
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, getAllUsers.length);
  const formUploadOrder = Array.isArray(getAllUsers)
    ? getAllUsers.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(getAllUsers.length / pageSize);

  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  return (
    <div className="order-history-body">
      <div className="form-upload-order-container">
        {" "}
        <h1>Current Users</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>userId</th>
              <th>firstName</th>
              <th>lastName</th>
              <th>email</th>
              <th>Admin</th>
              <th>Mfi</th>
              <th>Order Manager</th>
              <th>Warehouse Manager</th>
              <th>Inventory Manager</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formUploadOrder.map((file) => (
              <tr key={file._id}>
                <td>{file.userId}</td>
                <td>{file.firstName}</td>
                <td>{file.lastName}</td>
                <td>{file.email}</td>
                <td>{file.isAdmin ? "Yes" : "No"}</td>
                <td>{file.isMfiUser ? "Yes" : "No"}</td>
                <td>{file.isNewOrderManager ? "Yes" : "No"}</td>
                <td>{file.isWarehouseManager ? "Yes" : "No"}</td>
                <td>{file.isInventoryManager ? "Yes" : "No"}</td>
                {/*  */}
                <td>{file.isShaktiUser ? "Yes" : "No"}</td>
                <td>{file.isShaktiAdmin ? "Yes" : "No"}</td>
                <td>{file.isShaktiManager ? "Yes" : "No"}</td>
                <td>{file.isMasterAdmin ? "Yes" : "No"}</td>
                <td>{file.isInventoryManager ? "Yes" : "No"}</td>
                <td>{file.isStateHead ? "Yes" : "No"}</td>
                <td>{file.reportsTo ? "Yes" : "No"}</td>
                <td>{file.reportsToStateHead ? "Yes" : "No"}</td>

                <td>
                  <button
                    onClick={() => handleEditClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon color="primary" />
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => handleDeleteClick(file._id)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon color="error" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {isLoading && <LoadingOverlay />}
      </div>

      {showEditModal && (
        <EditUserModal
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
        />
      )}
    </div>
  );
}

export default UserAdminComponent;
