import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Divider } from "@mui/material";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/UserProviderMongo.jsx";
import { WarehouseContext } from "../../contexts/warehouse.context.jsx";
import FileButtons from "../bulk-order/fileButtons.jsx";
import ShipToButton from "../bulk-order/shipToTemplateBtn.jsx";
import SubmitButton from "../bulk-order/submit.btn.component.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px", // Increase padding for better spacing
    maxWidth: "800px", // Set a maximum width for the modal
    width: "100%", // Make the modal responsive
    maxHeight: "80vh", // Set a maximum height for the modal (80% of the viewport height)
    overflowY: "auto", // Enable vertical scrolling if the content exceeds the height
    margin: "10px",
  },
};

Modal.setAppElement("#root");
const GeneralTaxInvoice = ({ isOpen, onRequestClose }) => {
  const { getStaticDataS } = useAuth([]);
  console.log(
    "🚀 ~ file: bulk-upload.component.jsx:27 ~ GeneralTaxInvoice ~ getStaticDataS:",
    getStaticDataS
  );
  const { user } = useAuth();
  const { getSalesOrder } = useAuth([]);
  const { getAllWarehouseContent } = WarehouseContext([]);
  console.log(
    "🚀 ~ file: bulk-upload.component.jsx:29 ~ GeneralTaxInvoice ~ getAllWarehouseContent:",
    getAllWarehouseContent
  );

  const userId = user?.userId;
  console.log(
    "🚀 ~ file: bulk-upload.component.jsx:40 ~ GeneralTaxInvoice ~ userId:",
    userId
  );
  //For Upload of template
  const [uploadOrder, setUploadOrder] = useState(null);
  const [uploadOrderResData, setUploadOrderResData] = useState([]);
  const [uploadResCheck, setUploadResCheck] = useState(null);

  const data = uploadOrderResData?.data?.map((x) => x);

  const handleTemplateDownload = async (fileId) => {
    try {
      const response = await fetch(GetTaxInvoiceExcelDummyRoutes, {
        method: "GET",
      });

      if (response.status === 200) {
        const contentDisposition = response.headers.get("Content-Disposition");
        const filenameMatch =
          contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch
          ? filenameMatch[1]
          : "Bulk Order Template";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        if (response.status === 200 || response.status === 201) {
          toast.success("Template Downloaded Successfully");
        } else {
          toast.error("Template Download Failed");
        }
      } else {
        console.error("Template Download Failed");
      }
    } catch (error) {
      console.error("Error during Template download:", error);
    }
  };

  const handleShipToTemplateDownload = async (fileId) => {
    try {
      const response = await fetch(GetShipToExcelDummyRoutes, {
        method: "GET",
      });

      if (response.status === 200) {
        const contentDisposition = response.headers.get("Content-Disposition");
        console.log(
          "🚀 ~ handleShipToTemplateDownload ~ contentDisposition:",
          contentDisposition
        );
        const filenameMatch =
          contentDisposition && contentDisposition.match(/filename="(.+)"/);
        console.log(
          "🚀 ~ handleShipToTemplateDownload ~ filenameMatch:",
          filenameMatch
        );
        const filename = filenameMatch
          ? filenameMatch[1]
          : "ShipToTemplateXlsx";

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        if (response.status === 200 || response.status === 201) {
          toast.success("Template Downloaded Successfully");
        } else {
          toast.error("Template Download Failed");
        }
      } else {
        console.error("Template Download Failed");
      }
    } catch (error) {
      console.error("Error during Template download:", error);
    }
  };

  // Form state
  const [formData, setFormData] = useState({
    productFile: null,
    shipToFile: null,
    shipFrom: { name: "", shipFromIdReq: "" },
    invoiceDate: "",
    billTo: { name: "", address: "", taxId: "" },
    shipTo: [{ name: "", address: "", placeOfSupplyShip: "" }],
    userId: 4,
    PaymentTerms: "",
  });
  const [selectedProducts, setSelectedProducts] = useState(
    formData?.shipTo?.map(() => "")
  );
  const [productMapping, setProductMapping] = useState({});

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      // Split the name into an array to access nested properties
      const nameArray = name.split(".");

      // Handle nested properties
      if (nameArray.length === 2) {
        return {
          ...prevData,
          [nameArray[0]]: {
            ...prevData[nameArray[0]],
            [nameArray[1]]: value,
          },
        };
      }

      // Handle non-nested properties
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      productFile: file,
    }));
  };

  // Handle file input change
  const handleShipToFileChange = (e) => {
    const file = e.target.files[0];

    setFormData((prevData) => ({
      ...prevData,
      shipToFile: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create form data
    const data = new FormData();

    // Append form fields
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "shipFrom" || key === "billTo") {
        // Handle shipFrom and billTo as objects
        Object.entries(value).forEach(([subKey, subValue]) => {
          data.append(`${key}[${subKey}]`, subValue);
        });
      } else if (key === "shipTo") {
        // Handle shipTo as an array of objects
        value.forEach((shipToObj, index) => {
          Object.entries(shipToObj).forEach(([subKey, subValue]) => {
            data.append(`${key}[${index}][${subKey}]`, subValue);
          });
        });
      } else {
        data.append(key, value);
      }
    });

    try {
      // Make Axios call
      const response = await axios.post(createTaxInvoiceRoutes, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response.data);
      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
        getSalesOrder();
        toast.success("Order Form successfully filled");

        // Clear form fields using the existing state
        setFormData((prevData) => ({
          productFile: null,
          shipToFile: null,
          shipFrom: { name: "", shipFromIdReq: "" },
          invoiceDate: "",
          billTo: { name: "", address: "", taxId: "" },
          shipTo: [{ name: "", address: "" }],
          userId: prevData.userId,
          PaymentTerms: "",
        }));

        // Retrieve sales order after successful form submission
      } else {
        toast.error("Submission failed.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleNameChange = (e, index) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const newProducts = [...prevData.shipTo];
      newProducts[index] = { ...newProducts[index], name: value };

      const newSelectedProducts = newProducts?.map(
        (product) => product.name || ""
      );

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  const handleAddressChange = (e, index) => {
    const { value } = e.target;

    setFormData((prevData) => {
      const newProducts = [...prevData.shipTo];
      newProducts[index]["address"] = value;

      const newSelectedProducts = newProducts?.map(
        (product) => product.name || ""
      );

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  const handlePlaceOfSupplyShipChange = (e, index) => {
    const { value } = e.target;

    setFormData((prevData) => {
      const newProducts = [...prevData.shipTo];
      newProducts[index]["placeOfSupplyShip"] = value;

      const newSelectedProducts = newProducts?.map(
        (product) => product.name || ""
      );

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  const handleAddProductFields = (index) => {
    const isLastProductEmpty =
      formData.shipTo[index].name === "" &&
      formData.shipTo[index].address === "" &&
      formData.shipTo[index].placeOfSupplyShip === "";

    if (!isLastProductEmpty) {
      setFormData((prevData) => {
        const newProducts = [
          ...prevData.shipTo,
          { name: "", address: "", placeOfSupplyShip: "" },
        ];
        return {
          ...prevData,
          shipTo: newProducts,
        };
      });
    }
  };

  const handleDeleteProductFields = (index) => {
    setFormData((prevData) => {
      if (prevData.shipTo.length === 1) {
        // Ensure there is always at least one product field
        return prevData;
      }

      const newProducts = [...prevData.shipTo];
      newProducts.splice(index, 1);
      return {
        ...prevData,
        shipTo: newProducts,
      };
    });
  };

  // const renderProductInputs = () => {
  //   return formData?.shipTo?.map((product, index) => (
  //     <div className="row mt-10" key={index}>
  //       <div className="col-sm-4">
  //         <div className="form-section">
  //           <label className="mt-10" htmlFor={`shipToName${index}`}>
  //             Ship To Name
  //             <button
  //               onClick={(e) => {
  //                 e.preventDefault();
  //                 handleAddProductFields(index);
  //               }}
  //               style={{
  //                 padding: "5px",
  //                 background: "none",
  //                 border: "none",
  //                 cursor: "pointer",
  //               }}
  //             >
  //               <AddCircleOutline color="primary" />
  //             </button>
  //             <button
  //               onClick={(e) => {
  //                 e.preventDefault();
  //                 handleDeleteProductFields(index);
  //               }}
  //               style={{
  //                 padding: "5px",
  //                 background: "none",
  //                 border: "none",
  //                 cursor: "pointer",
  //               }}
  //             >
  //               <DeleteIcon color="error" />
  //             </button>
  //           </label>

  //           <input
  //             type="text"
  //             id={`shipToName${index}`}
  //             name={`shipTo[${index}].name`} // Updated input name
  //             value={product.name}
  //             onChange={(e) => handleNameChange(e, index)}
  //             style={{
  //               lineHeight: "24px",
  //               borderRadius: "10px",
  //               background: "#F1F3F4",
  //             }}
  //           />
  //         </div>
  //       </div>
  //       <div className="col-sm-4">
  //         <label className="mt-14" htmlFor={`shipToAddress${index}`}>
  //           Ship To Address
  //         </label>
  //         <input
  //           type="text"
  //           id={`shipToAddress${index}`}
  //           name={`shipTo.${index}.address`} // Updated input name
  //           value={product.address}
  //           onChange={(e) => handleAddressChange(e, index)}
  //           style={{
  //             lineHeight: "27px",
  //             borderRadius: "10px",
  //             background: "#F1F3F4",
  //           }}
  //         />
  //       </div>
  //       <div className="col-sm-4">
  //         <label className="mt-14" htmlFor={`placeOfSupplyShip${index}`}>
  //           Ship To Place Of Supply
  //         </label>
  //         <FormControl fullWidth>
  //           <InputLabel id="warehouseLabel">Select Place Of Supply</InputLabel>
  //           <Select
  //             id={`placeOfSupplyShip${index}`}
  //             name={`shipTo.${index}.placeOfSupplyShip`} // Updated input name
  //             value={product.placeOfSupplyShip}
  //             onChange={(e) => handlePlaceOfSupplyShipChange(e, index)}
  //             style={{
  //               lineHeight: "14px",
  //               borderRadius: "10px",
  //               background: "#F1F3F4",
  //             }}
  //           >
  //             <MenuItem value="">
  //               <em>Select Place Of Supply</em>
  //             </MenuItem>
  //             {/* {getStaticDataS?.placeOfSupply
  //               ?.map((item) => item?.formattedCode || [])
  //               .filter((nameItem) =>
  //                 nameItem.some((item) => item.formattedCode)
  //               )
  //               .map((nameItem, index) => (
  //                 <MenuItem key={index} value={nameItem[0]?.id}>
  //                   {nameItem.map((item) => (
  //                     <div key={item.id} value={item[0]?.id}>
  //                       {item.formattedCode}
  //                     </div>
  //                   ))}
  //                 </MenuItem>
  //               ))} */}
  //             {getStaticDataS?.placeOfSupply?.map((option) => (
  //               <MenuItem key={option?.id} value={option?.id}>
  //                 {option?.formattedCode}
  //               </MenuItem>
  //             ))}
  //           </Select>
  //         </FormControl>
  //       </div>
  //     </div>
  //   ));
  // };

  useEffect(() => {
    // Set formData.invoiceDate to today's date when the component mounts
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setFormData((prevFormData) => ({
      ...prevFormData,
      invoiceDate: formattedDate,
    }));
  }, []); // Run this effect only once, when the component mounts

  return (
    <div>
      <div className="file-buttons">
        <div className="form-container">
          <form onSubmit={handleSubmit} className="invoice-form">
            {/* billTo */}
            <div className="tax-invoice-container-bulk-upload">
              {/* <h2 className="text-left font-bold">Tax Invoice</h2> */}
              <h2 className="text-center">Create General Tax Invoice</h2>

              <div className="row mt-10">
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="invoiceDate">
                      Invoice Date
                    </label>
                    <input
                      type="date"
                      id="invoiceDate"
                      name="invoiceDate"
                      value={formData.invoiceDate}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-10">
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipFromName">
                      Ship From Name
                    </label>
                    <input
                      type="text"
                      id="shipFromName"
                      name="shipFrom.name"
                      value={formData.shipFrom.name}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipFromAddress">
                      Ship From Address
                    </label>
                    <FormControl fullWidth>
                      <InputLabel id="warehouseLabel">
                        Select Warehouse
                      </InputLabel>
                      <Select
                        labelId="warehouseLabel"
                        id="name"
                        name="shipFrom.shipFromIdReq"
                        value={formData?.shipFrom?.shipFromIdReq}
                        onChange={handleInputChange}
                        style={{
                          borderRadius: "10px",
                          background: "#F1F3F4",
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Warehouse</em>
                        </MenuItem>
                        {getAllWarehouseContent?.data
                          .filter((item) => item._id) // Filter out items with empty _id
                          .map((warehouse, index) => (
                            <MenuItem key={index} value={warehouse._id}>
                              {warehouse.name.map((item) => (
                                <div key={item._id} value={item._id}>
                                  {item.value}
                                </div>
                              ))}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="row mt-10">
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="billToName">
                      Bill To Name
                    </label>
                    <input
                      type="text"
                      id="billToName"
                      name="billTo.name"
                      value={formData.billTo.name}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="billToAddress">
                      Bill To Address
                    </label>
                    <input
                      type="text"
                      id="billToAddress"
                      name="billTo.address"
                      value={formData.billTo.address}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* shipTo */}
              <div className="row mt-10">
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="billToTaxId">
                      Bill To GST
                    </label>
                    <input
                      type="text"
                      id="billToTaxId"
                      name="billTo.taxId"
                      value={formData.billTo.taxId}
                      onChange={handleInputChange}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="PaymentTerms">
                      Payment Terms
                    </label>
                    <input
                      type="text"
                      id="PaymentTerms"
                      name="PaymentTerms"
                      value={formData.PaymentTerms}
                      onChange={handleInputChange}
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row mt-10">{renderProductInputs()}</div> */}

              {/* shipTo */}
              <div className="row mt-10">
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="productFile">
                      {" "}
                      Product File:
                    </label>
                    <input
                      type="file"
                      name="productFile"
                      onChange={handleFileChange}
                      // Remove the "value" attribute for file input
                      required
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipToFile">
                      {" "}
                      Ship To File:
                    </label>
                    <input
                      type="file"
                      name="shipToFile"
                      onChange={handleShipToFileChange}
                      // Remove the "value" attribute for file input
                      required
                      style={{
                        lineHeight: "24px",
                        borderRadius: "10px",
                        background: "#F1F3F4",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "left",
                  width: "100%",
                }}
              >
                <FileButtons onDownloadClick={handleTemplateDownload} />
                <ShipToButton onDownloadClick={handleShipToTemplateDownload} />
                <div style={{ marginLeft: "20px" }}>
                  {" "}
                  {/* Adjust the margin as needed */}
                  <SubmitButton handleSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-10"></div>
        <Divider />
        {/* <OrderHistory className="mt-10" /> */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default GeneralTaxInvoice;
