// src/SignIn.js

import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./signIn.css";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import { Link } from "react-router-dom";

function SignIn() {
  const { signin, user } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const setCookie = (name, value) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);

    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signin(formData);
      // Clear the form fields
      setFormData({
        email: "",
        password: "",
      });
      // setCookie("token", user.sessionToken);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  return (
    <div className="signIn-container-main">
      <div className="signup-container">
        <h1>Sign In</h1>
        <form onSubmit={handleSubmit} className="signup-container-form">
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button className="signup-button" type="submit">
            Sign In
          </button>
          <div className="additional-links">
            <p>
              Not a user?{" "}
              <Link className="register-link" to="/SignUp">
                Register Now
              </Link>
            </p>
          </div>
          <div className="additional-links">
            <p>
              or go back to the{" "}
              <Link className="home-link" to="/">
                Home Page
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
