import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";
import "./productsAdmin.css";
import LoadingOverlay from "../../../user-profile/order-history/LoadingOverlay.jsx";
import DeleteConfirmationModal from "../../../success-modal/deleteModal.js";
import PaginationComponent from "../../../pagination/PaginationComponent.jsx";
import Spinner from "react-bootstrap/esm/Spinner.js";
import EditProductsAdmin from "./editProductsAdmin.component.jsx";
import { format } from "date-fns";
import DateDisplay from "./DateDisplay.jsx";

function DeletedProductsAdmin() {
  const { getDeletedProductsAdmin } = useUser();
  const { refreshFilesGetProductsAdmin } = useUser();
  const { DeleteProductsWithAdminRole } = useUser();

  const pageSize = 20; // Number of rows per page
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };
  // const handleDeleteClick = async (file) => {
  //   await handleSoftDelete();
  // };

  const handleDeleteClick = (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (file) => {
    if (file && file._id) {
      const uid = file._id;
      try {
        await DeleteProductsWithAdminRole(uid);
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Invalid or missing _id in file object:", file);
    }
  };

  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refreshFilesGetProductsAdmin();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // handleRefresh();
  }, []);

  if (isLoading) {
    return (
      <div>
        <h1>Products</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (
    !getDeletedProductsAdmin?.data ||
    !getDeletedProductsAdmin?.data?.length === 0
  ) {
    return (
      <div>
        <h1>Products</h1>
        <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
        <p>No Products found.</p>
      </div>
    );
  }

  // For Form Order
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(
    startIndexForm + pageSize,
    getDeletedProductsAdmin?.data?.length
  );
  const formUploadOrder = getDeletedProductsAdmin?.data?.slice(
    startIndexForm,
    endIndexForm
  );
  const totalPagesForm = Math.ceil(
    getDeletedProductsAdmin?.data?.length / pageSize
  );

  console.log(
    "🚀 ~ file: deletedProductsAdmin.jsx:113 ~ DeletedProductsAdmin ~ getDeletedProductsAdmin:",
    getDeletedProductsAdmin
  );
  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  // ANCHOR - FIELDS
  const fields = formUploadOrder.map((file) => {
    return [
      { name: "Brand", value: file?.brand },
      { name: "Category", value: file?.category },
      { name: "Price", value: `${file?.price}` },
      // Add more fields as needed
    ];
  });

  return (
    <div className="order-history-body">
      <div className="form-upload-order-container">
        {" "}
        <h1>InActive Products</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>image</th>
              <th>brand</th>
              <th>category</th>
              <th>name</th>
              <th>description</th>
              <th>rating</th>
              <th>numReviews</th>
              <th>price</th>
              <th>countInStock</th>
              <th>createdAt</th>
              <th>updatedAt</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formUploadOrder.map((file) => (
              <tr key={file._id}>
                <td>
                  <img
                    src={file?.image}
                    alt={file?.image}
                    onError={(e) => {
                      e.target.src = "/404.png"; // Assuming 404.png is in the public directory
                    }}
                    style={{
                      maxHeight: "150px",
                      maxWidth: "150px",
                      minHeight: "150px",
                      minWidth: "150px",
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </td>{" "}
                <td>{file?.brand}</td>
                <td>{file?.category}</td>
                <td>{file?.name}</td>
                <td>{file?.description}</td>
                <td>{file?.rating}</td>
                <td>{file?.numReviews}</td>
                <td>{file?.price}</td>
                <td>{file?.countInStock}</td>
                <td>
                  {" "}
                  <DateDisplay dateString={file?.createdAt} />{" "}
                  {/* Use the DateDisplay component */}
                </td>
                <td>
                  {" "}
                  <DateDisplay dateString={file?.updatedAt} />{" "}
                  {/* Use the DateDisplay component */}
                </td>
                <td className="">{!file?.deleted ? "Active" : "InActive"}</td>
                <td>
                  <button
                    onClick={() => handleEditClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon color="primary" />
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => handleDeleteClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon color="error" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {isLoading && <LoadingOverlay />}
      </div>
      {showEditModal && (
        <EditProductsAdmin
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
          fields={fields}
        />
      )}
    </div>
  );
}

export default DeletedProductsAdmin;
