import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./LoadingOverlay.css";

function LoadingOverlay() {
  return (
    <div className="loading-overlay">
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default LoadingOverlay;
