import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../../../../contexts/adminPanel.context.jsx";
import SubmitButton from "../../../../bulk-order/submit.btn.component.jsx";
import { useHomePage } from "../../../../../contexts/homePage.context.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "250px",
  },
};

Modal.setAppElement("#root");

function EditBrands({ isOpen, onRequestClose, editUser }) {
  const { UpdateBrandsContext } = useHomePage();
  const uniqueId = editUser?._id;

  const [formData, setFormData] = useState({
    name: editUser?.name || "",
    logo: null,
    deleted: editUser?.deleted || false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    try {
      const formDataHandleSave = {
        ...formData,
      };

      await UpdateBrandsContext(uniqueId, formDataHandleSave);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle logo file input change
  const handleLogoInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Read the file data and convert it to a base64-encoded string
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setFormData({
          ...formData,
          logo: base64Data, // Store the base64-encoded string
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit Home Banner Text"
    >
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-section my-3">
              <label htmlFor="text">Brand Name</label>
              <input
                type="text"
                name="name"
                placeholder="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            {/* File input for logo */}
            <div className="form-section my-3">
              <label htmlFor="logo">Brand Logo</label>
              <input
                type="file"
                name="logo"
                accept="image/*"
                onChange={handleLogoInputChange}
              />
            </div>
            <div className="col-sm-12">
              <div className="form-section my-3">
                <label htmlFor="deleted">
                  {!formData.deleted
                    ? "Brand Currently Active"
                    : "Brand Currently InActive"}
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="deleted"
                  checked={!formData.deleted}
                  onChange={handleInputChange}
                  id="deleted"
                  style={{ width: "50px", height: "30px", display: "flex" }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default EditBrands;
