import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { Box } from "@mui/system";
import ReactFlows from "reactflow";

import ProfileSetting from "../user-profile/profile/profile.jsx";
import UserAdminComponent from "./components/users/userAdmin.component.jsx";
import UserInvoiceAdmin from "./components/invoices/userInvoicesAdmin.jsx";
import ProductsAdmin from "./components/products/productsAdmin.component.jsx";
import HomePageSetting from "./components/homePageSetting/banner/homePageBanner.jsx";
import HomePageSettingIndex from "./components/homePageSetting/homePageSettingIndex.jsx";
import GetWarehouse from "../warehouse-page/warehouse-crud/getWarehouse.jsx";
import CreateInwardWareHousePage from "../warehouse-page/inward-crud/createInwardWarehousPage.jsx";
import InwardWareHouse from "../warehouse-page/inward-crud/inwardWarehouse.jsx";
import PurchaseOrder from "./components/purchaseOrder/purchaseOrder.jsx";
import PurchaseOrderHistory from "./components/purchaseOrder/purchaseOrderHistory.jsx";
import MfiIndex from "./components/mfi/mfiIndex.jsx";
import WarehouseIndexWorkFlow from "../warehouse-workflow/warehouseIndexWorkFlow.jsx";

function AdminIndex() {
  const [value, setValue] = useState(0); // Initialize the selected tab state

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    <UserAdminComponent />,
    <MfiIndex />,
    <UserInvoiceAdmin />,
    <ProductsAdmin />,
    <HomePageSettingIndex />,
    <GetWarehouse />,
    <CreateInwardWareHousePage />,
    <InwardWareHouse />,
    <PurchaseOrder />,
    <PurchaseOrderHistory />,
    <WarehouseIndexWorkFlow />,
    <WarehouseIndexWorkFlow />,
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: 140,
          padding: 2,
          borderRight: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="Users" />
          <Tab label="Mfi Registration" />
          <Tab label="Orders" />
          <Tab label="Products" />
          <Tab label="Home" />
          <Tab label="WareHouse" />
          <Tab label="Create Inward" />
          <Tab label="Inward" />
          <Tab label="Generate Po" />
          <Tab label="Purchase Order History" />
          <Tab label="Warehouse Workflow" />
          <Tab label="Invoice generation" />
        </Tabs>
      </Box>
      <Box sx={{ flex: 1, padding: 3 }}>{tabs[value]}</Box>
    </Box>
  );
}

export default AdminIndex;
