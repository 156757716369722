import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import { useDeliveryChallanMongo } from "../../../contexts/deliveryChallanContext.jsx";

// Define your modal styles
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "80vh", // Maximum height
    maxWidth: "90vw", // Maximum width
    overflowY: "auto", // Vertical scroll
  },
};

Modal.setAppElement("#root"); // Set your root element

function EditDeliveryChallan({ isOpen, onRequestClose, file }) {
  const [uniqueId, setUId] = useState(null);

  useEffect(() => {
    setUId(file?._id);
  }, [file]);

  console.log("🚀 ~ EditDeliveryChallan ~ file:", file);
  // const taxInvoiceContext = useAuth(); // Get the context object
  const { UpdateDeliveryChallan } = useDeliveryChallanMongo();
  //   const { getSalesOrder } = useDeliveryChallanMongo();
  const { user } = useAuth();
  const userId = user?.userId;
  const uid = file?._id;
  console.log(
    "🚀 ~ file: editOrderForm.jsx:27 ~ EditDeliveryChallan ~ uid:",
    uid
  );

  // Initialize formData with shipTo data
  const initialShipToData = file.shipTo.map((shipToItem) => ({
    customerName: shipToItem.customerName || "",
    customerNumberShipTo: shipToItem.customerNumberShipTo || "",
    bocoName: shipToItem.bocoName || "",
    bocoContactNumber: shipToItem.bocoContactNumber || "",
    address: shipToItem.address || "",
    locationGst: shipToItem.locationGst || "",
    placeOfSupplyShipReq: shipToItem.placeOfSupplyShipReq || "",
    invoiceNumber: shipToItem.invoiceNumber || "",
  }));

  const [formData, setFormData] = useState({
    invoiceDate: file.invoiceDate,
    mainInvoiceNumber: file.mainInvoiceNumber,
    // shipFromName: file.shipFrom.name,
    // shipFromAddress: file.shipFrom.address,
    // shippingAddress: file.shipFrom.address,
    // billTo
    // billToName: file.billTo.name,
    // billToAddress: file.billTo.address,
    // consigneeName: file.consigneeName,
    // contactNo: file.contactNo,
    shipFrom: {
      name: file.shipFrom.name,
      address: file.shipFrom.address,
      shipFromId: file.shipFrom.shipFromId || "", // Assuming shipFromId might be optional
      shipFromPlaceReq: file.shipFrom.shipFromPlaceReq || "", // Assuming shipFromPlaceReq might be optional
    },
    billTo: {
      name: file.billTo.name,
      address: file.billTo.address,
      taxId: file.billTo.taxId || "", // Assuming taxId might be optional
    },
    // itemName: file.itemName,
    // quantity: file.quantity,
    // orderTypeId: file.orderTypeId,
    isAllowed: false,
    userId: userId,
    mainInvoiceNumber: file.mainInvoiceNumber,
    invoiceId: file.id,
    shipTo: initialShipToData,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Check if the input field is nested within an object
    if (name.includes(".")) {
      const [parent, child] = name.split("."); // Split the nested property
      setFormData((prevFormData) => ({
        ...prevFormData,
        [parent]: {
          ...prevFormData[parent],
          [child]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      // If not nested, update directly
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleShipToChange = (index, e) => {
    const { name, value } = e.target;
    const updatedShipTo = [...formData.shipTo];
    updatedShipTo[index] = {
      ...updatedShipTo[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      shipTo: updatedShipTo,
    });
  };

  const handleSubmit = async () => {
    console.log("🚀 ~ handleSubmit ~ formData:", formData);
    try {
      const invoiceId = formData.invoiceId;
      // Make Axios call (replace 'uId' with the actual user ID)
      const response = await UpdateDeliveryChallan(formData, invoiceId);
      onRequestClose(); // Assuming this function closes the modal or form after successful update
      // getSalesOrder();
    } catch (error) {
      console.error("Error:", error);
    }
    console.log("🚀 ~ handleSubmit ~ formData:", formData);
  };

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit File Modal"
    >
      <h2 className="" style={{ textAlign: "center" }}>
        Edit Form: {file.mainInvoiceNumber}
      </h2>
      <SubmitButton handleSubmit={handleSubmit} />

      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div
          className="row"
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            transform: "translateZ(0)",
            padding: "10px",
            border: "1px solid #e0e0e0",
          }}
        >
          <h3 className="card-title">Ship From</h3>
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Name</label>
              <input
                type="text"
                name="shipFrom.name" // Update the name attribute to match the nested structure
                placeholder="Name"
                value={formData.shipFrom.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Address</label>
              <input
                type="text"
                name="shipFrom.address"
                placeholder="Customer Invoice No"
                value={formData.shipFrom.address}
                onChange={handleInputChange}
              />
            </div>
          </div>{" "}
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="companyAddress">invoiceDate</label>
              <input
                type="date"
                name="invoiceDate"
                placeholder="Customer Invoice No"
                value={formData.invoiceDate}
                onChange={handleInputChange}
                style={{
                  height: "38px",
                  width: "100%",
                  boxSizing: "border-box",
                  padding: "8px",
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            transform: "translateZ(0)",
            padding: "10px",
            border: "1px solid #e0e0e0",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          <h3 className="card-title">Bill To</h3>
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Name</label>
              <input
                type="text"
                name="billTo.name"
                placeholder="Bill To Name"
                value={formData.billTo.name}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Address</label>
              <input
                type="text"
                name="billTo.address"
                placeholder="Bill To Address"
                value={formData.billTo.address}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        {/* ShipTo Details */}
        {file.shipTo.map((shipToItem, index) => (
          <div
            className="card mb-4"
            key={index}
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              transform: "translateZ(0)",
              padding: "10px",
              border: "1px solid #e0e0e0",
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            <div className="card-body">
              <h3 className="card-title bold">
                Ship To {index + 1}: {shipToItem.invoiceNumber}
              </h3>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-section my-3">
                    <label>Customer Name</label>
                    <input type="text" value={shipToItem.customerName} />
                  </div>
                  <div className="form-section my-3">
                    <label>Customer Contact Number</label>
                    <input
                      type="text"
                      value={shipToItem.customerNumberShipTo}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-section my-3">
                    <label>Address</label>
                    <input value={shipToItem.address} rows="4" />
                  </div>
                  <div className="form-section my-3">
                    <label>Location GST</label>
                    <input
                      disabled
                      type="text"
                      value={shipToItem.locationGst}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* Products */}
        {file.shipTo.map((shipToItem, index) => (
          <div
            className="card mb-4"
            key={index}
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              transform: "translateZ(0)",
            }}
          >
            <div className="card-body">
              <h4 className="card-title bold">
                Products for Ship To {index + 1} : {shipToItem.invoiceNumber}
              </h4>
              {shipToItem.products.map((product, prodIndex) => (
                <div className="card mb-3" key={prodIndex}>
                  <div className="card-body">
                    <h5 className="card-title">
                      Product {prodIndex + 1}: {product.productName}
                    </h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-section my-3">
                          <label>Product Name</label>
                          <input type="text" value={product.productName} />
                        </div>
                        <div className="form-section my-3">
                          <label>Description</label>
                          <input value={product.description} />
                        </div>
                        <div className="form-section my-3">
                          <label>HSN</label>
                          <input type="text" value={product.hsn} />
                        </div>
                        <div className="form-section my-3">
                          <label>Product Code</label>
                          <input type="text" value={product.productCode} />
                        </div>
                        <div className="form-section my-3">
                          <label>Quantity</label>
                          <input disabled type="text" value={product.qty} />
                        </div>
                        <div className="form-section my-3">
                          <label>Rate</label>
                          <input disabled type="text" value={product.rate} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-section my-3">
                          <label>Amount</label>
                          <input disabled type="text" value={product.amount} />
                        </div>
                        <div className="form-section my-3">
                          <label>GST Amount</label>
                          <input
                            disabled
                            type="text"
                            value={product.gstAmount}
                          />
                        </div>
                        <div className="form-section my-3">
                          <label>Total Amount with GST</label>
                          <input
                            disabled
                            type="text"
                            value={product.totalAmountWithGst}
                          />
                        </div>
                        <div className="form-section my-3">
                          <label>Total Amount with GST (In Words)</label>
                          <input
                            disabled
                            value={product.totalAmountWithGstInWords}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </Modal>
  );
}

export default EditDeliveryChallan;
