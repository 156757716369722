import React, { useState } from "react";
import "./invoices.css";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import { toast } from "react-toastify";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";

const InvoiceForm = () => {
  const { createInvoiceContext, user, getClientData } = useAuth();
  const userId = user?.userId;
  const id = getClientData?.[0]?._id;

  const [formData, setFormData] = useState({
    userId: userId,
    billTo: {
      name: "",
      address: "",
      city: "",
      state: "",
      pinCode: "",
      gstinOrPan: "",
    },
    shipTo: {
      name: "",
      address: "",
      city: "",
      state: "",
      pinCode: "",
    },
    paymentInfo: {
      bankName: "",
      accountName: "",
      accountNumber: "",
      ifscCode: "",
    },
    paymentInstructions: "",
    termsAndConditions: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if the name includes a dot (indicating a nested field)
    if (name.includes(".")) {
      const [section, field] = name.split(".");
      // Update the nested field
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: value,
        },
      }));
    } else {
      // If it's not a nested field, update it directly
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);

      await createInvoiceContext(formData);
      // Clear the form fields
      setFormData((prevData) => ({
        ...prevData,
        billTo: {
          name: "",
          address: "",
          city: "",
          state: "",
          pinCode: "",
          gstinOrPan: "",
        },
        shipTo: {
          address: "",
          city: "",
          state: "",
          pinCode: "",
        },
        paymentInfo: {
          bankName: "",
          accountName: "",
          accountNumber: "",
          ifscCode: "",
        },
        paymentInstructions: "",
        termsAndConditions: "",
      }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="invoice-form">
        {/* billTo */}
        <div className="invoice-container">
          <h2>Billing details</h2>
          <div class="row">
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyName">Client Name</label>
                <input
                  type="text"
                  name="billTo.name"
                  value={formData.billTo.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">Address</label>
                <input
                  type="text"
                  id="billToClientAddress"
                  name="billTo.address"
                  value={formData.billTo.address}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">City</label>
                <input
                  type="text"
                  id="ClientCity"
                  name="billTo.city"
                  value={formData.billTo.city}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyName">State</label>
                <input
                  type="text"
                  id="state"
                  name="billTo.state"
                  value={formData.billTo.state}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">PinCode</label>
                <input
                  type="text"
                  id="pinCode"
                  name="billTo.pinCode"
                  value={formData.billTo.pinCode}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">Gst</label>
                <input
                  type="text"
                  id="gstinOrPan"
                  name="billTo.gstinOrPan"
                  value={formData.billTo.gstinOrPan}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        {/* shipTo */}
        <div className="invoice-container">
          <h2>Shipping details</h2>
          <div class="row">
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyName">Address</label>
                <input
                  type="text"
                  id="shipToShippingAddress"
                  name="shipTo.address"
                  value={formData.shipTo.address}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">City</label>
                <input
                  type="text"
                  id="shipToShippingCity"
                  name="shipTo.city"
                  value={formData.shipTo.city}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">State</label>
                <input
                  type="text"
                  id="shipToShippingState"
                  name="shipTo.state"
                  value={formData.shipTo.state}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">PinCode</label>
                <input
                  type="text"
                  id="shipToPinCode"
                  name="shipTo.pinCode"
                  value={formData.shipTo.pinCode}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Payment Information */}
        <div className="invoice-container">
          <h2>Payment Information</h2>
          <div class="row">
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyName">Bank Name</label>
                <input
                  type="text"
                  id="paymentInfoBankName"
                  name="paymentInfo.bankName"
                  value={formData.paymentInfo.bankName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">Account Name</label>
                <input
                  type="text"
                  id="paymentInfoAccountName"
                  name="paymentInfo.accountName"
                  value={formData.paymentInfo.accountName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">Account Number</label>
                <input
                  type="text"
                  id="paymentInfoAccountNumber"
                  name="paymentInfo.accountNumber"
                  value={formData.paymentInfo.accountNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              {" "}
              <div className="form-section">
                <label htmlFor="companyName">ifsc Code</label>
                <input
                  type="text"
                  id="paymentInfoIfscCode"
                  name="paymentInfo.ifscCode"
                  value={formData.paymentInfo.ifscCode}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Payment Instructions */}
        <div className="invoice-container">
          <h2>Payment Instructions</h2>
          <div class="row">
            <div class="col-sm-12">
              {" "}
              <div className="form-section">
                <label htmlFor="companyName">payment Instructions</label>
                <input
                  type="text"
                  id="paymentInstructions"
                  name="paymentInstructions" // No need for dots here
                  value={formData?.paymentInstructions}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="col-sm-12">
              {" "}
              <div className="form-section">
                <label htmlFor="companyAddress">terms And Conditions</label>
                <input
                  type="text"
                  id="termsAndConditions"
                  name="termsAndConditions" // No need for dots here
                  value={formData?.termsAndConditions}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

export default InvoiceForm;
