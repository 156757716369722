import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../../contexts/adminPanel.context.jsx";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    margin: "10px",
  },
};

Modal.setAppElement("#root");

function CreateWareHouse({ isOpen, onRequestClose }) {
  const { CreateWarehouseContext } = WarehouseContext();
  const { user } = useAuth();
  const userId = user?.userId; // user id

  const [formData, setFormData] = useState({
    name: [{ value: "" }], // Leave it as an array with an object
    location: "",
    userId: userId,
    address: "",
    city: "",
    country: "",
    state: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      if (name === "brand" || name === "category") {
        setFormData({
          ...formData,
          [name]: value,
          productDetails: {
            ...formData.productDetails,
            [name]: value,
          },
        });
      } else if (name === "name[0].value") {
        // Handle the name field as an array
        setFormData({
          ...formData,
          name: [{ value }],
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const handleSave = async () => {
    try {
      const formDataCreate = {
        ...formData,
      };

      await CreateWarehouseContext(formDataCreate);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Create New Warehouse</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name[0].value" // Access the first (and only) element in the array
                placeholder="Name"
                value={formData.name[0].value}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="location">location</label>
              <input
                type="text"
                name="location"
                placeholder="location"
                value={formData.location}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="address">address</label>
              <input
                type="text"
                name="address"
                placeholder="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="name">city</label>
              <input
                type="text"
                name="city"
                placeholder="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="country">country</label>
              <input
                type="text"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="rating">state</label>
              <input
                type="text"
                name="state"
                placeholder="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default CreateWareHouse;
