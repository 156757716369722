import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButton from "../../../../bulk-order/submit.btn.component.jsx";
import StarIcon from "@mui/icons-material/Star";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../contexts/UserProviderMongo.jsx";

const customStyles = {
  // ... (unchanged)
};

Modal.setAppElement("#root");

function MfiEdit({ isOpen, onRequestClose, file }) {
  const uniqueId = file?._id;
  const { updateMfiContext } = useAuth();

  const [formData, setFormData] = useState({
    businessName: file?.businessName,
    businessAddress: file?.businessAddress,
    businessCity: file?.businessCity,
    businessState: file?.businessState,
    businessPinCode: file?.businessPinCode,
    contactPerson: {
      name: file?.contactPerson?.name,
      email: file?.contactPerson?.email,
      phone: file?.contactPerson?.phone,
    },
    documents: {
      panCard: file?.documents?.panCard,
      gstin: file?.documents?.gstin,
      registrationCertificate: file?.documents?.registrationCertificate,
    },
  });

  useEffect(() => {
    setFormData({
      businessName: file?.businessName,
      businessAddress: file?.businessAddress,
      businessCity: file?.businessCity,
      businessState: file?.businessState,
      businessPinCode: file?.businessPinCode,
      contactPerson: {
        name: file?.contactPerson?.name,
        email: file?.contactPerson?.email,
        phone: file?.contactPerson?.phone,
      },
      documents: {
        panCard: file?.documents?.panCard,
        gstin: file?.documents?.gstin,
        registrationCertificate: file?.documents?.registrationCertificate,
      },
    });
  }, [file]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNestedChange = (parent, key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [parent]: {
        ...prevFormData[parent],
        [key]: value,
      },
    }));
  };

  const handleSave = async () => {
    try {
      // Create form data
      const formDataWithUpdatedIsAdmin = {
        // userId: userId, // Uncomment this line if userId is needed
        businessName: formData.businessName,
        businessAddress: formData.businessAddress,
        businessCity: formData.businessCity,
        businessState: formData.businessState,
        businessPinCode: formData.businessPinCode,
        contactPerson: {
          name: formData.contactPerson.name,
          email: formData.contactPerson.email,
          phone: formData.contactPerson.phone,
        },
        documents: {
          panCard: formData.documents.panCard,
          gstin: formData.documents.gstin,
          registrationCertificate: formData.documents.registrationCertificate,
        },
      };

      const response = await updateMfiContext(
        formDataWithUpdatedIsAdmin,
        uniqueId
      );
      onRequestClose();

      if (response.status === 200 || response.status === 201) {
        toast.success("Product updated successfully");
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit File Modal"
    >
      <h2 className="text-center">Edit MFI Details</h2>

      {/* Business Details */}
      <div className="mfi-section">
        <label htmlFor="businessName">Business Name</label>
        <input
          type="text"
          id="businessName"
          name="businessName"
          value={formData.businessName}
          onChange={handleChange}
        />

        <label htmlFor="businessAddress">Business Address</label>
        <input
          type="text"
          id="businessAddress"
          name="businessAddress"
          value={formData.businessAddress}
          onChange={handleChange}
        />

        <label htmlFor="businessCity">Business City</label>
        <input
          type="text"
          id="businessCity"
          name="businessCity"
          value={formData.businessCity}
          onChange={handleChange}
        />

        <label htmlFor="businessState">Business State</label>
        <input
          type="text"
          id="businessState"
          name="businessState"
          value={formData.businessState}
          onChange={handleChange}
        />

        <label htmlFor="businessPinCode">Business PinCode</label>
        <input
          type="text"
          id="businessPinCode"
          name="businessPinCode"
          value={formData.businessPinCode}
          onChange={handleChange}
        />
      </div>

      {/* Contact Person Details */}
      <div className="mfi-section">
        <label htmlFor="contactPersonName">Contact Person Name</label>
        <input
          type="text"
          id="contactPersonName"
          name="contactPerson.name"
          value={formData.contactPerson.name}
          onChange={(e) =>
            handleNestedChange("contactPerson", "name", e.target.value)
          }
        />

        <label htmlFor="contactPersonEmail">Contact Person Email</label>
        <input
          type="text"
          id="contactPersonEmail"
          name="contactPerson.email"
          value={formData.contactPerson.email}
          onChange={(e) =>
            handleNestedChange("contactPerson", "email", e.target.value)
          }
        />

        <label htmlFor="contactPersonPhone">Contact Person Phone</label>
        <input
          type="text"
          id="contactPersonPhone"
          name="contactPerson.phone"
          value={formData.contactPerson.phone}
          onChange={(e) =>
            handleNestedChange("contactPerson", "phone", e.target.value)
          }
        />
      </div>

      {/* Document Details */}
      <div className="mfi-section">
        <label htmlFor="panCard">PAN Card</label>
        <input
          type="text"
          id="panCard"
          name="documents.panCard"
          value={formData.documents.panCard}
          onChange={(e) =>
            handleNestedChange("documents", "panCard", e.target.value)
          }
        />

        <label htmlFor="gstin">GSTIN</label>
        <input
          type="text"
          id="gstin"
          name="documents.gstin"
          value={formData.documents.gstin}
          onChange={(e) =>
            handleNestedChange("documents", "gstin", e.target.value)
          }
        />

        <label htmlFor="registrationCertificate">
          Registration Certificate
        </label>
        <input
          type="text"
          id="registrationCertificate"
          name="documents.registrationCertificate"
          value={formData.documents.registrationCertificate}
          onChange={(e) =>
            handleNestedChange(
              "documents",
              "registrationCertificate",
              e.target.value
            )
          }
        />
      </div>

      <SubmitButton handleSubmit={handleSave} />
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>
    </Modal>
  );
}

export default MfiEdit;
