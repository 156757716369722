import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import Pagination from "react-bootstrap/Pagination";
import "./orderHistory.css";
import Spinner from "react-bootstrap/Spinner";
import LoadingOverlay from "./LoadingOverlay"; // Import the LoadingOverlay component
import PaginationComponent from "../../pagination/PaginationComponent.jsx";
import EditOrderForm from "../../order-form/components/editOrderForm.jsx";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddIcon from "@mui/icons-material/AddCircleOutline"; // Plus icon
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmationModal from "../../success-modal/deleteModal.js";
import TaxInvoiceModal from "../../bulk-order/bulk-upload-modal.component.jsx";

function OrderHistory() {
  const files = useAuth([]);
  console.log("🚀 ~ file: order-history.jsx:17 ~ OrderHistory ~ files:", files);
  const { generateInvoice } = useAuth([]);
  const { getSaleOrderForm } = useAuth([]);
  console.log(
    "🚀 ~ file: order-history.jsx:20 ~ OrderHistory ~ getSaleOrderForm:",
    getSaleOrderForm
  );
  const { handleSoftDelete } = useAuth([]);

  const { refreshFiles, orderDownload, refreshFilesFormOrder } = useAuth([]); // Get data and function from context

  const pageSize = 5; // Number of rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [excelOrderForms, setExcelOrderForms] = useState([]); //for sales order form
  const [orderForms, setOrderForms] = useState([]); //for sales order form
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createProducts, setCreateProducts] = useState(null);

  console.log(
    "🚀 ~ file: order-history.jsx:33 ~ OrderHistory ~ orderForms:",
    orderForms
  );

  //ANCHOR  <!---- For Sale Order form ----!>
  useEffect(() => {
    const getSaleOrderAllFormMap = getSaleOrderForm?.data;

    if (getSaleOrderAllFormMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setOrderForms(getSaleOrderAllFormMap);
    }
  }, [getSaleOrderForm?.data]);

  //ANCHOR <!---- For Excel Order ----!>
  useEffect(() => {
    const ExcelOrderFormsMap = files?.files;
    console.log("🚀 ~ file: order-history.jsx:57 ~ useEffect ~ files:", files);

    if (ExcelOrderFormsMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setExcelOrderForms(ExcelOrderFormsMap);
    }
  }, [files]);

  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  // const handleDeleteClick = async (file) => {
  //   await handleSoftDelete();
  // };

  const handleDeleteClick = (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (file) => {
    // e.preventDefault();
    const uid = file;

    try {
      // console.log(formData);
      await handleSoftDelete(uid);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refreshFiles();
      // await refreshFilesFormOrder();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   handleRefresh();
  // });

  if (isLoading) {
    return (
      <div>
        <h1>User Files</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  //ANCHOR  <!---- Form Order to check if files ?? [] ----!>
  if (!orderForms || orderForms.length === 0) {
    return (
      <div>
        <h1>No Form Order Sales founds</h1>
        <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
      </div>
    );
  }

  //ANCHOR  <!---- Excel Order to check if files ?? [] ----!>
  // if (!excelOrderForms || !files?.files || files.files?.length === 0) {
  console.log(
    "🚀 ~ file: order-history.jsx:142 ~ OrderHistory ~ files:",
    files
  );
  console.log(
    "🚀 ~ file: order-history.jsx:142 ~ OrderHistory ~ excelOrderForms:",
    excelOrderForms
  );
  //   return (
  //     <div>
  //       <h1>No Form Order Sales </h1>
  //       <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
  //     </div>
  //   );
  // }

  //ANCHOR  <!---- For Excel Upload Order ----!>
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, excelOrderForms.length);
  // const excelUploadOrder = excelOrderForms.slice(startIndex, endIndex);
  const excelUploadOrder = Array.isArray(excelOrderForms)
    ? excelOrderForms.slice(startIndex, endIndex)
    : [];
  const totalPages = Math.ceil(excelOrderForms.length / pageSize);

  //ANCHOR  <!---- For Form Order ----!>
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, orderForms?.length);
  const formUploadOrder = Array.isArray(orderForms)
    ? orderForms?.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(orderForms?.length / pageSize);
  console.log(formUploadOrder, "formUploadOrder");
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentPageForm(page);
  };
  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  // Sort the formUploadOrder array by the invoiceDate in descending order
  const sortedFormUploadOrder = formUploadOrder.slice().sort((a, b) => {
    const dateA = new Date(a.invoiceDate);
    const dateB = new Date(b.invoiceDate);
    return dateB - dateA;
  });
  console.log(
    "🚀 ~ sortedFormUploadOrder ~ sortedFormUploadOrder:",
    sortedFormUploadOrder
  );

  const handleDownload = async (file) => {
    const fileId = file._id;
    console.log(
      "🚀 ~ file: order-history.jsx:174 ~ handleDownload ~ fileId:",
      fileId
    );
    // Handle download logic here, using file._id or other data
    await orderDownload(fileId); // Example usage, replace with your actual download logic
  };

  const handleGenerateInvoice = (file) => {
    const fileId = file._id;
    const invoiceNumber = file.invoiceNumber;

    console.log(
      "🚀 ~ file: order-history.jsx:180 ~ handleGenerateInvoice ~ fileId:",
      fileId
    );

    // Handle generate invoice logic here, using file._id or other data
    generateInvoice(fileId, invoiceNumber); // Example usage, replace with your actual generate invoice logic
  };

  const handleCreateClick = () => {
    setCreateProducts();
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  return (
    <div className="order-history-body">
      {showCreateModal && (
        <TaxInvoiceModal
          isOpen={showCreateModal}
          onRequestClose={handleCreateModalClose}
          file={createProducts}
        />
      )}
      <div className="form-upload-order-container">
        <h1>Invoices Generated</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <button
          onClick={() => handleCreateClick()}
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginLeft: "10px",
          }}
        >
          <AddIcon color="primary" />
        </button>
        <div className="table-responsive">
          <Table striped bordered hover className="custom-table">
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Invoice Date</th>
                <th>Ship From</th>
                <th>Ship To</th>
                <th>Payment Terms</th>
                <th>Edit/Delete</th>
                <th>Edit/Delete</th>
              </tr>
            </thead>
            <tbody>
              {sortedFormUploadOrder.map((file) => (
                <tr key={file._id}>
                  <td>{file.mainInvoiceNumber || "-"}</td>
                  <td>{file.invoiceDate || "-"}</td>
                  <td>{file.shipFrom.name || "-"}</td>
                  <td>
                    {file?.shipTo?.map((item, index) => (
                      <div key={index}>
                        {item.bmName || "-"}: {item.address || "-"}
                      </div>
                    ))}
                  </td>
                  <td>{file.PaymentTerms || "-"}</td>
                  <td>
                    <div className="d-flex">
                      <button
                        disabled
                        onClick={() => handleEditClick(file)}
                        className="mr-2"
                      >
                        <EditIcon color="disabled" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <button
                        disabled
                        onClick={() => handleDeleteClick(file._id)}
                      >
                        <DeleteIcon color="disabled" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {isLoading && <LoadingOverlay />}
      </div>
      {showEditModal && (
        <EditOrderForm
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
        />
      )}
    </div>
  );
}

export default OrderHistory;
