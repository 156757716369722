import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import ImageIcon from "@mui/icons-material/Image";
import "bootstrap/dist/css/bootstrap.min.css";
import "./category.styles.scss";
import { useState } from "react";

const Product = ({ product }) => {
  console.log("🚀 ~ Product ~ product:", product);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true); // Set imageError state to true when image fails to load
  };

  return (
    <Card className="my-3 mx-3 p-3 rounded custom-card ">
      <Link to={`/product/${product._id}`}>
        {product.image && product.image.length > 0 ? (
          <Carousel>
            {product.image.map((image, index) => (
              <Carousel.Item key={index}>
                {imageError ? (
                  <ImageIcon className="" />
                ) : (
                  <Card.Img
                    src={image?.imgUrl}
                    alt={`Product ${index + 1}`}
                    onError={handleImageError}
                    variant="top"
                    className="custom-card-image"
                  />
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          // Display a default image when the product.image array is empty
          // <Card.Img
          //   src="/404.png"
          //   alt="Default Image"
          //   onError={handleImageError}
          //   variant="top"
          //   className="custom-card-image"
          // />
          <ImageIcon className="custom-card-image" />
        )}
      </Link>

      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as="div" className="product-title">
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as="div">
          <div className="product-rating">
            {/* You can add your rating component here */}
          </div>
        </Card.Text>

        <Card.Text as="h3" className="product-price">
          INR {product.price}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
