import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../../contexts/adminPanel.context.jsx";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    margin: "10px",
  },
};

Modal.setAppElement("#root");

function CreateInwardWareHouse({ isOpen, onRequestClose }) {
  const { CreateWarehouseContext } = WarehouseContext();
  const { createWarehouseInwardContext } = WarehouseContext();
  const { getWarehouseContent } = WarehouseContext([]);
  const { getStaticDataS } = useAuth([]);
  console.log(
    "🚀 ~ file: createInwardWarehouse.jsx:29 ~ CreateInwardWareHouse ~ getStaticDataS:",
    getStaticDataS
  );
  console.log(
    "🚀 ~ file: createInwardWarehouse.jsx:28 ~ CreateInwardWareHouse ~ getStaticDataS:",
    getStaticDataS
  );

  const { getWarehouseInwardContent } = WarehouseContext([]); // Use useContext to access the context

  const { user } = useAuth();
  const userId = user?.userId; // user id
  const formUploadOrder = getWarehouseInwardContent?.data;

  const [formData, setFormData] = useState({
    inwardBrand: "",
    outwardVendor: "",
    deliveryStatus: "",
    deliveryDate: "",
    proofOfDelivery: "",
    userId: userId,
    warehouse: { name: { nameId: "" } },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Split the name attribute into parts
    const nameParts = name.split(".");

    // Handle changes for nested fields
    if (nameParts.length === 3) {
      // For nested fields like "warehouse.name.nameId"
      const [objectName, nestedObject, nestedField] = nameParts;

      setFormData((prevData) => ({
        ...prevData,
        [objectName]: {
          ...prevData[objectName],
          [nestedObject]: {
            ...prevData[objectName][nestedObject],
            [nestedField]: value,
          },
        },
      }));
    } else {
      // Handle changes for regular fields
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    try {
      const formDataCreate = {
        ...formData,
      };

      await createWarehouseInwardContext(formDataCreate);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Create Warehouse Inward</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="form-section my-3">
          <label htmlFor="name">Warehouse</label>
          <select
            className="form-control"
            id="name"
            name="warehouse.name.nameId"
            value={formData.warehouse.name.nameId}
            onChange={handleInputChange}
          >
            <option value="">Select Warehouse</option>
            {getWarehouseContent?.data
              .map((item) => item?.name || [])
              .filter((nameItem) => nameItem.some((item) => item.value))
              .map((nameItem, index) => {
                return (
                  <option key={index} value={nameItem[0]?._id}>
                    {nameItem.map((item) => (
                      <div key={item._id} value={item[0]?._id}>
                        {item.value}
                      </div>
                    ))}
                  </option>
                );
              })}
          </select>
        </div>

        <div>
          <label htmlFor="inwardBrand">Inward Brand</label>
          <input
            type="text"
            id="inwardBrand"
            name="inwardBrand"
            value={formData.inwardBrand}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="outwardVendor">Outward Vendor</label>
          <input
            type="text"
            id="outwardVendor"
            name="outwardVendor"
            value={formData.outwardVendor}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-section my-3">
          <label htmlFor="deliveryStatus">Delivery Status</label>
          <select
            className="form-control" // Add the Bootstrap class for form control
            id="deliveryStatus"
            name="deliveryStatus"
            value={formData.deliveryStatus}
            onChange={handleInputChange}
          >
            <option value="">Select Delivery Status</option>
            {getStaticDataS?.deliveryStatus?.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="deliveryDate">Delivery Date</label>
          <input
            type="datetime-local"
            id="deliveryDate"
            name="deliveryDate"
            value={formData.deliveryDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="proofOfDelivery">Proof of Delivery</label>
          <input
            type="text"
            id="proofOfDelivery"
            name="proofOfDelivery"
            value={formData.proofOfDelivery}
            onChange={handleInputChange}
          />
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default CreateInwardWareHouse;
