import React, { useState } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import InspectGoods from "./warehouse-components/InspectGoods.jsx";
import UpdateInventory from "./warehouse-components/UpdateInventory.jsx";
import AllocateStorage from "./warehouse-components/AllocateStorage.jsx";
import InventoryManagement from "./warehouse-components/InventoryManagement.jsx";
import CreateInwardWareHousePage from "../warehouse-page/inward-crud/createInwardWarehousPage.jsx";
import "./warehouseIndexWorkFlow.styles.css";
import WorkflowCompletionCard from "./warehouse-components/WorkflowCompletionCard.jsx";

const WarehouseIndexWorkFlow = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isWorkflowCompleted, setIsWorkflowCompleted] = useState(false);

  const [receivedGoods, setReceivedGoods] = useState({});
  console.log(
    "🚀 ~ file: warehouseIndexWorkFlow.jsx:13 ~ WarehouseIndexWorkFlow ~ receivedGoods:",
    receivedGoods
  );
  const [inspectionData, setInspectionData] = useState({});
  const [inventoryData, setInventoryData] = useState({});
  const [storageData, setStorageData] = useState({});

  const handleSave = (data) => {
    console.log(
      "🚀 ~ file: warehouseIndexWorkFlow.jsx:18 ~ handleSave ~ data:",
      data
    );
    switch (currentStep) {
      case 0:
        setReceivedGoods(data);
        break;
      case 1:
        setInspectionData(data);
        break;
      case 2:
        setInventoryData(data);
        break;
      case 3:
        setStorageData(data);
        break;
      default:
        break;
    }
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      alert("Process completed!");
    }
  };

  const handleSaveAndNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      setIsWorkflowCompleted(true);
    }
  };

  const handleWorkflowClose = () => {
    // Reset the workflow state or navigate to another page if needed
    setCurrentStep(0);
    setIsWorkflowCompleted(false);
  };

  return (
    <div className="render-card">
      <h1>Warehouse Process</h1>
      <Stepper activeStep={currentStep} alternativeLabel>
        <Step>
          <StepLabel>Receive Goods</StepLabel>
        </Step>
        <Step>
          <StepLabel>Inspect Goods</StepLabel>
        </Step>
        <Step>
          <StepLabel>Update Inventory</StepLabel>
        </Step>
        <Step>
          <StepLabel>Allocate Storage</StepLabel>
        </Step>
      </Stepper>
      <div className="step-content">
        {currentStep === 0 && (
          <CreateInwardWareHousePage
            onSave={handleSave}
            onNext={handleNext}
            onSaveAndNext={handleSaveAndNext} // Pass the function as a prop
          />
        )}
        {currentStep === 1 && (
          <InspectGoods
            onSave={handleSave}
            onNext={handleNext}
            onSaveAndNext={handleSaveAndNext}
          />
        )}
        {currentStep === 2 && (
          <UpdateInventory
            onSave={handleSave}
            onNext={handleNext}
            onSaveAndNext={handleSaveAndNext} // Pass the function as a prop
          />
        )}
        {currentStep === 3 && (
          <AllocateStorage
            onSave={handleSave}
            onNext={handleNext}
            onSaveAndNext={handleSaveAndNext} // Pass the function as a prop
          />
        )}
        <WorkflowCompletionCard
          isOpen={isWorkflowCompleted}
          onClose={handleWorkflowClose}
        />
      </div>
    </div>
  );
};

export default WarehouseIndexWorkFlow;
