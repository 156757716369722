import React from "react";
import HomePageBanner from "./banner/homePageBanner.jsx";
import HomePageBrands from "./brands/brands.jsx";

function HomePageSettingIndex() {
  return (
    <>
      <div>
        <HomePageBanner />
        <HomePageBrands />
      </div>
    </>
  );
}

export default HomePageSettingIndex;
