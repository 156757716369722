import React from "react";

// Privacy Policy Component
const PrivacyPolicy = () => {
  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
      lineHeight: "1.6",
    },
    heading: {
      color: "#333",
      borderBottom: "2px solid #4CAF50",
      paddingBottom: "10px",
      marginBottom: "20px",
    },
    section: {
      marginBottom: "20px",
    },
    sectionHeading: {
      color: "#4CAF50",
      marginBottom: "10px",
    },
    list: {
      marginLeft: "20px",
    },
    listItem: {
      marginBottom: "10px",
    },
    contactLink: {
      color: "#4CAF50",
      textDecoration: "none",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p>
        At JRise OPC LTD, we are committed to protecting your privacy and
        ensuring the security of your personal information. This Privacy Policy
        outlines how we collect, use, disclose, and protect the information you
        provide to us when using our website and services.
      </p>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>1. Information We Collect:</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            Personal information: such as your name, email address, shipping
            address, and payment details.
          </li>
          <li style={styles.listItem}>
            Usage information: such as your IP address, browser type, device
            information, and browsing history.
          </li>
          <li style={styles.listItem}>
            Cookies and tracking technologies: we may use cookies, web beacons,
            and similar technologies to collect information about your
            interactions with our website.
          </li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>2. How We Use Your Information:</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            To process and fulfill your orders, including processing payments
            and delivering products.
          </li>
          <li style={styles.listItem}>
            To communicate with you about your orders, account, and promotional
            offers.
          </li>
          <li style={styles.listItem}>
            To improve our website, products, and services, and to personalize
            your shopping experience.
          </li>
          <li style={styles.listItem}>
            To analyze trends and gather demographic information for marketing
            and advertising purposes.
          </li>
          <li style={styles.listItem}>
            To comply with legal obligations and protect the rights, property,
            and safety of JRise OPC LTD and its users.
          </li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>
          3. Disclosure of Your Information:
        </h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            To service providers and partners who assist us in operating our
            website and providing services to you.
          </li>
          <li style={styles.listItem}>
            To comply with legal requirements, such as responding to subpoenas,
            court orders, or legal processes.
          </li>
          <li style={styles.listItem}>
            In connection with a merger, acquisition, or sale of assets, in
            which case your information may be transferred to the acquiring
            entity.
          </li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>
          4. Data Retention and Deletion Policy:
        </h2>
        <p>
          We retain your personal information for as long as necessary to
          fulfill the purposes for which it was collected, comply with legal
          obligations, resolve disputes, and enforce our agreements. You may
          request the deletion of your personal information by contacting us.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>5. Security:</h2>
        <p>
          We take reasonable measures to protect the security of your personal
          information and to prevent unauthorized access, use, or disclosure.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>6. Children's Privacy:</h2>
        <p>
          JRise OPC LTD's services are not intended for children under the age
          of 18. We do not knowingly collect personal information from children
          under the age of 18.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>
          7. Changes to This Privacy Policy:
        </h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          material changes by posting the updated policy on our website.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionHeading}>8. Contact Us:</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          practices regarding your personal information, please contact us at{" "}
          <a
            href="mailto:support@jrisealliances.com"
            style={styles.contactLink}
          >
            support@jrisealliances.com
          </a>
          .
        </p>
      </div>

      <p>
        By using JRise OPC LTD services, you consent to the collection, use, and
        disclosure of your information as described in this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
