import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductsContext } from "../../contexts/product.context.jsx";

const ProductDetail = () => {
  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "#f4f4f4",
  };

  const imageStyle = {
    maxWidth: "80%",
    maxHeight: "40%",
    objectFit: "cover",
    borderRadius: "10px",
    marginBottom: "20px",
  };

  const { getProductsByIdContext } = useContext(ProductsContext);
  const { getActiveProductsAdmin } = useContext(ProductsContext);
  console.log(
    "🚀 ~ file: ProductDetail.jsx:8 ~ ProductDetail ~ getActiveProductsAdmin:",
    getActiveProductsAdmin
  );
  const { productId } = useParams();
  const uidProduct = productId;
  const [product, setProduct] = useState(null);

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productData = await getProductsByIdContext(uidProduct);
        setProduct(getActiveProductsAdmin);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProduct();
  }, [uidProduct]);

  return (
    <div style={cardStyle}>
      <h1>{product?.name}</h1>
      <img src={product?.image} alt={product?.name} style={imageStyle} />
      <p>Brand: {product?.brand}</p>
      <p>Category: {product?.category}</p>
      <p>Description: {product?.description}</p>
      <p>Price: INR {product?.price}</p>
      <p>Availability: {product?.countInStock} in stock</p>
    </div>
  );
};

export default ProductDetail;
