import React, { useState, useEffect } from "react";
import axios from "axios";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";

const AllocateStorage = ({ onSaveAndNext }) => {
  const { getWarehouseInwardContent } = WarehouseContext([]); // Use useContext to access the context

  const [formData, setFormData] = useState({
    warehouseInwardId: "",
    storageLocation: "",
    quantity: 0,
    storageCondition: "Dry",
  });

  const [warehouseInwards, setWarehouseInwards] = useState([]);

  useEffect(() => {
    // Fetch warehouseInwards to populate the dropdown
    const fetchWarehouseInwards = async () => {
      try {
        const response = await getWarehouseInwardContent?.data;
        setWarehouseInwards(response);
      } catch (error) {
        console.error("Error fetching warehouseInwards:", error);
      }
    };

    fetchWarehouseInwards();
  }, [getWarehouseInwardContent]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to your API endpoint
      await axios.post(
        `http://localhost:3002/warehouseStorage/storage/${formData.warehouseInwardId}`,
        formData
      );

      // Reset the form data
      setFormData({
        warehouseInwardId: "",
        storageLocation: "",
        quantity: 0,
        storageCondition: "Dry",
      });

      // You may want to add additional logic here, like updating state or showing a success message
      onSaveAndNext();
    } catch (error) {
      console.error("Error creating warehouse storage:", error);
    }
  };

  // Assuming warehouseInwards is your array of objects
  const uniqueWarehouseOptions = [
    ...new Map(
      warehouseInwards.map((item) => [item.inwardBrand, item])
    ).values(),
  ];

  function formatCreatedAtDate(createdAt) {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <form className="storage-form" onSubmit={handleSubmit}>
      <div className="form-section my-3">
        <label htmlFor="warehouseInwardId">Warehouse Inward:</label>
        <select
          className="form-control"
          name="warehouseInwardId"
          value={formData.warehouseInwardId}
          onChange={handleChange}
        >
          <option value="" disabled>
            Select Warehouse Inward
          </option>
          {uniqueWarehouseOptions.map((warehouseInward) => (
            <option key={warehouseInward._id} value={warehouseInward._id}>
              {`${warehouseInward.inwardBrand} - ${formatCreatedAtDate(
                warehouseInward.createdAt
              )}`}
            </option>
          ))}
        </select>
      </div>

      <div className="form-section my-3">
        <label htmlFor="storageLocation">Storage Location:</label>
        <input
          type="text"
          className="form-control"
          name="storageLocation"
          value={formData.storageLocation}
          onChange={handleChange}
        />
      </div>

      <div className="form-section my-3">
        <label htmlFor="quantity">Quantity:</label>
        <input
          type="number"
          className="form-control"
          name="quantity"
          value={formData.quantity}
          onChange={handleChange}
        />
      </div>

      <div className="form-section my-3">
        <label htmlFor="storageCondition">Storage Condition:</label>
        <select
          className="form-control"
          name="storageCondition"
          value={formData.storageCondition}
          onChange={handleChange}
        >
          <option value="Dry">Dry</option>
          <option value="Cool">Cool</option>
          <option value="Frozen">Frozen</option>
          <option value="Ventilated">Ventilated</option>
        </select>
      </div>

      <div className="form-section my-3">
        <button className="btn btn-primary" type="submit">
          Create Storage
        </button>
      </div>
    </form>
  );
};

export default AllocateStorage;
