import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    margin: "10px",
  },
};

Modal.setAppElement("#root");

function EditInwardWareHousePage({ isOpen, onRequestClose, file }) {
  const { getStaticDataS } = useAuth([]);
  console.log(
    "🚀 ~ file: editInwardWarehouse.jsx:25 ~ EditInwardWareHousePage ~ getStaticDataS:",
    getStaticDataS
  );

  console.log(
    "🚀 ~ file: editInwardWarehouse.jsx:24 ~ EditInwardWareHousePage ~ file:",
    file
  );
  const { getWarehouseContent } = WarehouseContext([]);
  console.log(
    "🚀 ~ file: editInwardWarehouse.jsx:25 ~ EditInwardWareHousePage ~ getWarehouseContent:",
    getWarehouseContent
  );

  const { UpdateWarehouseInwardContext } = WarehouseContext();

  const { user } = useAuth();
  const userId = user?.userId; // user id

  const [formData, setFormData] = useState({ ...file });

  useEffect(() => {
    setFormData({ ...file });
  }, [file]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      // Create a copy of formData
      const formDataUpdate = { ...formData };

      // Check if formData.deliveryStatus is defined
      if (formData.deliveryStatus) {
        // Set formDataUpdate.deliveryStatus to the ID as a number
        formDataUpdate.deliveryStatus = formData.deliveryStatus.id;
      } else {
        console.error("formData.deliveryStatus is not properly set.");
        return; // Abort the save operation if deliveryStatus is not valid
      }

      const warehouseId = file._id;
      await UpdateWarehouseInwardContext(warehouseId, formDataUpdate);

      // Close the modal after saving
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  const formattedDeliveryDate = formData.deliveryDate
    ? formData.deliveryDate.slice(0, 16)
    : "";

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Edit Warehouse Inward</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="form-section my-3">
          <label htmlFor="productInfo">WareHouse</label>
          <select
            className="form-control"
            id="productInfo"
            name="warehouse"
            value={formData?.warehouse?.name?.[0]?._id}
            onChange={handleInputChange}
          >
            <option value="">Select Warehouse</option>
            {/* {getWarehouseContent?.data
              .map((item) => item?.name || [])
              .filter((nameItem) => nameItem?.some((item) => item?.value))
              .map((nameItem, index) => {
                return (
                  <option key={index} value={nameItem[0]?._id}>
                    {nameItem.map((item) => (
                      <div key={item?._id} value={item?._id}>
                        {item?.value}
                      </div>
                    ))}
                  </option>
                );
              })} */}
            {getWarehouseContent?.data.map((warehouse) => (
              <option key={warehouse._id} value={warehouse._id}>
                {warehouse.name.map((nameItem) => (
                  <div key={nameItem._id} value={nameItem._id}>
                    {nameItem.value}
                  </div>
                ))}
              </option>
            ))}
          </select>
        </div>

        <div className="form-section my-3">
          <label htmlFor="inwardBrand">Inward Brand</label>
          <input
            type="text"
            id="inwardBrand"
            name="inwardBrand"
            value={formData.inwardBrand}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-section my-3">
          <label htmlFor="outwardVendor">Outward Vendor</label>
          <input
            type="text"
            id="outwardVendor"
            name="outwardVendor"
            value={formData.outwardVendor}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-section my-3">
          <label htmlFor="deliveryStatus">Delivery Status</label>
          <select
            className="form-control"
            id="deliveryStatus"
            name="deliveryStatus"
            value={formData?.deliveryStatus?.id}
            onChange={handleInputChange}
          >
            <option value="">Select Delivery Status</option>
            {getStaticDataS?.deliveryStatus?.map((option) => (
              <option key={option?.id} value={option?.id}>
                {option?.type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-section my-3">
          <label htmlFor="deliveryDate">Delivery Date</label>
          <input
            type="datetime-local"
            id="deliveryDate"
            name="deliveryDate"
            value={formattedDeliveryDate}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-section my-3">
          <label htmlFor="proofOfDelivery">Proof of Delivery</label>
          <input
            type="text"
            id="proofOfDelivery"
            name="proofOfDelivery"
            value={formData.proofOfDelivery}
            onChange={handleInputChange}
          />
        </div>
      </form>

      <SubmitButton text="Save" handleSubmit={handleSave} />
    </Modal>
  );
}

export default EditInwardWareHousePage;
