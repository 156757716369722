import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { Box } from "@mui/system";
import UserAdminComponent from "../admin-page/components/users/userAdmin.component.jsx";
import UserInvoiceAdmin from "../admin-page/components/invoices/userInvoicesAdmin.jsx";
import ProductsAdmin from "../admin-page/components/products/productsAdmin.component.jsx";
import HomePageSettingIndex from "../admin-page/components/homePageSetting/homePageSettingIndex.jsx";
import { WarehouseContexts } from "../../contexts/warehouse.context.jsx";
import GetWarehouse from "./warehouse-crud/getWarehouse.jsx";
import InwardWareHouse from "./inward-crud/inwardWarehouse.jsx";
import CreateInwardWareHouse from "./inward-crud/createInwardWarehouse.jsx";
import CreateInwardWareHousePage from "./inward-crud/createInwardWarehousPage.jsx";

function WarehouseIndex() {
  const [value, setValue] = useState(0); // Initialize the selected tab state

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: 140,
          padding: 2,
          borderRight: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="WareHouse" />
          <Tab label="Create Inward" />
          <Tab label="Inward" />
          {/* Add more tabs as needed */}
        </Tabs>
      </Box>
      <Box sx={{ flex: 1, padding: 3 }}>
        {value === 0 && <GetWarehouse />}
        {value === 1 && <CreateInwardWareHousePage />}
        {value === 2 && <InwardWareHouse />}
        {/* Add content for additional tabs here */}
      </Box>
    </Box>
  );
}

export default WarehouseIndex;
