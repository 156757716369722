// InwardWareHouse?.js

import React, { useEffect, useState, useContext } from "react";

import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";
import AddIcon from "@mui/icons-material/AddCircleOutline"; // Plus icon
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import EditWarehouse from "../warehouse-crud/editWareHouse.jsx";
import CreateWareHouse from "../warehouse-crud/createWarehouse.jsx";
import CreateInwardWareHouse from "./createInwardWarehouse.jsx";
import DateDisplay from "../../admin-page/components/products/DateDisplay.jsx";
import PaginationComponent from "../../pagination/PaginationComponent.jsx";
// import LoadingOverlay from "../../user-profile/order-history/LoadingOverlay.jsx";
import LoadingOverlay from "../../user-profile/order-history/LoadingOverlay.jsx";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import Spinner from "react-bootstrap/esm/Spinner.js";
import EditInwardWareHousePage from "./editInwardWarehouse.jsx";

const InwardWareHouse = () => {
  const { getWarehouseInwardContent } = WarehouseContext([]); // Use useContext to access the context
  const { getWarehouseInwardRefreshContext } = WarehouseContext([]); // Use useContext to access the context

  const { user } = useAuth();

  const userId = user?.userId; // user id

  console.log(
    "🚀 ~ file: getWarehouse.jsx:7 ~ InwardWareHouse ~ getWarehouseInwardContent:",
    getWarehouseInwardContent
  );
  const [warehouses, setWarehouses] = useState([]);
  console.log(
    "🚀 ~ file: getWarehouse.jsx:31 ~ InwardWareHouse ~ warehouses:",
    warehouses
  );
  const [createWarehouseModal, setCreateWarehouseModal] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  console.log(
    "🚀 ~ file: inwardWarehouse.jsx:41 ~ InwardWareHouse ~ editFile:",
    editFile
  );

  // ANCHOR - Pagination and refresh
  const pageSize = 20; // Number of rows per page
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner

  // Update warehouses when getWarehouseInwardContent changes
  useEffect(() => {
    if (getWarehouseInwardContent) {
      setWarehouses(getWarehouseInwardContent?.data);
    }
  }, [getWarehouseInwardContent]);

  // For Form Order
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, warehouses?.length);
  const formUploadOrder = Array.isArray(warehouses)
    ? warehouses?.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(warehouses?.length / pageSize);
  console.log(
    "🚀 ~ file: inwardWarehouse.jsx:57 ~ InwardWareHouse ~ formUploadOrder:",
    formUploadOrder
  );
  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await getWarehouseInwardRefreshContext();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div>
        <h1>Users</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const handleCreateClick = () => {
    setCreateWarehouseModal();
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };
  const handleEditClick = (item) => {
    console.log(
      "🚀 ~ file: getWarehouse.jsx:51 ~ handleEditClick ~ item:",
      item
    );
    setEditFile(item);
    setShowEditModal(true);
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  return (
    <div>
      <h1>warehouse inward</h1>
      <div className="order-history-body">
        <div className="form-upload-order-container">
          {" "}
          {/* <h1>Active Products</h1> */}
          <Button className="order-history-Refresh" onClick={handleRefresh}>
            Refresh Data
          </Button>
          <button
            onClick={() => handleCreateClick()}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <AddIcon color="primary" />
          </button>
          <Table striped bordered hover className="custom-table">
            <thead>
              <tr>
                <th>warehouse</th>
                <th>Inward Product</th>
                <th>Inward Brand</th>
                <th>Outward Vendor</th>
                <th>Delivery Status</th>
                <th>Delivery Date</th>
                <th>Proof Of Delivery</th>

                <th></th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {formUploadOrder?.map((item) => (
                <tr id="limited-row" key={item?._id}>
                  <td className="">
                    {item?.warehouse?.name?.map((nameItem) =>
                      nameItem?.value ? (
                        <div key={nameItem?._id}>{nameItem?.value}</div>
                      ) : (
                        <div key={nameItem?._id}>NAN</div>
                      )
                    )}
                  </td>
                  <td className="">{item?.productInfo}</td>
                  <td className="">{item?.inwardBrand}</td>
                  <td className="">{item?.outwardVendor}</td>
                  <td className="">
                    {Array.isArray(item.deliveryStatus) ? (
                      item.deliveryStatus.map((x) => (
                        <div key={x.id}>{x.type}</div>
                      ))
                    ) : (
                      <div key={item.deliveryStatus.id}>
                        {item.deliveryStatus.type}
                      </div>
                    )}
                  </td>
                  <td className="">
                    <DateDisplay dateString={item?.deliveryDate} />
                  </td>{" "}
                  <td className="">{item?.proofOfDelivery}</td>
                  {/* <td className="">{item?.userId}</td> */}
                  {/* <td className="">{item?.isActive}</td> */}
                  {/* Include additional fields as needed */}
                  <td className="">
                    <button
                      onClick={() => handleEditClick(item)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <EditIcon color="primary" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {isLoading && <LoadingOverlay />}
          <PaginationComponent
            currentPage={currentPageForm}
            totalPages={totalPagesForm}
            onPageChange={handlePageChangeForm}
          />
        </div>
        {showEditModal && (
          <EditInwardWareHousePage
            isOpen={showEditModal}
            onRequestClose={handleEditModalClose}
            file={editFile}
          />
        )}
        {showCreateModal && (
          <CreateInwardWareHouse
            isOpen={showCreateModal}
            onRequestClose={handleCreateModalClose}
            file={createWarehouseModal}
          />
        )}
      </div>
    </div>
  );
};

export default InwardWareHouse;
