import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./UserProviderMongo.jsx";
import {
  CreateProductsAdminRoutes,
  GetActiveProductsAdminRoutes,
  GetDeletedProductsAdminRoutes,
  GetProductsAdminRoutes,
  GetSalesOrderAllRoutes,
  OrderHistoryAdminRoutes,
  deleteProductsRoutes,
  getAllUsersRoutes,
  updateAllProductsRoutes,
  updateAllUsersRoutes,
} from "../api/user.api.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { user } = useAuth();
  const userIdParams = user?.userId;
  console.log(
    "🚀 ~ file: adminPanel.context.jsx:25 ~ UserProvider ~ userIdParams:",
    userIdParams
  );

  const [getAllUsers, setGetAllUsers] = useState(null);

  const [filesAdmin, setFilesAdmin] = useState([]);
  console.log(
    "🚀 ~ file: adminPanel.context.jsx:33 ~ UserProvider ~ filesAdmin:",
    filesAdmin
  );
  const [updateGetAllUsers, setUpdateGetAllUsers] = useState([]);
  const [getSaleOrderAllForm, setGetSaleOrderAllForm] = useState([]);
  console.log(
    "🚀 ~ file: adminPanel.context.jsx:35 ~ UserProvider ~ getSaleOrderAllForm:",
    getSaleOrderAllForm
  );
  const [getActiveProductsAdmin, setGetActiveProductsAdmin] = useState([]);
  const [getDeletedProductsAdmin, setGetDeletedProductsAdmin] = useState([]);
  const [createProductsAdmin, setCreateProductsAdmin] = useState([]);
  const [updateProductsAdmin, setUpdateProductsAdmin] = useState([]);
  const [deleteProducts, setDeleteProducts] = useState([]);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(true);

  // ANCHOR - Make an API call with isAdmin value
  const getUsersWithAdminRole = async () => {
    try {
      const userId = userIdParams;
      const response = await axios.get(getAllUsersRoutes.GetAllUsers(userId));
      setGetAllUsers(response.data);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching getAllUsers data:", error);
    }
  };

  // ANCHOR - Simulate fetching and updating data
  const refreshFilesFormOrder = async () => {
    const userId = userIdParams;
    const apiUrl = `${getAllUsersRoutes.GetAllUsers(userId)}`;
    try {
      // Simulate fetching data from the API
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const updatedData = await response.json(); // Parse the response JSON
      // Update the context data with the new data
      setUpdateGetAllUsers(updatedData);
    } catch (error) {
      console.error("Error simulating data update:", error);
    }
  };

  // ANCHOR - update Users With Admin Role
  const updateUsersWithAdminRole = async (formData) => {
    console.log(
      "🚀 ~ file: adminPanel.context.jsx:77 ~ updateUsersWithAdminRole ~ formData:",
      formData
    );
    const userId = userIdParams;
    const uniqueId = formData?.uniqueId;
    try {
      setError(null); // Clear any previous errors

      // Convert the UID to a string if it's not already
      //   const id = typeof uid === "string" ? uid : uid.toString();
      const apiUrl = `${updateAllUsersRoutes.UpdateAllUsers(uniqueId, userId)}`;

      const response = await axios.put(apiUrl, formData);

      setUpdateGetAllUsers(response?.data);

      // After a successful update, call getUsersWithAdminRole to update updateUsersWithAdminRole

      // setGetSaleOrderForm(updateSaleOrderForm);
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
      await getUsersWithAdminRole();
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message, {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR - GET ALL SALES ORDER
  const getSalesOrderAdmin = async () => {
    const userId = userIdParams;
    const apiUrl = GetSalesOrderAllRoutes.GetSalesOrderAll(userId);
    console.log(
      "🚀 ~ file: adminPanel.context.jsx:119 ~ getSalesOrderAdmin ~ apiUrl:",
      apiUrl
    );

    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      console.log(
        "🚀 ~ file: adminPanel.context.jsx:127 ~ getSalesOrderAdmin ~ response:",
        response
      );
      setGetSaleOrderAllForm(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR - GET ALL SALES ORDER Excel order
  const getOrderHistoryAdmin = async () => {
    const userId = userIdParams; // Make sure userIdParams is defined and contains the correct user ID
    const apiUrl = OrderHistoryAdminRoutes.orderHistoryAdmin(userId);
    try {
      console.log(
        "🚀 ~ file: adminPanel.context.jsx:153 ~ getOrderHistoryAdmin ~ apiUrl:",
        apiUrl
      );
      const response = await axios.get(apiUrl);
      console.log(
        "🚀 ~ file: adminPanel.context.jsx:150 ~ getOrderHistoryAdmin ~ response:",
        response
      );
      if (response.status === 200) {
        setFilesAdmin(response.data);
      }
    } catch (error) {
      console.error("Error fetching user files:", error);
    }
  };
  // // Update Upload Order
  // const updateSaleOrder = async (formData, uid) => {
  //   try {
  //     setError(null); // Clear any previous errors

  //     // Convert the UID to a string if it's not already
  //     const id = typeof uid === "string" ? uid : uid.toString();
  //     const apiUrl = `${updateSaleOrderRoutes.UpdateSaleOrder(id)}`;

  //     const response = await axios.put(apiUrl, formData);

  //     setUpdateSaleOrderForm(response?.data);

  //     // After a successful update, call getSalesOrder to update getSaleOrderForm
  //     await getSalesOrder();

  //     // setGetSaleOrderForm(updateSaleOrderForm);
  //     if (response.status === 200 || response.status === 201) {
  //       toast.success(response?.data?.message);
  //     } else {
  //       toast.error(error.response?.data?.message);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     toast.error(error.response?.data?.message, {
  //       position: "top-right",
  //       autoClose: 3000, // 3 seconds
  //     });
  //     setError(
  //       error.response?.data?.message || "An error occurred during signup."
  //     ); // Handle signup errors here
  //   }
  // };

  // ANCHOR - GET ALL Active Products
  const getActiveProductsAdminContext = async () => {
    const apiUrl = GetActiveProductsAdminRoutes;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      setGetActiveProductsAdmin(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR - GET ALL Deleted Products
  const getDeletedProductsAdminContext = async () => {
    const apiUrl = GetDeletedProductsAdminRoutes;

    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      setGetDeletedProductsAdmin(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR - Simulate fetching and updating data ALL Products
  const refreshFilesGetProductsAdmin = async () => {
    const apiUrl = GetActiveProductsAdminRoutes;
    try {
      // Simulate fetching data from the API
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const updatedData = await response.json(); // Parse the response JSON
      // Update the context data with the new data
      setGetActiveProductsAdmin(updatedData);
    } catch (error) {
      console.error("Error simulating data update:", error);
    }
  };

  // ANCHOR - Create Products With Admin Role
  const createProductWithAdminRole = async (formDataWithUpdatedIsAdmin) => {
    const apiUrl = `${CreateProductsAdminRoutes.ProductsAdminRoutes(
      userIdParams
    )}`;
    // const apiUrl = CreateProductsAdminRoutes;
    try {
      setError(null); // Clear any previous errors
      const response = await axios.post(apiUrl, formDataWithUpdatedIsAdmin);
      setCreateProductsAdmin(response?.data);
      await getActiveProductsAdminContext();
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message || "Product Created!");
      } else {
        toast.error(error.response?.data?.message || "Product creation Failed");
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: adminPanel.context.jsx:270 ~ createProductWithAdminRole ~ error:",
        error
      );
      console.error(error);
      toast.error(error.response?.data?.message || "Product creation Failed", {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(error.response?.data?.message || "Product creation Failed."); // Handle signup errors here
    }
  };

  // ANCHOR - update products With Admin Role
  const UpdateProductsWithAdminRole = async (formData, uId) => {
    const id = uId;
    const uniqueId = userIdParams;

    try {
      setError(null); // Clear any previous errors

      // Convert the UID to a string if it's not already
      //   const id = typeof uid === "string" ? uid : uid.toString();
      const apiUrl = `${updateAllProductsRoutes.updateAllProducts(
        id,
        uniqueId
      )}`;

      const response = await axios.put(apiUrl, formData);

      setUpdateProductsAdmin(response?.data);

      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message);
      } else {
        toast.error(error.response?.data?.message);
      }
      await getActiveProductsAdminContext();
      await getDeletedProductsAdminContext();
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message, {
        position: "top-right",
        autoClose: 3000, // 3 seconds
      });
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  // ANCHOR -  soft delete products With Admin Role
  const DeleteProductsWithAdminRole = async (uid) => {
    // Make an API call to soft delete the order form
    try {
      const id = uid;

      const uniqueId = userIdParams;

      const apiUrl = `${deleteProductsRoutes.deleteProducts(id)}`;
      const response = await axios.delete(apiUrl);

      if (response.status === 200) {
        // Update the local state to mark the order form as soft-deleted
        setDeleteProducts((prevOrderForms) =>
          prevOrderForms.map((form) =>
            form._id === id ? { ...form, deleted: true } : form
          )
        );
        // setGetSaleOrderForm(updateSaleOrderForm);
        if (response.status === 200) {
          toast.success(response?.data?.message);
        } else {
          toast.error(error.response?.data?.message);
        }
        if (response.status === 200) {
          await getActiveProductsAdminContext();
        }
      } else {
        console.error("Failed to soft delete the order form");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // ANCHOR - useEffect for userIsAdmin
  useEffect(() => {
    getUsersWithAdminRole();
    getOrderHistoryAdmin();
  }, [userIdParams]);

  // ANCHOR - useEffect for other getData
  useEffect(() => {
    getActiveProductsAdminContext();
    getDeletedProductsAdminContext();
  }, []);

  const value = {
    getUsersWithAdminRole,
    refreshFilesFormOrder,
    updateUsersWithAdminRole,
    getSalesOrderAdmin,
    getSaleOrderAllForm,
    getAllUsers,
    updateGetAllUsers,
    getOrderHistoryAdmin,
    filesAdmin,
    getActiveProductsAdminContext,
    getActiveProductsAdmin,
    refreshFilesGetProductsAdmin,
    UpdateProductsWithAdminRole,
    updateProductsAdmin,
    DeleteProductsWithAdminRole,
    deleteProducts,
    getDeletedProductsAdminContext,
    getDeletedProductsAdmin,
    createProductWithAdminRole,
    createProductsAdmin,
    loading,
  };
  console.log(
    "🚀 ~ file: adminPanel.context.jsx:324 ~ UserProvider ~ value.getAllUsers:",
    value.getAllUsers
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
