import BulkUploadComponent from "../../components/bulk-order/bulk-upload.component.jsx";

const BulkOrder = () => {
  return (
    <div className="bulkUpload-route-container">
      <BulkUploadComponent />
    </div>
  );
};

export default BulkOrder;
