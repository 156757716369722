import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useHomePage } from "../../../../../contexts/homePage.context.jsx";
import SubmitButton from "../../../../bulk-order/submit.btn.component.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    margin: "10px",
    width: "250px",
  },
};

Modal.setAppElement("#root");

function CreateBrand({ isOpen, onRequestClose }) {
  const { createBrandsContext } = useHomePage();
  const [formData, setFormData] = useState({
    name: "",
    logo: null, // Change to null to store the file object
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      if (name === "brand" || name === "category") {
        setFormData({
          ...formData,
          [name]: value,
          productDetails: {
            ...formData.productDetails,
            [name]: value,
          },
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const handleSave = async () => {
    try {
      const formDataCreate = new FormData();
      formDataCreate.append("name", formData.name);
      formDataCreate.append("logo", formData.logo);

      await createBrandsContext(formDataCreate);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle logo file input change
  const handleLogoInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Update the 'logo' field with the file object
      setFormData({
        ...formData,
        logo: file,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Create New Brand</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-section my-3">
              <label htmlFor="text">Brand Name</label>
              <input
                type="text"
                name="name"
                placeholder="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            {/* File input for logo */}
            <div className="form-section my-3">
              <label htmlFor="logo">Brand Logo</label>
              <input
                type="file"
                name="logo"
                accept="image/*"
                onChange={handleLogoInputChange}
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default CreateBrand;
