import React from "react";
import OrderForm from "./components/orderForm.jsx";
import OrderTable from "./components/orderTable.jsx";

function OrderFormMain() {
  return (
    <div>
      <OrderForm />
      <OrderTable />
    </div>
  );
}

export default OrderFormMain;
