import React from "react";
import DownloadIcon from "@mui/icons-material/Download";

const FileButtons = ({ onDownloadClick, onUploadClick }) => {
  return (
    <div className="file-buttons-body">
      <button id="download-button" onClick={onDownloadClick}>
        Product Excel Template <DownloadIcon />
      </button>
    </div>
  );
};

export default FileButtons;
