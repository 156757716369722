import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHomePage } from "../../../../../contexts/homePage.context.jsx";
import LoadingOverlay from "../../../../user-profile/order-history/LoadingOverlay.jsx";
import DeleteConfirmationModal from "../../../../success-modal/deleteModal.js";
import PaginationComponent from "../../../../pagination/PaginationComponent.jsx";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline.js";
import EditHomePageBanner from "../banner/editbanner.jsx";
import CreateBanner from "./createBrand.jsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditBrands from "./editBrands.jsx";
import CreateBrand from "./createBrand.jsx";

function HomePageBrands() {
  const { getBrandsContent } = useHomePage();
  console.log(
    "🚀 ~ file: brands.jsx:17 ~ HomePageBrands ~ getBrandsContent:",
    getBrandsContent
  );
  const { DeleteBrandsContext } = useHomePage();

  const [userList, setUserList] = useState([]);

  const pageSize = 5; // Number of rows per page
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Set to true initially
  const [showEditModal, setShowEditModal] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createProducts, setCreateProducts] = useState(null);

  const handleCreateClick = () => {
    setCreateProducts();
    setShowCreateModal(true);
  };
  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };
  const handleEditClick = (user) => {
    setEditUser(user);
    setShowEditModal(true);
  };

  const handleDeleteClick = (user) => {
    setDeleteUser(user);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (user) => {
    if (user && user._id) {
      const uid = user._id;
      try {
        await DeleteBrandsContext(uid);
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Invalid or missing _id in file object:", user);
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (getBrandsContent) {
      setUserList(getBrandsContent);
      setIsLoading(false);
    }
  }, [getBrandsContent]);

  if (!userList || !userList?.length === 0) {
    return (
      <div>
        <h1>User Files</h1>
        {/* <Button onClick={handleRefresh}>Refresh Data</Button>{" "} */}
        <p>No files found for the user.</p>
      </div>
    );
  }

  // For Form Order
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, userList?.length);
  const userListMap = Array.isArray(userList)
    ? userList.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(userList?.length / pageSize);

  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  return (
    <div className="user-admin-body">
      <h1 className="category-header">
        <ArrowDropDownIcon />
        Brands With Us
        <ArrowDropDownIcon />
      </h1>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div>
          <button
            onClick={() => handleCreateClick()}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <AddCircleOutline color="primary" />
          </button>
          <Table striped bordered hover className="custom-table">
            <thead>
              <tr>
                <th>logo</th>
                <th>name</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>
              {userListMap?.map((user) => (
                <tr key={user?._id}>
                  <td className="">
                    <img
                      src={user?.logo.replace(/\\/g, "/")} // Replace double backslashes with forward slashes
                      alt={user?.logo}
                      onError={(e) => {
                        e.target.src = "/404.png"; // Assuming 404.png is in the public directory
                      }}
                      style={{
                        maxHeight: "100px",
                        maxWidth: "100px",
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </td>{" "}
                  <td>{user?.name}</td>
                  <td className="">{!user?.deleted ? "yes" : "no"}</td>
                  <td>
                    <button
                      onClick={() => handleEditClick(user)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <EditIcon color="primary" />
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDeleteClick(user)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteIcon color="error" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationComponent
            currentPage={currentPageForm}
            totalPages={totalPagesForm}
            onPageChange={handlePageChangeForm}
          />
          {isLoading && <LoadingOverlay />}
        </div>
      )}
      {showEditModal && (
        <EditBrands
          isOpen={showEditModal}
          onRequestClose={() => setShowEditModal(false)}
          editUser={editUser}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={() => handleConfirmDelete(deleteUser)}
          user={deleteUser}
        />
      )}
      {showCreateModal && (
        <CreateBrand
          isOpen={CreateBanner}
          onRequestClose={handleCreateModalClose}
          file={createProducts}
        />
      )}
      {/* <Button className="order-history-Refresh" onClick={handleRefresh}>
        Refresh Data
      </Button> */}
    </div>
  );
}

export default HomePageBrands;
