import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import { useUser } from "../../../contexts/adminPanel.context.jsx";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    margin: "10px",
  },
};

Modal.setAppElement("#root");

function EditWarehouse({ isOpen, onRequestClose, file }) {
  console.log("🚀 ~ file: editWareHouse.jsx:25 ~ EditWarehouse ~ file:", file);
  const { UpdateWarehouseContext } = WarehouseContext();
  const uniqueId = file?._id;
  const { user } = useAuth();
  const userId = user?.userId; // user id

  const [formData, setFormData] = useState({
    name: file?.name?.[0]?.value,
    location: file?.location,
    userId: userId,
    address: file?.address,
    city: file?.city,
    country: file?.country,
    state: file?.state,
    isActive: file?.isActive,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    try {
      const formDataHandleSave = {
        ...formData,
        isActive: formData.isActive.toString(), // Convert isAdmin to a string
      };

      await UpdateWarehouseContext(uniqueId, formDataHandleSave);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Update Warehouse</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="name">name</label>
              <input
                type="text"
                name="name"
                placeholder="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="location">location</label>
              <input
                type="text"
                name="location"
                placeholder="location"
                value={formData.location}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="address">address</label>
              <input
                type="text"
                name="address"
                placeholder="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="name">city</label>
              <input
                type="text"
                name="city"
                placeholder="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="country">country</label>
              <input
                type="text"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="rating">state</label>
              <input
                type="text"
                name="state"
                placeholder="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-section my-3">
              <label htmlFor="isActive">
                {formData.isActive
                  ? "Warehouse Currently Active"
                  : "Warehouse Currently InActive"}
              </label>{" "}
              <input
                className="form-check-input"
                type="checkbox"
                name="isActive"
                checked={formData.isActive}
                onChange={handleInputChange}
                id="isActive"
                style={{ width: "50px", height: "30px", display: "flex" }} // Adjust width and height as needed
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default EditWarehouse;
