import React, { useState } from "react";
import axios from "axios";

function UpdateInventory({ onSaveAndNext }) {
  const [formData, setFormData] = useState({
    warehouseInwardId: "",
    inspectorName: "",
    quality: "Excellent",
    quantity: 0,
    condition: "New",
    notes: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      onSaveAndNext();

      // Make a POST request to your API endpoint
      const response = await axios.post(
        `http://localhost:3002/inspections/inspect/${formData.warehouseInwardId}`,
        formData
      );
      // Call the onAdd function passed as a prop to update the inspection list
      //   onAdd(response.data.inspection);
      // Reset the form data
      setFormData({
        warehouseInwardId: "",
        inspectorName: "",
        quality: "Excellent",
        quantity: 0,
        condition: "New",
        notes: "",
      });
      // Call onSaveAndNext after resetting the form data
    } catch (error) {
      console.error("Error creating inspection:", error);
    }
  };
  return (
    <form className="inspection-form" onSubmit={handleSubmit}>
      <div className="form-section my-3">
        <button className="btn btn-primary" type="submit">
          Inventory Managed
        </button>
      </div>
    </form>
  );
}

export default UpdateInventory;
