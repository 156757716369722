import React, { useState } from "react";
import "../mfi.style.css";
import SubmitButton from "../../../../bulk-order/submit.btn.component.jsx";
import { useAuth } from "../../../../../contexts/UserProviderMongo.jsx";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";

const customStyles = {
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
    margin: "10px",
    background: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow for a card-like appearance
    borderRadius: "8px", // Add border radius for rounded corners
  },
};

Modal.setAppElement("#root");

const MfiCreateForm = ({ isOpen, onRequestClose }) => {
  const { createMfiContext, user } = useAuth();
  const userId = user?.userId;

  const [formData, setFormData] = useState({
    userId: userId,
    businessName: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessPinCode: "",
    contactPerson: {
      name: "",
      email: "",
      phone: "",
    },
    documents: {
      panCard: "",
      gstin: "",
      registrationCertificate: "",
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if the name includes a dot (indicating a nested field)
    if (name.includes(".")) {
      const [section, field] = name.split(".");
      // Update the nested field
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: value,
        },
      }));
    } else {
      // If it's not a nested field, update it directly
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);

      await createMfiContext(formData);
      onRequestClose();
      // Clear the form fields
      setFormData({
        userId: userId,
        businessName: "",
        businessAddress: "",
        businessCity: "",
        businessState: "",
        businessPinCode: "",
        contactPerson: {
          name: "",
          email: "",
          phone: "",
        },
        documents: {
          panCard: "",
          gstin: "",
          registrationCertificate: "",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
      className="mfi-form-container"
    >
      <h2 className="text-center">Create New MFI</h2>

      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>
      <form onSubmit={handleSubmit} className="mfi-form">
        {/* billTo */}
        <div className="mfi-section">
          <h2>Business Details</h2>
          <label htmlFor="businessName">Business Name</label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            required
          />

          <label htmlFor="businessAddress">Business Address</label>
          <input
            type="text"
            id="businessAddress"
            name="businessAddress"
            value={formData.businessAddress}
            onChange={handleChange}
          />

          <label htmlFor="businessCity">Business City</label>
          <input
            type="text"
            id="businessCity"
            name="businessCity"
            value={formData.businessCity}
            onChange={handleChange}
          />

          <label htmlFor="businessState">Business State</label>
          <input
            type="text"
            id="businessState"
            name="businessState"
            value={formData.businessState}
            onChange={handleChange}
          />

          <label htmlFor="businessPinCode">Business PinCode</label>
          <input
            type="text"
            id="businessPinCode"
            name="businessPinCode"
            value={formData.businessPinCode}
            onChange={handleChange}
          />
        </div>

        {/* Contact Person */}
        <div className="mfi-container">
          <h2>Contact Person Details</h2>
          <label htmlFor="contactPersonName">Contact Person Name</label>
          <input
            type="text"
            id="contactPersonName"
            name="contactPerson.name"
            value={formData.contactPerson.name}
            onChange={handleChange}
          />

          <label htmlFor="contactPersonEmail">Contact Person Email</label>
          <input
            type="text"
            id="contactPersonEmail"
            name="contactPerson.email"
            value={formData.contactPerson.email}
            onChange={handleChange}
          />

          <label htmlFor="contactPersonPhone">Contact Person Phone</label>
          <input
            type="text"
            id="contactPersonPhone"
            name="contactPerson.phone"
            value={formData.contactPerson.phone}
            onChange={handleChange}
          />
        </div>

        {/* Documents */}
        <div className="mfi-container">
          <h2>Document Details</h2>
          <label htmlFor="panCard">PAN Card</label>
          <input
            type="text"
            id="panCard"
            name="documents.panCard"
            value={formData.documents.panCard}
            onChange={handleChange}
          />

          <label htmlFor="gstin">GSTIN</label>
          <input
            type="text"
            id="gstin"
            name="documents.gstin"
            value={formData.documents.gstin}
            onChange={handleChange}
          />

          <label htmlFor="registrationCertificate">
            Registration Certificate
          </label>
          <input
            type="text"
            id="registrationCertificate"
            name="documents.registrationCertificate"
            value={formData.documents.registrationCertificate}
            onChange={handleChange}
          />
        </div>
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </Modal>
  );
};

export default MfiCreateForm;
