import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { KeyboardArrowRight as KeyboardArrowRightIcon } from "@mui/icons-material";
import GeneralTaxInvoice from "./general-tax-invoice.jsx";
import OrderHistory from "../user-profile/order-history/order-history.jsx";
import InvoiceHistory from "./invoiceHistory.jsx";
import EditInvoicePage from "./editTaxInvoice.jsx";
import GetDeliveryChallan from "./delhivery-challan/getDeliveryChallan.jsx";
import CreateDeliveryChallan from "./delhivery-challan/createDeliveryChallan.jsx";
import CreateCustomerInvoicesMidland from "./customer-invoices-midland/CreateCustomerInvoicesMidland.jsx";
import GetCustomerInvoiceMidland from "./customer-invoices-midland/GetCustomerInvoiceMidland.jsx";

function InvoiceGenerate() {
  const [value, setValue] = useState(0); // Initialize the selected tab state
  const [selectedOptionColour, setSelectedOptionColourValue] =
    useState("#D4FAFF");
  const [defaultOptionColour, setDefaultOptionColourValue] =
    useState("inherit");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    // TAX INVOICES
    <GeneralTaxInvoice />,
    <InvoiceHistory />,
    // DELIVERY CHALLAN
    <CreateDeliveryChallan />,
    <GetDeliveryChallan />,
    // CUSTOMER INVOICES MIDLAND
    <CreateCustomerInvoicesMidland />,
    <GetCustomerInvoiceMidland />,
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: 140,
          padding: 2,
          borderRight: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ bgcolor: "#fffff" }} // Default background color for tabs
        >
          {tabItems.map((tabItem, index) => (
            <Tab
              key={index}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {tabItem.label}
                  {value === index && (
                    <KeyboardArrowRightIcon
                      sx={{ ml: 1 }} // Add margin to the right of the arrow
                    />
                  )}
                </div>
              }
              sx={{
                bgcolor:
                  value === index ? selectedOptionColour : defaultOptionColour,
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ flex: 1, padding: 1 }}>{tabs[value]}</Box>
    </Box>
  );
}

export default InvoiceGenerate;

// Define an array of tab items with labels
const tabItems = [
  { label: "Create Invoice" },
  { label: "Invoice History" },
  { label: "Create Delhivery Challan" },
  { label: "Delhivery Challan History" },
  // CUSTOMER INVOICES MIDLAND
  { label: "Create Customer Invoices (Midland)" },
  { label: "Customer Invoices (Midland) History" },
];
