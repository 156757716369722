import React from "react";
import DownloadIcon from "@mui/icons-material/Download";

const ShipToButton = ({ onDownloadClick, onUploadClick }) => {
  return (
    <div className="file-buttons-body m-2">
      <button id="download-button" onClick={onDownloadClick}>
        Ship To Excel Template <DownloadIcon />
      </button>
    </div>
  );
};

export default ShipToButton;
