import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Product from "./Products"; // Import your Product component

import "./category.styles.scss";
import { useUser } from "../../contexts/adminPanel.context.jsx";

const Category = () => {
  const { getActiveProductsAdmin } = useUser();

  // Initialize state to store products
  const [products, setProducts] = useState([]);

  // Replace 'data' with your JSON data
  const data = getActiveProductsAdmin;
  console.log("🚀 ~ file: category.component.jsx:17 ~ Category ~ data:", data);

  useEffect(() => {
    // Categorize products by their category
    const productsByCategory = {};

    data.forEach((product) => {
      const category = product.productDetails.category;

      if (!productsByCategory[category]) {
        productsByCategory[category] = [];
      }

      productsByCategory[category].push(product);
    });

    // Update the state with categorized products
    setProducts(productsByCategory);
  }, [data]);

  return (
    <div>
      {Object.keys(products).map((category) => (
        <div key={category}>
          <h2>{category}</h2>
          <Row>
            {products[category].map((product) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default Category;
