// EditOrderModal.jsx
import React, { useState } from "react";
import Modal from "react-modal";
import SubmitButton from "../../bulk-order/submit.btn.component.jsx";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../contexts/UserProviderMongo.jsx";

// Define your modal styles
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root"); // Set your root element

function EditOrderModal({ isOpen, onRequestClose, file }) {
  const { user } = useAuth();
  const { updateSaleOrder } = useAuth();
  const userId = user?.userId;
  const uid = file?._id;
  console.log("🚀 ~ file: editOrderForm.jsx:27 ~ EditOrderModal ~ uid:", uid);

  const [formData, setFormData] = useState({
    InvoiceNoBranchCopies: file.InvoiceNoBranchCopies,
    branch: file.branch,
    customerInvoices: file.customerInvoices,
    consigneeName: file.consigneeName,
    shippingAddress: file.shippingAddress,
    contactNo: file.contactNo,
    itemName: file.itemName,
    quantity: file.quantity,
    orderTypeId: file.orderTypeId,
    isAllowed: false,
    userId: userId,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = async () => {
    // e.preventDefault();
    try {
      console.log(formData);
      await updateSaleOrder(formData, uid);
      setFormData(formData);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit File Modal"
    >
      <h2>Edit Form: {file.InvoiceNoBranchCopies}</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div class="row">
          <div class="col-sm-3">
            {" "}
            <div className="form-section my-3">
              <label>Invoice No:</label>{" "}
              <input
                type="text"
                value={formData.InvoiceNoBranchCopies}
                disabled
              />
            </div>
          </div>
          <div class="col-sm-3">
            {" "}
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Branch</label>
              <input
                type="text"
                name="branch"
                placeholder="Branch"
                value={formData.branch}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div class="col-sm-3">
            {" "}
            <div className="form-section my-3">
              <label htmlFor="companyAddress">customer Invoice</label>
              <input
                type="text"
                name="customerInvoices"
                placeholder="Customer Invoice No"
                value={formData.customerInvoices}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div class="col-sm-3">
            {" "}
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Consignee Name</label>
              <input
                type="text"
                name="consigneeName"
                placeholder="Consignee Name"
                value={formData.consigneeName}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div class="row">
          {" "}
          <div class="col-sm-12">
            {" "}
            <div className="form-section my-3">
              <label>Shipping Address</label>{" "}
              <input
                type="text"
                name="shippingAddress"
                placeholder="Shipping Address"
                value={formData.shippingAddress}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            {" "}
            <div className="form-section my-3">
              <label>Contact No</label>{" "}
              <input
                type="text"
                name="contactNo"
                placeholder="Contact No"
                value={formData.contactNo}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div class="col-sm-4">
            {" "}
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Item Name</label>
              <input
                type="text"
                name="itemName"
                placeholder="Item Name"
                value={formData.itemName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div class="col-sm-4">
            {" "}
            <div className="form-section my-3">
              <label htmlFor="companyAddress">Quantity</label>
              <input
                type="text"
                name="quantity"
                placeholder="Quantity"
                value={formData.quantity}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default EditOrderModal;
