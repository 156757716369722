import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import CartDropdown from "../../components/cart-dropdown/cart-dropdown.component.jsx";
import { CartContext } from "../../contexts/cart.context.jsx";
import { useAuth } from "../../contexts/UserProviderMongo.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield, faUser } from "@fortawesome/free-solid-svg-icons";
import "./navigation.styles.scss";

const Navigation = () => {
  const { isCartOpen } = useContext(CartContext);
  const { user, signout } = useAuth();
  console.log(
    "🚀 ~ file: navigation.component.jsx:14 ~ Navigation ~ user:",
    user
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [userRoleFlag, setUserRoleFlag] = useState(null);

  console.log(
    "🚀 ~ file: navigation.component.jsx:17 ~ Navigation ~ userRoleFlag:",
    userRoleFlag
  );

  useEffect(() => {
    setUserRoleFlag(user);
  }, [user]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  return (
    <Fragment>
      <div class="card m-2 rounded overflow-visible shadow-lg z-20">
        {" "}
        <Navbar
          style={{ backgroundColor: "#FFFFFF", margin: "0px" }}
          expand="lg"
        >
          <Navbar.Brand as={Link} to="/">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="logo"
                src="/jriseLogo.png"
                alt={user?.logo}
                onError={(e) => {
                  e.target.src = "/404.png";
                }}
                style={{
                  maxHeight: "100px",
                  maxWidth: "100px",
                  width: "auto",
                  height: "auto",
                  marginRight: "10px", // Adjust the margin as needed
                }}
              />
              <div
                style={{
                  border: "1px solid #272661",
                  padding: "5px",
                  color: "#272661",
                  borderRadius: "5px",
                }}
              >
                <h6 className="dpiit" style={{ margin: 0 }}>
                  Recognized by <br /> DPIIT
                </h6>
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/shop">
                SHOP
              </Nav.Link>{" "}
              <Nav.Link as={Link} to="/VisionShakti">
                VisionShakti
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/privacyPolicy">
                PrivacyPolicy
              </Nav.Link> */}
              {userRoleFlag && (
                <>
                  {/* {userRoleFlag?.isMfiUser && (
                  <Nav.Link as={Link} to="/registration">
                    MFI Register
                  </Nav.Link>
                )} */}
                  {userRoleFlag?.isMfiUser && (
                    <Nav.Link as={Link} to="/invoice-generate">
                      Invoice Generation{" "}
                    </Nav.Link>
                  )}
                  {/* {userRoleFlag?.isMfiUser && (
                    <Nav.Link as={Link} to="/order">
                      Invoice Generation 2
                    </Nav.Link>
                  )} */}
                  {userRoleFlag?.isWarehouseManager && (
                    <Nav.Link as={Link} to="/warehouse">
                      Warehouse
                    </Nav.Link>
                  )}
                  {userRoleFlag?.isNewOrderManager && (
                    <Nav.Link as={Link} to="/adminPage">
                      New Order
                    </Nav.Link>
                  )}
                  {userRoleFlag?.isInventoryManager && (
                    <Nav.Link as={Link} to="/adminPage">
                      Inventory Manager
                    </Nav.Link>
                  )}
                  <NavDropdown
                    title={<FontAwesomeIcon icon={faUser} />}
                    id="basic-nav-dropdown"
                    className="overflow"
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/profile"
                      onClick={closeDropdown}
                    >
                      Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/OrderHistory"
                      onClick={closeDropdown}
                    >
                      OrderHistory
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={signout}>
                      Sign Out
                    </NavDropdown.Item>
                  </NavDropdown>
                  {userRoleFlag?.isAdmin && (
                    <Nav.Link as={Link} to="/adminPage">
                      <FontAwesomeIcon icon={faUserShield} />
                    </Nav.Link>
                  )}
                </>
              )}
              {!userRoleFlag && (
                <Nav.Link as={Link} to="/SignUp">
                  Sign Up
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      {isCartOpen && <CartDropdown />}
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
