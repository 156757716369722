import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
// import "./orderHistory.css";
import Spinner from "react-bootstrap/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../../contexts/UserProviderMongo.jsx";
import LoadingOverlay from "../../../user-profile/order-history/LoadingOverlay.jsx";
import PaginationComponent from "../../../pagination/PaginationComponent.jsx";
import EditOrderModal from "../../../order-form/components/editOrderForm.jsx";
import DeleteConfirmationModal from "../../../success-modal/deleteModal.js";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";
import EditInvoicesModal from "./edtiInvoicesAdmin.jsx";

function UserInvoiceAdmin() {
  //ANCHOR  <!---- CONTEXT ----!>
  const files = useAuth([]);
  const generateInvoice = useAuth([]);
  const { getSaleOrderAllForm } = useUser([]); //for sales order form
  const { filesAdmin } = useUser([]); // for excel file upload table
  console.log(
    "🚀 ~ file: userInvoicesAdmin.jsx:22 ~ UserInvoiceAdmin ~ filesAdmin:",
    filesAdmin
  );
  const { handleSoftDelete } = useAuth([]);
  const { getSalesOrderAdmin } = useUser([]);

  // ANCHOR <!---- USESTATE FOR PAGINATION VALUES ----!>
  const pageSize = 5; // Number of rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageForm, setCurrentPageForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  const [deleteFile, setDeleteFile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // ANCHOR  <!---- USESTATE FOR RETURN VALUES ----!>
  const [excelOrderForms, setExcelOrderForms] = useState([]); //for sales order form
  console.log(
    "🚀 ~ file: userInvoicesAdmin.jsx:37 ~ UserInvoiceAdmin ~ excelOrderForms:",
    excelOrderForms
  );
  const [orderForms, setOrderForms] = useState([]); //for sales order form
  const { refreshFiles, orderDownload, refreshFilesFormOrder } = useAuth([]); // Get data and function from context

  //ANCHOR <!---- For Excel Order ----!>
  useEffect(() => {
    const ExcelOrderFormsMap = filesAdmin?.files;
    console.log(
      "🚀 ~ file: userInvoicesAdmin.jsx:43 ~ useEffect ~ filesAdmin:",
      filesAdmin
    );

    if (ExcelOrderFormsMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setExcelOrderForms(ExcelOrderFormsMap);
    }
  }, [filesAdmin]);

  //ANCHOR  <!---- For Sale Order form ----!>
  useEffect(() => {
    const getSaleOrderAllFormMap = getSaleOrderAllForm?.orderForms;

    if (getSaleOrderAllFormMap) {
      // If getSaleOrderAllFormMap is not null or undefined, set it in the state
      setOrderForms(getSaleOrderAllFormMap);
    }
  }, [getSaleOrderAllForm]);

  //ANCHOR  <!---- handle Edit Click ----!>
  const handleEditClick = (file) => {
    setEditFile(file);
    setShowEditModal(true);
  };

  //ANCHOR  <!---- handle delete Click ----!>
  const handleDeleteClick = (file) => {
    setDeleteFile(file);
    setIsDeleteModalOpen(true);
  };

  //ANCHOR  <!----  handleConfirmDelete  ----!>
  const handleConfirmDelete = async (file) => {
    // e.preventDefault();
    const uid = file;

    try {
      // console.log(formData);
      await handleSoftDelete(uid);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  //ANCHOR  <!----  handleCancelDelete  ----!>
  const handleCancelDelete = () => {
    // Cancel the delete operation and close the modal
    setIsDeleteModalOpen(false);
  };

  //ANCHOR  <!----  handleEditModalClose  ----!>
  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  //ANCHOR  <!----  handleRefresh  ----!>
  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await refreshFiles();
      await refreshFilesFormOrder();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //ANCHOR  <!---- useEffect to render data ----!>
  useEffect(() => {
    handleRefresh();
    getSalesOrderAdmin();
  }, []);

  if (isLoading) {
    return (
      <div>
        <h1>User Files</h1>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  //ANCHOR  <!---- Form Order to check if files ?? [] ----!>
  if (
    !orderForms ||
    !getSaleOrderAllForm ||
    getSaleOrderAllForm?.length === 0
  ) {
    return (
      <div>
        <h1>No Form Order Sales founds</h1>
        <Button onClick={handleRefresh}>Refresh Data</Button>{" "}
      </div>
    );
  }

  //ANCHOR  <!---- For Excel Upload Order ----!>
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, excelOrderForms.length);
  const excelUploadOrder = Array.isArray(excelOrderForms)
    ? excelOrderForms.slice(startIndex, endIndex)
    : [];
  const totalPages = Math.ceil(excelOrderForms.length / pageSize);

  //ANCHOR  <!---- For Form Order ----!>
  const startIndexForm = (currentPageForm - 1) * pageSize;
  const endIndexForm = Math.min(startIndexForm + pageSize, orderForms.length);
  const formUploadOrder = Array.isArray(orderForms)
    ? orderForms.slice(startIndexForm, endIndexForm)
    : [];
  const totalPagesForm = Math.ceil(orderForms.length / pageSize);

  //ANCHOR  <!---- handlePageChange ----!>
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentPageForm(page);
  };
  //ANCHOR  <!---- handlePageChangeForm ----!>
  const handlePageChangeForm = (page) => {
    setCurrentPageForm(page);
  };

  return (
    <div className="order-history-body">
      <div className="form-upload-order-container">
        {" "}
        <h1>Form Upload Order</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>Invoice No (Branch Copies)</th>
              <th>Branch</th>
              <th>customer Invoice</th>
              <th>Consignee Name</th>
              <th>shipping address</th>
              <th>Contact No</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formUploadOrder.map((file) => (
              <tr key={file._id}>
                <td>{file.InvoiceNoBranchCopies}</td>
                <td>{file.branch}</td>
                <td>{file.customerInvoices}</td>
                <td>{file.consigneeName}</td>
                <td>{file.shippingAddress}</td>
                <td>{file.contactNo}</td>
                <td>{file.itemName}</td>
                <td>{file.quantity}</td>

                <td>
                  <button
                    onClick={() => handleEditClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon color="primary" />
                  </button>
                </td>
                <td>
                  <button
                    onClick={() => handleDeleteClick(file)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon color="error" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={currentPageForm}
          totalPages={totalPagesForm}
          onPageChange={handlePageChangeForm}
        />
        {isLoading && <LoadingOverlay />}
      </div>
      <div className="excel-upload-order-container">
        <h1>Excel Upload Order</h1>
        <Button className="order-history-Refresh" onClick={handleRefresh}>
          Refresh Data
        </Button>
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>Original Name</th>
              <th>File ID</th>
              <th>Download</th>
              <th>Generate Invoice</th>
            </tr>
          </thead>
          <tbody>
            {excelUploadOrder.map((file) => (
              <tr key={file._id}>
                <td>{file.originalName}</td>
                <td>{file._id}</td>
                <td>
                  <Button
                    className="order-history-Refresh"
                    onClick={() => orderDownload(file._id)}
                  >
                    Download
                  </Button>
                </td>
                <td>
                  <Button
                    className="order-history-Refresh"
                    onClick={() => generateInvoice(file._id)}
                  >
                    Generate Invoice
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        {isLoading && <LoadingOverlay />}
      </div>
      {showEditModal && (
        <EditInvoicesModal
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
          file={deleteFile}
        />
      )}
    </div>
  );
}

export default UserInvoiceAdmin;
