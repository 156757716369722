import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./purchaseOrder.scss";
import { Divider, Input } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/UserProviderMongo.jsx";
import {
  createPurchaseOrderInvoiceRoutes,
  createTaxInvoiceRoutes,
  GetTaxInvoiceExcelDummyRoutes,
} from "../../../../api/user.api.js";
import FileButtons from "../../../bulk-order/fileButtons.jsx";
import SubmitButton from "../../../bulk-order/submit.btn.component.jsx";
import OrderHistory from "../../../user-profile/order-history/order-history.jsx";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline.js";
import DeleteIcon from "@mui/icons-material/Delete";

const PurchaseOrder = () => {
  const { user } = useAuth();
  const { getActiveProductsAdmin } = useUser();
  const userId = user?.userId;

  // Form state
  const [formData, setFormData] = useState({
    products: [
      { brandId: "", qty: "" }, // Initial product field
    ],
    invoiceDate: "",
    billTo: { name: "", address: "", taxId: "" },
    shipTo: { name: "", address: "" },
    userId: userId,
    PaymentTerms: "",
  });
  const [selectedProducts, setSelectedProducts] = useState(
    formData?.products?.map(() => "")
  );

  const [productMapping, setProductMapping] = useState({});

  // When fetching products, update the mapping
  useEffect(() => {
    const newProductMapping = {};
    getActiveProductsAdmin?.data
      ?.filter((item) => item.brand)
      .forEach((item) => {
        const value = `${item.category} - ${item.brand} - ${item.description}`;
        newProductMapping[value] = item._id;
      });
    setProductMapping(newProductMapping);
  }, [getActiveProductsAdmin]);

  const handleProductChange = (e, index) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const newProducts = [...prevData.products];
      newProducts[index][name] = value;

      // Set brandId and qty for the current product
      newProducts[index]["brandId"] = value; // Set brandId
      newProducts[index]["qty"] = newProducts[index]["qty"]; // Set qty

      const newSelectedProducts = newProducts.map(
        (product) => product.brandId || ""
      );

      console.log("New Products:", newProducts);
      console.log("New Selected Products:", newSelectedProducts);

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        products: newProducts,
      };
    });
  };

  const handleQtyChange = (e, index) => {
    const { value } = 1000;

    setFormData((prevData) => {
      const newProducts = [...prevData.products];
      newProducts[index]["qty"] = value;

      const newSelectedProducts = newProducts?.map(
        (product) => product.brandId || ""
      );

      console.log("New Products:", newProducts);
      console.log("New Selected Products:", newSelectedProducts);

      setSelectedProducts(newSelectedProducts);

      return {
        ...prevData,
        products: newProducts,
      };
    });
  };

  const handleAddProductFields = (index) => {
    setFormData((prevData) => {
      const newProducts = [...prevData.products];
      newProducts.splice(index + 1, 0, { brandId: "", qty: "" });
      return {
        ...prevData,
        products: newProducts,
      };
    });
  };

  const handleDeleteProductFields = (index) => {
    setFormData((prevData) => {
      if (prevData.products.length === 1) {
        // Ensure there is always at least one product field
        return prevData;
      }

      const newProducts = [...prevData.products];
      newProducts.splice(index, 1);
      return {
        ...prevData,
        products: newProducts,
      };
    });
  };

  const renderProductInputs = () => {
    return formData?.products?.map((product, index) => (
      <div className="row mt-10" key={index}>
        <div className="col-sm-4">
          <div className="form-section">
            <label className="mt-10" htmlFor={`brandId${index}`}>
              Select product
              <button
                onClick={() => handleAddProductFields(index)}
                style={{
                  padding: "5px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <AddCircleOutline color="primary" />
              </button>
              <button
                onClick={() => handleDeleteProductFields(index)}
                style={{
                  padding: "5px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <DeleteIcon color="error" />
              </button>
            </label>
            <select
              className="form-control"
              id={`brandId${index}`}
              name={`products[${index}].brandId`}
              value={selectedProducts[index]}
              onChange={(e) => handleProductChange(e, index)}
            >
              <option value="">Select product</option>
              {Object.entries(productMapping)?.map(
                ([displayValue, actualValue]) => (
                  <option key={actualValue} value={actualValue}>
                    {displayValue}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
        <div className="col-sm-4">
          <label className="mt-14" htmlFor={`qty${index}`}>
            Quantity
          </label>
          <input
            type="number"
            id={`qty${index}`}
            name={`products[${index}].qty`}
            value={product.qty[index]}
            onChange={(e) => handleQtyChange(e, index)}
            style={{ lineHeight: "27px" }}
          />
        </div>
      </div>
    ));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      // Split the name into an array to access nested properties
      const nameArray = name.split(".");

      // Handle nested properties
      if (nameArray.length === 2) {
        return {
          ...prevData,
          [nameArray[0]]: {
            ...prevData[nameArray[0]],
            [nameArray[1]]: value,
          },
        };
      }

      // Handle non-nested properties
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create form data
    const data = {
      userId: userId,
      products: formData.products?.map((product) => ({
        brandId: product.brandId,
        qty: product.qty,
      })),
      invoiceDate: formData.invoiceDate,
      PaymentTerms: formData.PaymentTerms,
      billTo: {
        name: formData.billTo.name,
        address: formData.billTo.address,
        taxId: formData.billTo.taxId,
      },
      shipTo: {
        name: formData.shipTo.name,
        address: formData.shipTo.address,
      },
    };

    try {
      // Make Axios call
      const response = await axios.post(createPurchaseOrderInvoiceRoutes, data);

      console.log(response.data);
      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
        toast.success("Order Form successfully filled");

        // Clear form fields
        setFormData({
          productFile: null,
          invoiceDate: "",
          billTo: { name: "", address: "", taxId: "" },
          shipTo: { name: "", address: "" },
          userId: userId,
          PaymentTerms: "",
        });
      } else {
        toast.error("Submission failed.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="file-buttons">
        <div className="form-container">
          <form onSubmit={handleSubmit} className="invoice-form">
            {/* billTo */}
            <div className="invoice-container">
              <h2 className="text-center">Generate Purchase Order</h2>
              <div className="row mt-10">{renderProductInputs()}</div>
              <div className="row mt-10">
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="invoiceDate">
                      Invoice Date
                    </label>
                    <input
                      type="date"
                      id="invoiceDate"
                      name="invoiceDate"
                      value={formData.invoiceDate}
                      onChange={handleInputChange}
                      style={{ lineHeight: "24px" }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="billToName">
                      Bill To Name
                    </label>
                    <input
                      type="text"
                      id="billToName"
                      name="billTo.name"
                      value={formData.billTo.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="billToAddress">
                      Bill To Address
                    </label>
                    <input
                      type="text"
                      id="billToAddress"
                      name="billTo.address"
                      value={formData.billTo.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              {/* shipTo */}
              <div className="row mt-10">
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="billToTaxId">
                      Bill To GST
                    </label>
                    <input
                      type="text"
                      id="billToTaxId"
                      name="billTo.taxId"
                      value={formData.billTo.taxId}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipToName">
                      Ship To Name
                    </label>
                    <input
                      type="text"
                      id="shipToName"
                      name="shipTo.name"
                      value={formData.shipTo.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="shipToAddress">
                      Ship To Address
                    </label>
                    <input
                      type="text"
                      id="shipToAddress"
                      name="shipTo.address"
                      value={formData.shipTo.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-10">
                <div className="col-sm-4">
                  {" "}
                  <div className="form-section">
                    <label className="mt-10" htmlFor="PaymentTerms">
                      Payment Terms
                    </label>
                    <input
                      type="text"
                      id="PaymentTerms"
                      name="PaymentTerms"
                      value={formData.PaymentTerms}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ marginLeft: "20px" }}>
                {" "}
                {/* Adjust the margin as needed */}
                <SubmitButton handleSubmit={handleSubmit} />
              </div>
            </div>
          </form>
        </div>
        <div className="mt-10"></div>
        <Divider />
        <ToastContainer />
      </div>
    </>
  );
};

export default PurchaseOrder;
