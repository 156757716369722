import React, { useState } from "react";
import "./PrivacyPolicy.css"; // Import the CSS file

const TermsAndCondition = () => {
  const [sections, setSections] = useState({
    privacyPolicy: false,
    refundAndCancellation: false,
    termsAndConditions: false,
    faqs: false,
  });

  const toggleSection = (section) => {
    setSections({ ...sections, [section]: !sections[section] });
  };

  return (
    <div className="privacy-policy-container">
      {/* <div className="section">
        <h2
          onClick={() => toggleSection("privacyPolicy")}
          className="section-header"
        >
          {sections.privacyPolicy ? "▼" : "►"} Privacy Policy
        </h2>
        {sections.privacyPolicy && (
          <div className="section-content">
            <h3>1. Information We Collect</h3>
            <p>
              When you use JRise, we may collect various types of information,
              including:
            </p>
            <ul>
              <li>
                Personal information: such as your name, email address, shipping
                address, and payment details.
              </li>
              <li>
                Usage information: such as your IP address, browser type, device
                information, and browsing history.
              </li>
              <li>
                Cookies and tracking technologies: we may use cookies, web
                beacons, and similar technologies to collect information about
                your interactions with our website.
              </li>
            </ul>
            <h3>2. How We Use Your Information</h3>
            <p>
              We may use the information we collect for the following purposes:
            </p>
            <ul>
              <li>
                To process and fulfill your orders, including processing
                payments and delivering products.
              </li>
              <li>
                To communicate with you about your orders, account, and
                promotional offers.
              </li>
              <li>
                To improve our website, products, and services, and to
                personalize your shopping experience.
              </li>
              <li>
                To analyze trends and gather demographic information for
                marketing and advertising purposes.
              </li>
              <li>
                To comply with legal obligations and protect the rights,
                property, and safety of JRise Shopping and its users.
              </li>
            </ul>
            <h3>3. Disclosure of Your Information</h3>
            <p>
              We may disclose your information to third parties in the following
              circumstances:
            </p>
            <ul>
              <li>
                To service providers and partners who assist us in operating our
                website and providing services to you.
              </li>
              <li>
                To comply with legal requirements, such as responding to
                subpoenas, court orders, or legal process.
              </li>
              <li>
                In connection with a merger, acquisition, or sale of assets, in
                which case your information may be transferred to the acquiring
                entity.
              </li>
            </ul>
            <h3>4. Your Choices</h3>
            <p>
              You have certain choices regarding the information you provide to
              us:
            </p>
            <ul>
              <li>
                You may choose not to provide certain information, although this
                may limit your ability to access certain features of our
                website.
              </li>
              <li>
                You can opt-out of receiving promotional emails from us by
                following the unsubscribe instructions included in each email.
              </li>
            </ul>
            <h3>5. Security</h3>
            <p>
              We take reasonable measures to protect the security of your
              personal information and to prevent unauthorized access, use, or
              disclosure. We securely store your data using trusted services
              like MongoDB and Microsoft Azure.
            </p>
            <h3>6. Children's Privacy</h3>
            <p>
              JRise Shopping's website is not intended for children under the
              age of 18. We do not knowingly collect personal information from
              children under the age of 18.
            </p>
            <h3>7. Changes to This Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We will notify you
              of any material changes by posting the updated policy on our
              website.
            </p>
            <h3>8. Contact Us</h3>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our practices regarding your personal information, please contact
              us at support@jrisealliances.com.
            </p>
            <p>
              By using JRise Shopping's website, you consent to the collection,
              use, and disclosure of your information as described in this
              Privacy Policy. Thank you for trusting us with your personal
              information.
            </p>
          </div>
        )}
      </div> */}

      {/* <div className="section">
        <h2
          onClick={() => toggleSection("refundAndCancellation")}
          className="section-header"
        >
          {sections.refundAndCancellation ? "▼" : "►"} Refund and Cancellation
          Policy
        </h2>
        {sections.refundAndCancellation && (
          <div className="section-content">
            <h3>1. No Order Cancellation</h3>
            <p>
              Please note that once an order is placed and confirmed, it cannot
              be cancelled. Our no cancellation policy ensures that orders are
              processed promptly to expedite delivery.
            </p>
            <h3>2. Eligibility for Refunds</h3>
            <p>Refunds may be requested under the following circumstances:</p>
            <ul>
              <li>
                Product(s) received are defective, damaged, or not as described.
              </li>
              <li>The wrong item(s) were delivered.</li>
              <li>The product(s) received do not match the order details.</li>
              <li>
                Non-delivery of the ordered item(s) within the specified
                timeframe.
              </li>
            </ul>
            <h3>3. Refund Request Process</h3>
            <p>
              To request a refund, please contact our customer support team
              within 2 days of receiving your order. Provide detailed
              information regarding the issue, including order number,
              product(s) affected, and a brief description of the problem.
            </p>
            <p>
              Our support team will review your request and may require
              additional information or evidence, such as photographs of the
              damaged or incorrect items. Refund requests submitted after the
              specified timeframe may not be eligible for consideration.
            </p>
            <h3>4. Refund Approval</h3>
            <p>
              Once your refund request is approved, we will initiate the refund
              process. Refunds will be issued to the original payment method
              used for the purchase. Please allow 10 business days for the
              refunded amount to reflect in your account, depending on your
              payment provider's processing time.
            </p>
            <h3>5. Return of Product(s)</h3>
            <p>
              In some cases, we may request the return of the product(s) for
              inspection or verification purposes before processing the refund.
              Our customer support team will provide instructions on how to
              return the item(s) if necessary.
            </p>
            <h3>6. Refund Exceptions</h3>
            <p>Refunds will not be provided for the following reasons:</p>
            <ul>
              <li>Change of mind or preference.</li>
              <li>Incorrect selection of product(s) by the customer.</li>
              <li>
                Items damaged due to improper use, mishandling, or neglect.
              </li>
              <li>
                Products that have been used or are not in their original
                condition and packaging.
              </li>
            </ul>
            <h3>7. Contact Us</h3>
            <p>
              If you have any questions or need assistance regarding refunds,
              please don't hesitate to contact our customer support team. We're
              here to help address any concerns you may have.
            </p>
            <p>
              By making a purchase on JRISE Shopping, you agree to abide by our
              refund policy outlined above. We appreciate your understanding and
              cooperation. Thank you for choosing JRISE Shopping for your
              shopping needs.
            </p>
          </div>
        )}
      </div> */}

      <div className="section">
        <h2
          onClick={() => toggleSection("termsAndConditions")}
          className="section-header"
        >
          {sections.termsAndConditions ? "▼" : "►"} Terms & Conditions
        </h2>
        {sections.termsAndConditions && (
          <div className="section-content">
            <h3>1. Acceptance of Terms</h3>
            <p>
              By using JRISE Shopping's website, you acknowledge that you have
              read, understood, and agree to be bound by these terms and
              conditions, as well as our Privacy Policy. If you do not agree
              with any part of these terms, please refrain from using our
              website.
            </p>
            <h3>2. Eligibility</h3>
            <p>
              You must be at least 18 years old or have parental consent to use
              JRISE Shopping. By accessing our website, you represent and
              warrant that you meet these eligibility requirements.
            </p>
            <h3>3. Account Responsibility</h3>
            <p>
              You are responsible for maintaining the confidentiality of your
              account information and password. You agree to notify us
              immediately of any unauthorized use of your account or any other
              breach of security. JRISE Shopping is not liable for any loss or
              damage arising from your failure to protect your account
              information.
            </p>
            <h3>4. Product Information and Availability</h3>
            <p>
              We strive to provide accurate and up-to-date product information
              on our website. However, we do not guarantee the accuracy,
              completeness, or availability of any product information. Product
              prices and availability are subject to change without notice.
            </p>
            <h3>5. Ordering and Payment</h3>
            <p>
              By placing an order on JRISE Shopping, you agree to pay the total
              amount specified for your purchase, including any applicable taxes
              and shipping fees. We reserve the right to cancel or refuse any
              order at our discretion, including orders that appear to be
              fraudulent or in violation of these terms.
            </p>
            <h3>6. Shipping and Delivery</h3>
            <p>
              Shipping and delivery times are estimates and may vary based on
              factors such as location, availability, and carrier delays. We are
              not liable for any delays or damages that occur during shipping.
            </p>
            <h3>7. Returns and Refunds</h3>
            <p>
              Our refund and cancellation policy outlines the terms under which
              refunds and returns may be processed. Please review our Refund and
              Cancellation Policy for more information.
            </p>
            <h3>8. Intellectual Property</h3>
            <p>
              All content on JRISE Shopping's website, including text, images,
              logos, and trademarks, is the property of JRISE Shopping or its
              licensors and is protected by copyright and intellectual property
              laws. You may not use, reproduce, or distribute any content from
              our website without our prior written consent.
            </p>
            <h3>9. Limitation of Liability</h3>
            <p>
              JRISE Shopping is not liable for any indirect, incidental,
              consequential, or punitive damages arising from your use of our
              website or products. Our liability is limited to the maximum
              extent permitted by law.
            </p>
            <h3>10. Governing Law</h3>
            <p>
              These terms and conditions are governed by and construed in
              accordance with the laws of the jurisdiction in which JRISE
              Shopping operates. Any disputes arising from these terms will be
              subject to the exclusive jurisdiction of the courts in that
              jurisdiction.
            </p>
            <h3>11. Changes to Terms</h3>
            <p>
              We may update these terms and conditions from time to time to
              reflect changes in our practices or legal requirements. We will
              notify you of any material changes by posting the updated terms on
              our website.
            </p>
            <h3>12. Contact Us</h3>
            <p>
              If you have any questions or concerns regarding these terms and
              conditions, please contact us at support@jrisealliances.com.
            </p>
            <p>
              By using JRISE Shopping's website, you agree to adhere to these
              terms and conditions. Thank you for your cooperation.
            </p>
          </div>
        )}
      </div>

      {/* <div className="section">
        <h2 onClick={() => toggleSection("faqs")} className="section-header">
          {sections.faqs ? "▼" : "►"} FAQs
        </h2>
        {sections.faqs && (
          <div className="section-content">
            <h3>1. What is JRISE Shopping?</h3>
            <p>
              JRISE Shopping is an online retail platform offering a wide range
              of products across various categories. Our goal is to provide a
              convenient and enjoyable shopping experience for our customers.
            </p>
            <h3>2. How can I place an order?</h3>
            <p>
              To place an order, browse our product catalog, select the items
              you wish to purchase, and proceed to checkout. Follow the
              instructions to provide your shipping and payment information.
              Once your order is confirmed, you will receive a confirmation
              email.
            </p>
            <h3>3. What payment methods do you accept?</h3>
            <p>
              We accept various payment methods, including credit cards, debit
              cards, and other secure online payment options. Payment details
              will be processed securely through our payment gateway.
            </p>
            <h3>4. How can I track my order?</h3>
            <p>
              After your order has been shipped, you will receive a tracking
              number via email. Use this tracking number to monitor the status
              of your shipment through our carrier's website.
            </p>
            <h3>5. What is your return policy?</h3>
            <p>
              Please refer to our Refund and Cancellation Policy for detailed
              information on returns and refunds. If you encounter any issues
              with your order, contact our customer support team for assistance.
            </p>
            <h3>6. How can I contact customer support?</h3>
            <p>
              For any inquiries or support requests, please email us at
              support@jrisealliances.com. Our customer support team will be
              happy to assist you with any questions or concerns.
            </p>
            <h3>7. Do you offer international shipping?</h3>
            <p>
              Currently, we offer shipping within specific regions. Please check
              our shipping policy or contact our customer support team for
              information on international shipping options.
            </p>
            <h3>8. How do I change my account details?</h3>
            <p>
              To update your account details, log in to your account on our
              website and navigate to the account settings. Here, you can modify
              your personal information, shipping address, and payment details.
            </p>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default TermsAndCondition;
