import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    background: "#fff",
    padding: "20px",
    borderRadius: "4px",
    maxWidth: "400px",
    textAlign: "center",
  },
};

const h2Style = {
  margin: 0,
  color: "#333",
};

const buttonStyle = {
  margin: "0 10px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  fontWeight: "600",
};

const yesButtonStyle = {
  ...buttonStyle,
  background: "#e74c3c",
  color: "#fff",
};

const noButtonStyle = {
  ...buttonStyle,
  background: "#3498db",
  color: "#fff",
};

const DeleteConfirmationModal = ({
  isOpen,
  onRequestClose,
  onConfirmDelete,
  file,
}) => {
  console.log("🚀 ~ file: deleteModal.js:60 ~ file:", file?._id);
  const renderContent = () => {
    if (file?.InvoiceNoBranchCopies !== undefined) {
      console.log(
        "🚀 ~ file: deleteModal.js:62 ~ renderContent ~ file?.InvoiceNoBranchCopies:",
        file?.InvoiceNoBranchCopies
      );
      return <p>Invoice: {file?.InvoiceNoBranchCopies}</p>;
    }

    const dynamicContent = (
      <div>
        <h3 className="font-bold text-red-900">
          Once Deleted, You won't be able to get this.
        </h3>
        {Object.entries(file?.productDetails || {}).map(([key, value]) => (
          <p key={key}>
            {key}: {value}
          </p>
        ))}
      </div>
    );

    return dynamicContent;
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyle}>
      <p>Are you sure you want to delete this order?</p>
      {renderContent()}
      <button onClick={() => onConfirmDelete(file)} style={yesButtonStyle}>
        Yes
      </button>
      <button onClick={onRequestClose} style={noButtonStyle}>
        No
      </button>
    </Modal>
  );
};

export default DeleteConfirmationModal;
