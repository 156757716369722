import React, { useState, useEffect } from "react";
import axios from "axios";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";

const InspectGoods = ({ onAdd, onSaveAndNext }) => {
  const { getWarehouseInwardContent } = WarehouseContext([]); // Use useContext to access the context
  console.log(
    "🚀 ~ file: InspectGoods.jsx:6 ~ InspectGoods ~ getWarehouseInwardContent:",
    getWarehouseInwardContent
  );

  const [formData, setFormData] = useState({
    warehouseInwardId: "",
    inspectorName: "",
    quality: "Excellent",
    quantity: 0,
    condition: "New",
    notes: "",
  });

  const [warehouseInwards, setWarehouseInwards] = useState([]);
  console.log(
    "🚀 ~ file: InspectGoods.jsx:22 ~ InspectGoods ~ warehouseInwards:",
    warehouseInwards
  );

  useEffect(() => {
    // Fetch warehouseInwards to populate the dropdown
    const fetchWarehouseInwards = async () => {
      try {
        const response = await getWarehouseInwardContent?.data;
        console.log(
          "🚀 ~ file: InspectGoods.jsx:32 ~ fetchWarehouseInwards ~ response:",
          response
        );
        setWarehouseInwards(response);
      } catch (error) {
        console.error("Error fetching warehouseInwards:", error);
      }
    };

    fetchWarehouseInwards();
  }, [getWarehouseInwardContent]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(
        `http://localhost:3002/inspections/inspect/${formData.warehouseInwardId}`,
        formData
      );
      // Call the onAdd function passed as a prop to update the inspection list
      //   onAdd(response.data.inspection);
      // Reset the form data
      setFormData({
        warehouseInwardId: "",
        inspectorName: "",
        quality: "Excellent",
        quantity: 0,
        condition: "New",
        notes: "",
      });
      // Call onSaveAndNext after resetting the form data
      onSaveAndNext();
    } catch (error) {
      console.error("Error creating inspection:", error);
    }
  };

  // Assuming warehouseInwards is your array of objects
  const uniqueWarehouseOptions = [
    ...new Map(
      warehouseInwards.map((item) => [item.inwardBrand, item])
    ).values(),
  ];

  function formatCreatedAtDate(createdAt) {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <form className="inspection-form" onSubmit={handleSubmit}>
      <div className="form-section my-3">
        <label htmlFor="warehouseInwardId">Warehouse Inward:</label>
        <select
          className="form-control"
          name="warehouseInwardId"
          value={formData.warehouseInwardId}
          onChange={handleChange}
        >
          <option value="" disabled>
            Select Warehouse Inward
          </option>
          {uniqueWarehouseOptions.map((warehouseInward) => (
            <option key={warehouseInward._id} value={warehouseInward._id}>
              {`${warehouseInward.inwardBrand} - ${formatCreatedAtDate(
                warehouseInward.createdAt
              )}`}
            </option>
          ))}
        </select>
      </div>

      <div className="form-section my-3">
        <label htmlFor="inspectorName">Inspector Name:</label>
        <input
          type="text"
          className="form-control"
          name="inspectorName"
          value={formData.inspectorName}
          onChange={handleChange}
        />
      </div>

      <div className="form-section my-3">
        <label htmlFor="quality">Quality:</label>
        <select
          className="form-control"
          name="quality"
          value={formData.quality}
          onChange={handleChange}
        >
          <option value="Excellent">Excellent</option>
          <option value="Good">Good</option>
          <option value="Average">Average</option>
          <option value="Poor">Poor</option>
        </select>
      </div>

      <div className="form-section my-3">
        <label htmlFor="quantity">Quantity:</label>
        <input
          type="number"
          className="form-control"
          name="quantity"
          value={formData.quantity}
          onChange={handleChange}
        />
      </div>

      <div className="form-section my-3">
        <label htmlFor="condition">Condition:</label>
        <select
          className="form-control"
          name="condition"
          value={formData.condition}
          onChange={handleChange}
        >
          <option value="New">New</option>
          <option value="Used">Used</option>
          <option value="Damaged">Damaged</option>
        </select>
      </div>

      <div className="form-section my-3">
        <label htmlFor="notes">Notes:</label>
        <textarea
          className="form-control"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
        ></textarea>
      </div>

      <div className="form-section my-3">
        <button className="btn btn-primary" type="submit">
          Create Inspection
        </button>
      </div>
    </form>
  );
};

export default InspectGoods;
