import { useEffect, useState } from "react";
import "./home.styles.scss";
import { Link, Outlet } from "react-router-dom";
import React from "react";
import { useHomePage } from "../../contexts/homePage.context.jsx";
import WomanIcon from "@mui/icons-material/Woman";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import GiteIcon from "@mui/icons-material/Gite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { brandsJson } from "./brandJson.js";
import { Nav } from "react-bootstrap";

const cardStyle = {
  border: "1px solid #272661",
  margin: "10px",
  padding: "10px",
  width: "280px",
  backgroundColor: "rgba(255, 255, 255, 0.55)",
  borderRadius: "5px",
};

const headerStyle = {
  backgroundColor: "#FF9A00",
  padding: "10px",
  textAlign: "center",
  color: "#000000",
  borderRadius: "5px",
};

const backgroundColor = "rgba(255, 255, 255, 0.65)";

const Home = () => {
  const { getBrandsContent } = useHomePage();
  console.log(
    "🚀 ~ file: home.component.jsx:16 ~ Home ~ getBrandsContent:",
    getBrandsContent?.length
  );

  const [getBrandsNumbers, setBrandsNumbers] = useState(null);
  const [getBrands, setBrands] = useState(null);
  console.log(
    "🚀 ~ file: home.component.jsx:24 ~ Home ~ getBrands:",
    getBrands
  );

  useEffect(() => {
    setBrandsNumbers(getBrandsContent?.length);
    setBrands(getBrandsContent);
  }, [getBrandsContent]);

  return (
    <div>
      <Outlet />
      {/* <Directory categories={categories} /> */}
      <React.StrictMode>
        <div className="main-page-content">
          <div className="card m-2 rounded overflow-hidden shadow-lg new-about-container">
            <div className="new-about-title">
              {" "}
              <div className="about-title-h3">
                {" "}
                <h3>
                  We at JRise are working towards Empowering Rural Prosperity
                </h3>
                <h3>
                  Our vision is to be the catalyst for rural growth and
                  prosperity by connecting rural
                </h3>
              </div>
            </div>
            <div className="new-about-img">
              {" "}
              <img src="/assets/Flow.png" alt="404" />
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div style={cardStyle}>
                <h3 style={headerStyle}>
                  ONE STOP SOLUTION FOR HAPPY CUSTOMER
                </h3>
                <div className="underline1"></div>
                <div className="underline2"></div>
                <p>
                  Comprehensive approach that provides exceptional customer
                  experiences and ensures customer satisfaction at every
                  touchpoint
                </p>
              </div>

              <div style={{ ...cardStyle, backgroundColor }}>
                <h3 style={headerStyle}>ONE STOP SOLUTION FOR ALL BRANDS</h3>
                <div className="underline1"></div>
                <div className="underline2"></div>
                <p>
                  Comprehensive platform that offers a wide range of products
                  and services to meet the various needs and requirements of
                  rural consumers
                </p>
              </div>

              <div style={{ ...cardStyle, backgroundColor }}>
                <h3
                  style={{
                    ...headerStyle,
                    backgroundColor: "#006476",
                    color: "#ffff",
                  }}
                >
                  ONE STOP SOLUTION FOR SERVICE
                </h3>
                <div className="underline1"></div>
                <div className="underline2"></div>
                <p>
                  Comprehensive service provider that offers a wide range of
                  services to meet various needs and requirements of rural
                  consumer at their convenient location
                </p>
              </div>

              <div style={{ ...cardStyle, backgroundColor }}>
                <h3
                  style={{
                    ...headerStyle,
                    backgroundColor: "#009DFF",
                    color: "#ffff",
                  }}
                >
                  ONE STOP SOLUTION FOR LAST MILE DELIVERY{" "}
                </h3>
                <div className="underline1"></div>
                <div className="underline2"></div>
                <p>
                  Comprehensive platform that offers end-to-end logistics and
                  delivery solutions to efficiently transport products from a
                  brand distribution centre to branch or warehouse to the
                  customer's doorstep
                </p>
              </div>
            </div>

            <div className="new-about-img">
              {" "}
              {/* <img src="/assets/one-stop-solution.png" alt="404" /> */}
            </div>
          </div>
          <div className="home-text wow fadeIn text-center">
            {/* <TextAnimation /> */}
          </div>

          <div id="about" class="card m-2 rounded overflow-hidden shadow-lg">
            <div class="container">
              <div class="row">
                {/* <!-- card-mission 1 --> */}
                <div class="col-md-6">
                  <div class="card-mission">
                    <div class="card-mission-body">
                      <h3>Our Mission</h3>
                      <p>
                        We are dedicated to turning this vision into reality.
                        Through strategic partnerships, innovative programs, and
                        unwavering commitment, we strive to be catalysts for
                        change, igniting a spark that transforms the lives of
                        rural women and their communities.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!-- card-mission 2 --> */}
                <div class="col-md-6">
                  <div class="card-mission">
                    <div class="card-mission-body">
                      <h3>Our Approach</h3>
                      <p>
                        By fostering a culture of mentorship, collaboration, and
                        technological integration, we aim to create a supportive
                        ecosystem where women entrepreneurs connect, learn, and
                        inspire one another.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!-- card-mission 3 --> */}
                <div class="col-md-6">
                  <div class="card-mission">
                    <div class="card-mission-body">
                      <h3>Our Values</h3>
                      <p>
                        Empowerment, Inclusivity Sustainability, Integrity,
                        Collaboration, Innovation, Community-Centric &
                        Compassion. These values serve as the foundation upon
                        which JRISE operates, guiding our decisions, actions,
                        and relationships as we work towards realizing our
                        vision of sustainable rural prosperity through the
                        empowerment of women entrepreneurs.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <!-- card-mission 4 --> */}
                <div class="col-md-6">
                  <div class="card-mission">
                    <div class="card-mission-body">
                      <h3>Industry</h3>
                      <p>
                        Rural and B2B Distribution of diverse consumer products
                        and electronics segment specializing in the distribution
                        of an extensive range of products including consumer
                        electronics, electricals, white goods, water purifiers,
                        portable solar products and mobile accessories.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="service" class="card m-2 rounded overflow-hidden shadow-lg">
            <div className="service-content">
              <div className="service-grid text-center">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-title text-center wow fadeIn">
                        <h3>OUR BRAND PARTNERS</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        {/* <p>
                        I always want to make things that make a difference.
                      </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="row love-row wow fadeIn">
                    {brandsJson?.length === 0 ||
                    brandsJson === undefined ||
                    brandsJson === null ? (
                      <div className="col-md-4 col-sm-6 center">
                        <div className="service-details" data-wow-delay=".1s">
                          <div className="service-head">
                            <img src="/404.png" alt="404" />
                          </div>
                          <div className="service-bottom">
                            <div className="underline1"></div>
                            <div className="underline2"></div>
                            <h3>Coming Soon</h3>
                          </div>
                        </div>
                      </div>
                    ) : (
                      brandsJson?.map(({ id, name, logo }) => (
                        <div className="col-md-4 col-sm-6">
                          <div
                            className="service-details"
                            data-wow-delay=".1s"
                            key={id}
                          >
                            <div className="service-head">
                              <img
                                src={logo}
                                alt={name}
                                onError={(e) => {
                                  e.target.src = "/404.png"; // Assuming 404.png is in the public directory
                                }}
                              />
                            </div>
                            <div className="service-bottom">
                              <div className="underline1"></div>
                              <div className="underline2"></div>
                              <h3>{name}</h3>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="testimonial">
                <div className="testimonial-content">
                  <div className="testimonial-grid">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="main-title text-center wow fadeIn">
                            <h3>Testimonials</h3>
                            <div className="underline1"></div>
                            <div className="underline2"></div>
                            <p>
                              People I've worked with have said some nice things
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-details">
                      <section id="carousel">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-8 col-md-offset-2">
                              <div className="quote">
                                <i className="fa fa-quote-left fa-4x"></i>
                              </div>
                              <div
                                className="carousel slide"
                                id="fade-quote-carousel"
                                data-ride="carousel"
                                data-interval="3000"
                              >
                                <ol className="carousel-indicators">
                                  <li
                                    data-target="#fade-quote-carousel"
                                    data-slide-to="0"
                                    className="active"
                                  ></li>
                                  <li
                                    data-target="#fade-quote-carousel"
                                    data-slide-to="1"
                                  ></li>
                                  <li
                                    data-target="#fade-quote-carousel"
                                    data-slide-to="2"
                                  ></li>
                                </ol>
                                <div className="carousel-inner">
                                  <div className="active item">
                                    <blockquote>
                                      <p>
                                        “Sanajit was a real pleasure to work
                                        with and we look forward to working with
                                        him again. He’s definitely the kind of
                                        developer you can trust with a project
                                        from start to finish.”
                                        <br />
                                        <span>Ishrak Chaudhury</span>
                                      </p>
                                    </blockquote>
                                  </div>
                                  <div className="item">
                                    <blockquote>
                                      <p>
                                        “Sanajit's a clear communicator with the
                                        tenacity and confidence to really dig in
                                        to tricky design scenarios and the
                                        collaborative friction that's needed to
                                        produce excellent work.”
                                        <br />
                                        <span>Kamrul Roy</span>
                                      </p>
                                    </blockquote>
                                  </div>
                                  <div className="item">
                                    <blockquote>
                                      <p>
                                        “Sanajit has done a fantastic job
                                        overall. Not only the site is to design,
                                        but the code is also very clean and
                                        slick. Love the way he achieved the
                                        translations portion of the site.”
                                        <br />
                                        <span>Shahadat Mahapatra</span>
                                      </p>
                                    </blockquote>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div id="blog">
            <div className="blog-content">
              <div className="blog-grid">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-title text-center wow fadeIn">
                        <h3>My Blog</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          The Blog, Which has thoughts on life, work and
                          everything in between.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-details wow fadeIn text-left">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <div className="blog-main">
                          <a
                            href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="assets/img/blog/blog1.png"
                              alt="React hooks were introduced in version 16.8"
                              className="blog-img"
                            />
                          </a>
                          <div className="blog-head">
                            <a
                              href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <h3>
                                <strong>
                                  What is the importance of hooks in React JS?
                                </strong>
                              </h3>
                            </a>
                          </div>
                          <div className="blog-bottom">
                            <p>
                              In this article, you will learn what are hooks in
                              React JS? and when to use react hooks? React JS is
                              developed by Facebook in the year 2013. There are
                              many students and the new developers who have
                              confusion between react and hooks in react. Well,
                              it is not different, react is a programming
                              language and hooks is ...
                            </p>
                            <a
                              href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="about-link-2"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="blog-main">
                          <a
                            href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="assets/img/blog/blog2.png"
                              alt="The importance of UI/UX"
                              className="blog-img"
                            />
                          </a>
                          <div className="blog-head">
                            <a
                              href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <h3>
                                <strong>
                                  The importance of UI/UX | Software Engineering
                                </strong>
                              </h3>
                            </a>
                          </div>
                          <div className="blog-bottom">
                            <p>
                              UI or User Interface is the interface that is the
                              access point where users interact with computers.
                              It is also a way through which users can interact
                              with a website or an application. UI design
                              typically refers to graphical user interfaces but
                              also includes others, such as voice-controlled
                              ones, a keyboard and the appearance ...
                            </p>
                            <a
                              href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="about-link-2"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="blog-main">
                          <a
                            href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="assets/img/blog/blog3.png"
                              alt="blog-img"
                              className="blog-img"
                            />
                          </a>
                          <div className="blog-head">
                            <a
                              href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <h3>
                                <strong>Form Example in Laravel 8</strong>
                              </h3>
                            </a>
                          </div>
                          <div className="blog-bottom">
                            <p>
                              Laravel 8 form example tutorial. In this post, i
                              will teach from starting on how to send form data
                              on controller and how to insert form data in
                              database using laravel 8. If you are trying to
                              create form and want to insert form data into
                              database using laravel 8 latest version. So this
                              post will help you to do this. Because in this
                              post example, i will create contact-list ...
                            </p>
                            <a
                              href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="about-link-2"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ textAlign: "center" }}>
                        <a
                          href="https://www.linkedin.com/in/jayashree-rao/?originalSubdomain=in"
                          target="newtab"
                          className="btn-home btn-warning"
                          style={{
                            width: "50%",
                            fontSize: "16px",
                            backgroundColor: "#efd236",
                            borderColor: "#efd236",
                            color: "black",
                          }}
                        >
                          More Blog
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div id="contact">
            <div className="contact-content">
              <div className="text-grid">
                <div className="text-grid-main">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="main-title text-center wow fadeIn">
                          <h3>Contact Us</h3>
                          <div className="underline1"></div>
                          <div className="underline2"></div>
                          <p>Contact us now.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-grid">
                <div className="contact-form-details wow fadeIn">
                  <div className="container">
                    <div className="row contact-info-row text-center wow fadeIn">
                      <div className="col-md-3 col-sm-6 contact-colamn">
                        <div className="contact-col-info" data-wow-delay=".2s">
                          <i
                            className="fa fa-map-marker contact-icon"
                            aria-hidden="true"
                          ></i>
                          <h3>Address</h3>
                          <p>Mumbai, Maharahstra, India</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 contact-colamn">
                        <div className="contact-col-info" data-wow-delay=".4s">
                          <i
                            className="fa fa-envelope contact-icon"
                            aria-hidden="true"
                          ></i>
                          <h3>Email</h3>
                          <a
                            href="mailto:jayashree.rao@JRiseindia.co.in."
                            style={{
                              color: "black",
                              textDecoration: "none",
                              fontSize: "17px",
                            }}
                          >
                            contact.us@gmail.com
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 contact-colamn">
                        <div className="contact-col-info" data-wow-delay=".6s">
                          <i
                            className="fa fa-mobile contact-icon"
                            aria-hidden="true"
                          ></i>
                          <h3>Phone</h3>
                          <a
                            href="tel:8372876775"
                            style={{
                              color: "black",
                              textDecoration: "none",
                              fontSize: "17px",
                            }}
                          >
                            +91 1234567891
                          </a>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 contact-colamn">
                        <div className="contact-col-info" data-wow-delay=".6s">
                          <i
                            className="fa fa-television contact-icon"
                            aria-hidden="true"
                          ></i>
                          <h3>Portfolio</h3>
                          <a
                            href="https://in.linkedin.com/in/jayashree-rao"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click me
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid map-col">
                    <div className="col-md-12 col-sm-12 map-col">
                      <div className="google-maps">
                        <div className="map-wrap">
                          <iframe
                            title="google-maps"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.14570883012!2d72.71637762514337!3d19.08217751649168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1693406526859!5m2!1sen!2sin"
                            width="100%"
                            height="450"
                            style={{ border: "0" }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="footer-container-new">
            <div>
              {/* <table className="max-w-sm ml-10">
                <thead>
                  <tr>
                    <th colspan="2" className="max-w-sm">
                      <LocationOnIcon />
                      Jrise Alliances Private Limited 
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      WeWork Oberoi Commerz II, 1st floor, Goregaon East,
                      Mumbai, Maharashtra - 400063
                    </td>
                  </tr>
                </tbody>
              </table> */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6 p-4">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <LocationOnIcon style={{ marginRight: "8px" }} /> */}
                      <div>
                        <Link to="/privacyPolicy">
                          <h4>PRIVACY POLICY</h4>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <Nav.Link as={Link} to="/refund-policy">
                        <h4> REFUND POLICY</h4>
                      </Nav.Link>
                    </div>
                    <div>
                      <Nav.Link as={Link} to="/TermsAndCondition">
                        <h4>TERMS AND CONDITION</h4>
                      </Nav.Link>
                    </div>{" "}
                  </div>

                  {/* <div className="col-12 col-md-3 p-4">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <EmailIcon style={{ marginRight: "8px" }} />
                      <span style={{ paddingLeft: "3px" }}>
                        support@jriseialiances.com
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-md-3 p-4">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <CallIcon
                        style={{ marginRight: "8px", color: "white" }}
                      />
                      <span
                        style={{
                          paddingLeft: "3px",
                          color: "white",
                        }}
                      >
                        <a
                          className="footer-phone-number"
                          href="tel:+919870372830"
                        >
                          +91 98703 72830
                        </a>
                      </span>
                    </div>
                  </div> */}
                </div>{" "}
                <div className="row">
                  <div className="col-12 col-md-6 p-4">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <LocationOnIcon style={{ marginRight: "8px" }} />
                      <div>Jrise Alliances Private Limited </div>
                    </div>
                    <div style={{ marginRight: "18px", marginLeft: "23px" }}>
                      S-185, B-wing, express zone commercial hub, western
                      express highway, Goregaon (e), Mumbai-400063. CIN:
                      U50401PN2022PTC211568
                    </div>
                  </div>

                  <div className="col-12 col-md-3 p-4">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <EmailIcon style={{ marginRight: "8px" }} />
                      <span style={{ paddingLeft: "3px" }}>
                        support@jriseialiances.com
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-md-3 p-4">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <CallIcon
                        style={{ marginRight: "8px", color: "white" }}
                      />
                      <span
                        style={{
                          paddingLeft: "3px",
                          color: "white",
                        }}
                      >
                        <a
                          className="footer-phone-number"
                          href="tel:+919870372830"
                        >
                          +91 98703 72830
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.StrictMode>
    </div>
  );
};

export default Home;
