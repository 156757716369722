import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../../../../contexts/adminPanel.context.jsx";
import SubmitButton from "../../../../bulk-order/submit.btn.component.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function EditUserModal({ isOpen, onRequestClose, file }) {
  console.log(
    "🚀 ~ file: editUserAdmin.component.jsx:21 ~ EditUserModal ~ file:",
    file
  );
  const { updateUsersWithAdminRole } = useUser();
  const userId = file?.userId;

  const [formData, setFormData] = useState({
    firstName: file?.firstName,
    lastName: file?.lastName,
    email: file?.email,
    password: file?.password,
    isAdmin: file?.isAdmin,
    isMfiUser: file?.isMfiUser,
    isNewOrderManager: file?.isNewOrderManager,
    isWarehouseManager: file?.isWarehouseManager,
    isInventoryManager: file?.isInventoryManager,
    uniqueId: file?._id,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    try {
      const formDataWithUpdatedIsAdmin = {
        ...formData,
        ...formData,
        isAdmin: formData.isAdmin.toString(),
        isMfiUser: formData.isMfiUser.toString(),
        isNewOrderManager: formData.isNewOrderManager.toString(),
        isWarehouseManager: formData.isWarehouseManager.toString(),
        isInventoryManager: formData.isInventoryManager.toString(),
      };

      await updateUsersWithAdminRole(formDataWithUpdatedIsAdmin, userId);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit File Modal"
    >
      <h2>Edit Form: {file.InvoiceNoBranchCopies}</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-section my-3">
              <label htmlFor="isAdmin">Is Admin</label>
              <input
                className="form-check-input"
                type="checkbox"
                name="isAdmin"
                checked={formData.isAdmin}
                onChange={handleInputChange}
                id="isAdmin"
                style={{ width: "50px", height: "30px", display: "flex" }} // Adjust width and height as needed
              />
            </div>
          </div>

          <div className="col">
            <div className="form-section my-3 col-sm-3">
              <label htmlFor="isMfiUser">Is Mfi User</label>
              <input
                className="form-check-input"
                type="checkbox"
                name="isMfiUser"
                checked={formData.isMfiUser}
                onChange={handleInputChange}
                id="isMfiUser"
                style={{ width: "50px", height: "30px", display: "flex" }} // Adjust width and height as needed
              />
            </div>
            <div className="form-section my-3 col-sm-3">
              <label htmlFor="isNewOrderManager">Is New Order Manager</label>
              <input
                className="form-check-input"
                type="checkbox"
                name="isNewOrderManager"
                checked={formData.isNewOrderManager}
                onChange={handleInputChange}
                id="isNewOrderManager"
                style={{ width: "50px", height: "30px", display: "flex" }} // Adjust width and height as needed
              />
            </div>
            <div className="form-section my-3 col-sm-3">
              <label htmlFor="isWarehouseManager">Is Warehouse Manager</label>
              <input
                className="form-check-input"
                type="checkbox"
                name="isWarehouseManager"
                checked={formData.isWarehouseManager}
                onChange={handleInputChange}
                id="isWarehouseManager"
                style={{ width: "50px", height: "30px", display: "flex" }} // Adjust width and height as needed
              />
            </div>
            <div className="form-section my-3 col-sm-3">
              <label htmlFor="isInventoryManager">Is Inventory Manager</label>
              <input
                className="form-check-input"
                type="checkbox"
                name="isInventoryManager"
                checked={formData.isInventoryManager}
                onChange={handleInputChange}
                id="isInventoryManager"
                style={{ width: "50px", height: "30px", display: "flex" }} // Adjust width and height as needed
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default EditUserModal;
