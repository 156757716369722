// GetWarehouse?.js
import React, { useEffect, useState } from "react";
import { WarehouseContext } from "../../../contexts/warehouse.context.jsx";
import AddIcon from "@mui/icons-material/AddCircleOutline"; // Plus icon
import CreateWareHouse from "./createWarehouse.jsx";
import EditWarehouse from "./editWareHouse.jsx";
import EditIcon from "@mui/icons-material/Edit";

const cardStyle = {
  background: "#f4f4f4",
  border: "1px solid #ccc",
  borderRadius: "5px",
  padding: "20px",
  width: "300px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  //   alignItems: "center",
  //   minHeight: "100vh",
};

const GetWarehouse = () => {
  const { getWarehouseContent } = WarehouseContext([]);
  console.log(
    "🚀 ~ file: getWarehouse.jsx:7 ~ GetWarehouse ~ getWarehouseContent:",
    getWarehouseContent
  );
  const [warehouses, setWarehouses] = useState([]);
  console.log(
    "🚀 ~ file: getWarehouse.jsx:31 ~ GetWarehouse ~ warehouses:",
    warehouses
  );
  const [createWarehouseModal, setCreateWarehouseModal] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFile, setEditFile] = useState(null);
  console.log(
    "🚀 ~ file: getWarehouse.jsx:41 ~ GetWarehouse ~ editFile:",
    editFile
  );

  const handleCreateClick = () => {
    setCreateWarehouseModal();
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };
  const handleEditClick = (warehouse) => {
    console.log(
      "🚀 ~ file: getWarehouse.jsx:51 ~ handleEditClick ~ warehouse:",
      warehouse
    );
    setEditFile(warehouse);
    setShowEditModal(true);
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  useEffect(() => {
    setWarehouses(getWarehouseContent?.data);
  }, [getWarehouseContent?.data]);

  return (
    <div>
      <h1>All Your warehouses</h1>
      <button
        onClick={() => handleCreateClick()}
        style={{
          padding: "5px",

          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        <AddIcon color="primary" />
      </button>
      {warehouses?.length === 0 || warehouses === undefined ? (
        <div style={centerStyle}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            <div style={cardStyle}>
              <h2>No warehouses Found</h2>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          {warehouses?.map((warehouse) => (
            <div key={warehouse?._id} style={cardStyle}>
              <h2>
                {warehouse?.name.map((nameItem) => (
                  <div key={nameItem?._id}>{nameItem?.value}</div>
                ))}{" "}
                <button
                  onClick={() => handleEditClick(warehouse)}
                  style={{
                    paddingLeft: "5px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <EditIcon color="primary" />
                </button>
              </h2>
              <p>Location: {warehouse?.location}</p>
              <p>Address: {warehouse?.address}</p>
              <p>City: {warehouse?.city}</p>
              <p>State: {warehouse?.state}</p>
              <p>Country: {warehouse?.country}</p>
              <p>Active: {warehouse?.isActive ? "Yes" : "No"}</p>
            </div>
          ))}
        </div>
      )}
      {showEditModal && (
        <EditWarehouse
          isOpen={showEditModal}
          onRequestClose={handleEditModalClose}
          file={editFile}
        />
      )}
      {showCreateModal && (
        <CreateWareHouse
          isOpen={showCreateModal}
          onRequestClose={handleCreateModalClose}
          file={createWarehouseModal}
        />
      )}
    </div>
  );
};

export default GetWarehouse;
