import React from "react";
import "./submit.btn.css";

const SubmitButton = ({ handleSubmit, text }) => {
  return (
    <div className="Submit-button-body">
      <button id="Submit-button" onClick={handleSubmit} type="button">
        {text ? text : "Submit"}
      </button>
    </div>
  );
};

export default SubmitButton;
