import { createContext, useState, useEffect } from "react";

import { addCollectionAndDocuments } from "../utils/firebase/firebase.utils";

import SHOP_DATA from "../shop-data.js";
import {
  GetActiveProductsAdminRoutes,
  GetProductsByIdRoute,
} from "../api/user.api.js";
import axios from "axios";

export const ProductsContext = createContext({
  products: [],
});

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [getActiveProductsAdmin, setGetActiveProductsAdmin] = useState([]);
  const [error, setError] = useState(null);

  // ANCHOR - GET ALL Active Products
  const getProductsByIdContext = async (uidProduct) => {
    const id = uidProduct;
    console.log(
      "🚀 ~ file: product.context.jsx:24 ~ getProductsByIdContext ~ id:",
      id
    );
    const apiUrl = GetProductsByIdRoute.GetProductsById(id);
    try {
      setError(null); // Clear any previous errors
      const response = await axios.get(apiUrl);
      setGetActiveProductsAdmin(response?.data);
    } catch (error) {
      console.error(error);
      setError(
        error.response?.data?.message || "An error occurred during signup."
      ); // Handle signup errors here
    }
  };

  const value = { products, getActiveProductsAdmin, getProductsByIdContext };
  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
