import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import FileButtons from "../../../bulk-order/fileButtons.jsx";
import SubmitButton from "../../../bulk-order/submit.btn.component.jsx";
import { useAuth } from "../../../../contexts/UserProviderMongo.jsx";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { createProductsExcelRoutes } from "../../../../api/user.api.js";
import { useUser } from "../../../../contexts/adminPanel.context.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px", // Increase padding for better spacing
    maxWidth: "800px", // Set a maximum width for the modal
    width: "100%", // Make the modal responsive
    maxHeight: "80vh", // Set a maximum height for the modal (80% of the viewport height)
    overflowY: "auto", // Enable vertical scrolling if the content exceeds the height
    margin: "10px",
  },
};

Modal.setAppElement("#root");

function UploadProduct({ isOpen, onRequestClose }) {
  const { user } = useAuth();
  const { getActiveProductsAdminContext } = useUser();

  const userId = user?.userId;
  // Form state
  const [formData, setFormData] = useState({
    xlsxFile: null,
    userId: userId,
  });
  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(
      "🚀 ~ file: uploadProduct.jsx:42 ~ handleFileChange ~ file:",
      file
    );

    setFormData((prevData) => ({
      ...prevData,
      xlsxFile: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create form data
    const data = new FormData();
    data.append("xlsxFile", formData.xlsxFile);

    try {
      // Make Axios call
      const response = await axios.post(createProductsExcelRoutes, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response.data);
      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
        getActiveProductsAdminContext();
        toast.success("Order Form successfully filled");

        // Clear form fields using the existing state
        setFormData((prevData) => ({
          xlsxFile: null,
          userId: prevData.userId,
        }));

        // Retrieve sales order after successful form submission
      } else {
        toast.error("Submission failed.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Upload Product Xlsx file</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          padding: "10px",
          margin: "10px",
        }}
      >
        <CloseIcon />
      </button>
      <form onSubmit={handleSubmit} className="invoice-form">
        {/* billTo */}
        <div className="invoice-container">
          <div className="row mt-10">
            <div className="col-sm-4">
              {" "}
              <div className="form-section">
                <label className="mt-10" htmlFor="xlsxFile">
                  {" "}
                  Product File:
                </label>
                <input
                  type="file"
                  name="xlsxFile"
                  onChange={handleFileChange}
                  // Remove the "value" attribute for file input
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FileButtons
          // onDownloadClick={handleTemplateDownload}
          // onUploadClick={handleUpload}
          />
          <div style={{ marginLeft: "20px" }}>
            {" "}
            {/* Adjust the margin as needed */}
            <SubmitButton handleSubmit={handleSubmit} />
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default UploadProduct;
