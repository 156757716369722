import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useHomePage } from "../../../../../contexts/homePage.context.jsx";
import SubmitButton from "../../../../bulk-order/submit.btn.component.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    margin: "10px",
    width: "250px",
  },
};

Modal.setAppElement("#root");

function CreateBanners({ isOpen, onRequestClose }) {
  const { CreateBannerContext } = useHomePage();
  const [formData, setFormData] = useState({
    text: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Ensure that the checkbox value updates correctly
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Use 'checked' to set the checkbox value
      });
    } else {
      if (name === "brand" || name === "category") {
        setFormData({
          ...formData,
          [name]: value,
          productDetails: {
            ...formData.productDetails,
            [name]: value,
          },
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const handleSave = async () => {
    try {
      const formDataCreate = {
        ...formData,
        // deleted: formData.deleted.toString(), // Convert isAdmin to a string
      };

      await CreateBannerContext(formDataCreate);
      onRequestClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Create Product Modal"
    >
      <h2 className="text-center">Create New Text</h2>
      <button
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          // padding: "0px",
          paddingLeft: "10px",
          marginLeft: "10px",
          // margin: "0px",
        }}
      >
        <CloseIcon />
      </button>

      <form>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-section my-3">
              <label htmlFor="text">Text</label>
              <textarea
                type="text"
                name="text"
                placeholder="text"
                value={formData.text}
                onChange={handleInputChange}
                rows={4} // You can adjust the number of rows to control the height
                style={{
                  width: "100%", // Make the textarea take up the full width
                  display: "block", // Ensure it's displayed as a block element
                }}
              />
            </div>
          </div>
        </div>
      </form>

      <SubmitButton handleSubmit={handleSave} />
    </Modal>
  );
}

export default CreateBanners;
